(function(){
  'use strict';

  angular.module('mainApp')

    .directive('validnif', function () {
      return {
        restrict: 'A',
        require: 'ngModel',
        link: function(scope, elem, attr, ctrl) {
          var validRegex = /^[XYZ]?([0-9]{7,8})([A-Z])$/i;
          var dniLetters = 'TRWAGMYFPDXBNJZSQVHLCKE';

          ctrl.$parsers.unshift(function(value) {
            var valid = false;
            var original_document = value.toUpperCase().replace(/\s/, '');

            if ( value && value.length === 9 ) {
              value = value.toUpperCase().replace(/\s/, '');
              var niePrefix = value.charAt(0);

              switch ( niePrefix ) {
                case 'X':
                  niePrefix = 0;
                break;
                case 'Y':
                  niePrefix = 1;
                break;
                case 'Z':
                  niePrefix = 2;
                break;
              }
              value = niePrefix + value.substr(1);
              var valid = false;
              if (validRegex.test(value)) {
                valid = (value.charAt(8) === dniLetters.charAt(parseInt(value, 10) % 23));
              }
            }

            //4594359v
            //X0243228A - NIE
            ctrl.$setValidity('validnif', valid);
            return valid ? original_document : undefined;
          });
        }
      };
    })

    .directive('postalCodeCheck', ['toolsService', function (toolsService) { // Postal code concords
			return {
				require: 'ngModel',
				link: function (scope, elem, attrs, ctrl) {
					scope.$watchGroup(['userData.state', 'userData.zip'], function(newValues, oldValues, scope) {
            var isValidPostalCode = false;

            if(scope.userData.zip && scope.userData.state) {
              var start_stateCode = scope.states[scope.userData.state] + '000';
              var end_stateCode = parseInt(start_stateCode) + 1000;

              if(parseInt(scope.userData.zip) >= parseInt(start_stateCode) && parseInt(scope.userData.zip) < end_stateCode) {
                isValidPostalCode = true;
              }

              ctrl.$setValidity('postalcodeerror', isValidPostalCode);
            }
					});
				}
			};
		}])

    .controller('StoreShippingController', ['$scope','$rootScope','$http','$anchorScroll','$location','webStorage', 'toolsService','legalTerms','ngCart','appConfig','usersManager','storeFactory','serviceStoreAnalytics','ngDialog',
      function ($scope, $rootScope, $http, $anchorScroll, $location, webStorage, toolsService, legalTerms, ngCart, appConfig, usersManager, storeFactory, serviceStoreAnalytics, ngDialog) {

      serviceStoreAnalytics.checkoutProcess(3);

      // get state list
      storeFactory.getStates().then(function(states) {
        $scope.states = states;
      });

      var userInfo;
      $scope.showShippingForm = false;
      $scope.userData = {};
      $scope.userData.privacy = false;
      $scope.selectedShippingAddress = {};

      var currentAddress = ngCart.getShippingAddress();
      if(currentAddress) {
        $scope.userData = currentAddress;
        $scope.userData.privacy = true;
        $scope.showShippingForm = true;
      }

      $scope.$watch('currentUser.niceName', function(currentUserNiceName) {
        if(currentUserNiceName) {
          var url = appConfig.localApiBasePath + '/customer/' + currentUserNiceName;

          $http({
            method: 'GET',
            url: url
          }).then(function (res) {
            $scope.addresses = res.data.address;
            userInfo = res.data;
          });

          usersManager.getMachineList().then(function (result) {
            if (result.length > 0) {
              ngCart.setMycookSerialNumber(result[0].niceName);
            }
          });
        }
      }, true);

      $scope.loadAddressAsGuest = function() {
        $scope.showShippingForm = true;
      }

      $scope.addNewShippingAddress = function() {
        $scope.showShippingForm = true;
        $scope.userData = {};
        $scope.userData.privacy = true;

        $scope.selectedShippingAddress = $scope.userData;
      }

      $scope.userShippingAddress = function(address) {
        $scope.showShippingForm = true;
        $scope.userData = address;
        $scope.userData.privacy = true;

        $scope.selectedShippingAddress = address;
        $scope.userData.mail = userInfo.email;
      }

      $scope.acceptContract = function (validForm, userLegalData) {
        $scope.signature_contract = 'loading';
        $scope.contract_not_accepted = false;
        var conditionsArray = [];
        for(var k in userLegalData) {
          if(userLegalData[k]) {
            conditionsArray.push(k);
          }
        }
        legalTerms.acceptLegalTerms('multi',conditionsArray, $scope.userData.mail).then(function(result){
          ngCart.setShippingAddress($scope.userData);
          $location.path($rootScope.url('store-payment'));
        }).catch(function(error){
          console.error('NewsletterContractController -> Error accepting legalTerms', error);
        });
      };

      $scope.cancelContract = function (userLegalData) {
        $scope.signature_contract = 'deactivated';
        $scope.contract_not_accepted = true;
      };

      $scope.next = function() {
        $scope.userData.country = "España";

        if (!toolsService.isEmpty($scope.currentUser)) {
          ngCart.setShippingAddress($scope.userData);
          $location.path($rootScope.url('store-payment'));
        } else {
          $scope.userLegalData = {};
          $scope.anonymousUserData = true;
          legalTerms.getLegalTerms(['ecommerceGuestConditions']).then(function(legal) {
            $scope.legal = legal;
            $scope.signature_contract = 'activated';
          });
        }
      }
    }]);
})();
