(function() {

  'use strict';

  angular.module('mainApp')

  	.run(['serviceStoreAnalytics', function (serviceStoreAnalytics) {
		  serviceStoreAnalytics.init();
		}])

		.service('serviceStoreAnalytics', ['$rootScope', 'ngCart', 'storeFactory', 
      function ($rootScope, ngCart, storeFactory) {
			
				var self = this;
				var dataLayer;

				self.init = function() {
					dataLayer = window.dataLayer = window.dataLayer || [];

					$rootScope.$on('ngCart:itemAdded', function(event, element) {
						console.log('addRemoveFromCart > itemAdded');
						self.addRemoveFromCart('add', element);
					});

					$rootScope.$on('ngCart:itemRemoved', function(event, element) {
						console.log('addRemoveFromCart > itemRemoved');
						self.addRemoveFromCart('remove', element);
					});
				}

				function sendToAnalytics(obj) {
					console.log('sendToAnalytics: ', obj);
					dataLayer.push(obj);
				}

				function setCartProduct(product) {
					
					var qty;

					if(product._data) {
						qty = product._quantity;
						product = product._data;
					} else if(product.productValue) {
						qty = product.quantity;
						product = product.productValue;
					}

					var categoryList = getProductCategories(product.category);

					var obj = {
						'name': product.name,
		        'id': product.niceName,
		        'price': product.price,
		        //'brand': 'Google',
		        'category': categoryList,
		        //'variant': 'Gray',
		        'quantity': qty
					};

					return obj;
				}

				function getProductCategories(categories) {
					var categoryList = '';

					angular.forEach(categories, function(cat, key) {
						categoryList += cat.text;

						if(key < categories.length - 1) {
							categoryList += '|';
						}
					});

					return categoryList;
				}

				function cartProductItems(products) {
					var productArray = [];

					if(products._name) {
						productArray.push(setCartProduct(products));
					} else {
						angular.forEach(products, function(value, key) {
							productArray.push(setCartProduct(value));
						});
					}

					return productArray;
				}

				function listProductItems(products, list) {
					var productArray = [];

					angular.forEach(products, function(value, key) {
						var obj = {
							'name': value.name,
							'id': value.niceName,
							'price': value.price,
							'category': getProductCategories(value.category),
							'list': list,
							'position': (value.positionImpression) ? value.positionImpression : key + 1
						};

						productArray.push(obj);
					});

					return productArray;
				}

				self.addRemoveFromCart = function(state, products) {
					// console.log('serviceStoreAnalytics > addRemoveFromCart', state, products);
					products = cartProductItems(products);
					// console.log('convert cartProductItems', products);

					var object = {
						'ecommerce': {}
					};

					if(state == 'remove') {
						object.event = 'removeFromCart';
						object.ecommerce.remove = {};
						object.ecommerce.remove.products = products;

						sendToAnalytics(object);

					} else if(state == 'add') {
						object.event = 'addToCart';

						storeFactory.getConfiguration().then(function (store_config) {
		          var currency = store_config.displayCurrency || 'EUR';
							object.ecommerce = {};
							object.ecommerce.currencyCode = currency;
							object.ecommerce.add = {};
							object.ecommerce.add.products = products;

							sendToAnalytics(object);
		        });
					}
				};

				self.checkoutProcess = function(step, checkoutOption) {

					var products = cartProductItems(ngCart.getItems());

					var object = {
						'event': 'checkout',
						'ecommerce': {
							"checkout": {
								"actionField": {
									"step": step
								},
								"products": products
							}
						}
					};

					if(checkoutOption) object.ecommerce.checkout.actionField.option = checkoutOption;

					sendToAnalytics(object);
	        /// tipo de pago (stripe)
	        /// direccion de envio igual a facturacion
	        /// guest / usuario
				};

				self.checkoutOptions = function(step, checkoutOption) {
					var object = {
						'event': 'checkoutOption',
						'ecommerce': {
							"checkout_option": {
								"actionField": {
									"step": step,
									"option": checkoutOption
								}
							}
						}
					};

					sendToAnalytics(object);
				};

				self.purchase = function(order) {
					console.log('purchase', order);

					var object = {
						'event': 'purchaseCompleted',
					  'ecommerce': {
					    'purchase': {
					      'actionField': {
					        'id': order.id,
					        'affiliation': 'Tienda Mycook',
					        'revenue': order.total,
					        'tax': order.totalTaxes.total,
					        'shipping': order.total - order.totalWithoutShipping
					      },
					      'products': cartProductItems(order.products)
					    }
					  }
					};

					if(order.voucher && order.voucher.code && order.voucher.status == 'applied') {
						object.ecommerce.purchase.actionField.coupon = order.voucher.code;
					}

					sendToAnalytics(object);
				};

				this.addProductImpression = function(products, page) {
					storeFactory.getConfiguration().then(function (store_config) {
	          var currency = store_config.displayCurrency || 'EUR';

						var object = {
							'event': 'productImpressions',
						  'ecommerce': {
						    'currencyCode': currency,
						    'impressions': listProductItems(products, page)
						  }
						};

						sendToAnalytics(object);
	        });
				};

				this.addProductClick = function(product, position, list) {
					var object = {
						'event': 'productClick',
					  'ecommerce': {
					    'click': {
					    	'actionField': {'list': list},
					    	'products': [{
				          'name': product.name,
				          'id': product.niceName,
				          'price': product.price,
				          'category': getProductCategories(product.category),
				          'position': position
				        }]
					    }
					  }
					};

					sendToAnalytics(object);
				}

				this.singleProductView = function(product, related) {
					var object = {
						'event': 'productDetailView',
					  'ecommerce': {
							'detail': {
								//'actionField': {'list': 'Apparel Gallery'}  // 'detail' actions have an optional list property.
								'products': [{
									'name': product.name,
									'id': product.niceName,
									'price': product.price,
									'category': getProductCategories(product.category)
								}]
							}
					  }
					};

					//if(related.length > 0) object.ecommerce.impressions = listProductItems(related, 'single-product-related');

					sendToAnalytics(object);
				}
		}]);
})();