/**
 *  - recipesFactory
 *  - RecipeController
 *  - AsideController
 */

(function () {

  'use strict';

  angular.module('mainApp')
    .directive("starRating", [
      function () {
        return {
          restrict: 'EA',
          replace: true,
          template:
            '<div ng-class="className" class="svg-element">' +
              '<div class="scaling-svg-container" ng-attr-style="padding-bottom: {{ 100 * 16 / (stars.length * 16) }}%;">' +
                '<svg role="img" class="scaling-svg star-review" viewBox="{{ \'0 0 \' + (stars.length * 16) + \' 16\' }}">' +
                  '<use ng-repeat="star in stars" ng-class="star.filled" ng-click="toggle($index)" xlink:href="#star" ng-attr-x="{{ 16 * $index }}" y="0" />' +
                '</svg>' +
              '</div>' +
            '</div>',
            /*
            viewBox="{{ x + \' \' + y + \' \' + w + \' \' + h }}"
            */
          scope: {
            ratingValue: '=ngModel',
            max: '=?', // optional (default is 5)
            readonly: '=?',
            /*x: '=?',
            y: '=?',
            w: '=?',
            h: '=?',*/
            className: '=?'
          },
          link: function(scope, element, attributes) {
            if (scope.max == undefined) scope.max = 5;
            /*if (scope.x == undefined) scope.x = 0;
            if (scope.y == undefined) scope.y = 0;
            if (scope.w == undefined) scope.w = 16 * scope.max;
            if (scope.h == undefined) scope.h = 16;*/

            function updateStars() {
              scope.stars = [];
              for (var i = 0; i < scope.max; i++) {
                var stateClass = ((i+1) <= scope.ratingValue) ? 'star--full' : 'star--empty';
                // has another half rating, add half star
                if ((i+1) - scope.ratingValue < 1 &&  (i+1) - scope.ratingValue > 0) { // mitjos
                  stateClass = 'star--half';
                }
                scope.stars.push({
                  filled: stateClass
                });
              }
            };

            scope.toggle = function(index) {
              if (scope.readonly == undefined || scope.readonly === false) {
                scope.ratingValue = index + 1;
              }
            };

            scope.$watch('ratingValue', function(oldValue, newValue) {
              if (newValue || newValue === 0 || newValue === undefined) {
                updateStars();
              }
            });
          }
        };
      }])

    .directive("commentAuthor", ['$compile', '$filter', 'toolsService', 'serviceRanks', 'saRouter',
      function ($compile, $filter, toolsService, serviceRanks, saRouter) {
        return {
          restrict: "E",
          scope: {
            modelo: "="
          },
          replace: true,
          link: function (scope, elem, attrs) {
            var route = saRouter.getRoute('authorRecipeList');
            var url = saRouter.replaceUrlParams(route.url, { user: scope.modelo.niceName });

            var htmlCode = '<strong>{{modelo.displayName}}</strong>';
            if (!toolsService.isEmpty(scope.modelo.niceName)) {
              htmlCode = '<a href="' + url + '" rel="nofollow">' + htmlCode + '</a>';
            }

            serviceRanks.getRanks().then(function (ranks) {
              if (scope.modelo.rank) {
                var found = $filter('filter')(ranks, { niceName: scope.modelo.rank }, true);
                if (found.length > 0) {
                  scope.modelo.rank = found[0].name;
                }
              } else {
                scope.modelo.rank = '';
              }
            });

            htmlCode += '<div class="comment-info">';
            htmlCode += '<strong>{{modelo.rank}}</strong>';
            htmlCode += '<time datetime="{{modelo.date | date: \'yyyy-MM-dd HH:mm:ss+0200\'}}" am-time-ago="modelo.date"></time>';
            htmlCode += '</div>';
            elem.html(htmlCode);
            $compile(elem.contents())(scope);
          }
        };
      }]);

})();