(function () {

  'use strict';

  angular.module('mainApp')

    .controller("RecipesBySearchController", ['$rootScope', '$scope', '$filter', '$q', '$routeParams', '$location', '_', 'toolsService', 'appConfig', 'recipesFactory', 'seoService', 'recipeListService', 'richSearchService', 'sessionService', 'serviceLandings',
      function ($rootScope, $scope, $filter, $q, $routeParams, $location, _, toolsService, appConfig, recipesFactory, seoService, recipeListService, richSearchService, sessionService, serviceLandings) {
        var promises = [];
        var criteria = {};
        var pageQuery = '?';
        var term = '';
        var currentPage = ($routeParams.currentPage) ? $routeParams.currentPage : 1;

        $scope.currentPage = parseInt(currentPage);

        $scope.showDeleteDialog = function (niceName, title) {
          recipeListService.showDeleteDialog(niceName, title);
        };

        $scope.showPublishDialog = function (recipe, title) {
          recipeListService.showPublishDialog(recipe, title, $scope.deviceDetector.raw.userAgent);
        };

        var orderInfo = recipeListService.setOrderFilters(criteria, pageQuery);
        pageQuery = orderInfo.pageQuery;
        
        var urlVariables = $location.$$search;
        if(urlVariables.terms) term = urlVariables.terms;
        pageQuery += '&terms=' + term;
        promises.push(recipesFactory.getTags());
        if(appConfig.webSettings.richSearch) promises.push(richSearchService.getByTerm(term));

        var searchLandingsCounter = sessionService.getValue('terms-' + term, 'session');
        var offSetItems = (searchLandingsCounter) ? searchLandingsCounter : 0;
        console.log('offSetItems at ' + term, offSetItems);

        if(($scope.currentPage == 1 || !searchLandingsCounter) && appConfig.webSettings.landingsAtRecipesList) {
          // get Landings & ebooks
          var landingProjection = {
            title: 1,
            img: 1,
            niceName: 1,
            type: 1,
            shortDescription: 1,
            _id: 0
          };

          promises.push(serviceLandings.getLandingsByTerm(term, 'terms', landingProjection));
        }
        
        criteria = orderInfo.criteria;
        criteria = _.clone($location.$$search, true);
        criteria['status.idParent'] = '';
        $scope.pageQuery = pageQuery;
        $scope.criteria = criteria;

        $q.all(promises).then(function(results) {
          var tagsBundle = results[0];
          var richSearch = results[1];
          if(results[2]) var landingsBundle = results[2];

          $scope.tags = tagsBundle.tags;
          $scope.tagsDietas = tagsBundle.tagsDietas;

          if($scope.currentPage == 1 && landingsBundle && landingsBundle.status.count > 0) {
            landingsBundle.results = toolsService.getRandomValuesFromArray(landingsBundle.results, 3);
            offSetItems = landingsBundle.results.length;

            _.map(landingsBundle.results, function(obj) {
              if(obj.type == 'ebook') obj.niceName_noebook = obj.niceName.replace('ebook-', '');
              return obj;
            });
          }

          // Save counter in session
          sessionService.setValue('terms-' + term, offSetItems, 'session');

          $scope.$emit('LOAD');

          recipesFactory.searchRecipes(criteria, $scope.currentPage, offSetItems).then(function (recipes) {
            // change custom contents by rich search
            if(richSearch) {
              seoService.setTitle($filter('translateVariables')(richSearch.seo.title, recipes.status.count), $scope.currentPage);
              seoService.setDescription(richSearch.seo.description, $scope.currentPage);

              $scope.recipesTitle = richSearch.title;
              $scope.richSearch = richSearch;
            } else {
              var pageTitle = $filter('translateVariables')($rootScope.translations.seo.searchResult.title, term);
              seoService.setTitle(pageTitle, $scope.currentPage);
              seoService.setDescription($rootScope.translations.seo.searchResult.description, $scope.currentPage);
              $scope.recipesTitle = pageTitle;
            }

            var bundleRecipesAndLandings = recipes.result;

            // SET LANDING DATA TO VIEW
            if(landingsBundle && landingsBundle.status.count > 0) {
              bundleRecipesAndLandings = toolsService.pushElementsAtPositions(recipes.result, landingsBundle.results);
            }

            console.log('//////////// RecipesByCategory > bundleRecipesAndLandings', bundleRecipesAndLandings);

            $scope.contentData = bundleRecipesAndLandings;

            // Paginacion
            var _maxCount = recipes.status.count
            if(offSetItems > 0) _maxCount += offSetItems;
            $scope.maxCount = _maxCount;
            $scope.itemsByPage = appConfig.itemsByPage;
            $scope.lastPage = (_maxCount > appConfig.itemsByPage) ? Math.ceil(_maxCount / appConfig.itemsByPage) : 1;

            seoService.setPrevPage($scope.currentPage, $scope.pageQuery);
            seoService.setNextPage($scope.currentPage, $scope.pageQuery, $scope.lastPage);

            $scope.$emit('UNLOAD');

          }).catch(function (error) {
            toolsService.manageError("RecipesBySearchController > getRecipesByCriteria > ERROR1: ", error);
          });
        });
      }
    ]);
})();