(function() {

    'use strict';

    angular.module('ngCart.directives', ['ngCart.fulfilment'])

        .directive('ngcartAddtocart', ['$templateCache', 'ngCart', 'timestamp', function($templateCache, ngCart, timestamp) {
            return {
                restrict: 'E',
                controller: 'CartController',
                scope: {
                    nicename: '@',
                    name: '@',
                    quantity: '@',
                    quantityMax: '@',
                    price: '@',
                    data: '='
                },
                transclude: true,
                template: function(element, attrs) {
                    return $templateCache.get('template/store/single-product/add-to-cart.html');
                },
                link: function(scope, element, attrs) {
                    scope.attrs = attrs;

                    scope.inCart = function() {
                        return ngCart.getItemByNiceName(attrs.nicename);
                    };

                    if (scope.inCart()) {
                        scope.q = ngCart.getItemByNiceName(attrs.nicename).getQuantity();
                    } else {
                        scope.q = parseInt(scope.quantity);
                    }

                    scope.qtyOpt =  [];
                    for (var i = 1; i <= scope.quantityMax; i++) {
                        scope.qtyOpt.push(i);
                    }
                }
            };
        }])

        .directive('ngcartCartTable', ['timestamp', function($rootScope, timestamp) {
            return {
                restrict : 'E',
                controller : 'CartController',
                scope: false,
                templateUrl: function(element, attrs) {
                    if ( typeof attrs.templateUrl == 'undefined' ) {
                        return '/template/store/cart/cart-table.html?rev=' + timestamp;
                    } else {
                        return attrs.templateUrl;
                    }
                },
                link: function(scope, element, attrs) {
                    /*console.log('ngcartCartTable paso', $rootScope)
                    console.log('ngcartCartTable paso 2', $rootScope.windowWidth);

                    scope.windowWidth = $rootScope.windowWidth;*/
                }
            };
        }])

        .directive('ngcartSummary', ['$templateCache', 'timestamp', function($templateCache, timestamp) {
            return {
                restrict : 'E',
                controller : 'CartController',
                scope: {
                    translations: '='
                },
                transclude: true,
                template: function(element, attrs) {
                    return $templateCache.get('template/store/cart/summary.html');
                }
            };
        }])

        .directive('ngcartCheckout', ['timestamp', function(timestamp) {
            return {
                restrict : 'E',
                controller : ('CartController', ['$scope', 'ngCart', 'fulfilmentProvider','addressSvc', function($scope, ngCart, fulfilmentProvider,addressSvc) {
                    $scope.ngCart = ngCart;

                    $scope.checkout = function () {
                        fulfilmentProvider.setService($scope.service);
                        fulfilmentProvider.setSettings($scope.settings);
                        var promise = fulfilmentProvider.checkout();
                    };
                }]),
                scope: {
                    service:'@',
                    settings:'='
                },
                transclude: true,
                templateUrl: function(element, attrs) {
                    if ( typeof attrs.templateUrl == 'undefined' ) {
                        return '/template/store/cart/checkout.html?rev=' + timestamp;
                    } else {
                        return attrs.templateUrl;
                    }
                }
            };
        }]);
})();