(function () {
  angular.module('mainApp')

    .controller('AffiliatesProfileController', [function ($scope, $rootScope, affiliatesUsersManager, webStorage) {
      var affiliateNiceName = webStorage.get('userNiceNameAffiliates');
      var profile;

      affiliatesUsersManager.getAffiliateInfo(affiliateNiceName, profile).then(function (data) {
        $scope.affiliateData = data;
      });

      $scope.submitForm = function (valid, affiliateData) {
        if (valid) {
          affiliatesUsersManager.updateAffiliateInfo(affiliateNiceName, affiliateData).then(function (data) {
            if (data === true) {
              $scope.success = true;
            } else {
              $scope.success = false;
            }
          });
        }
      }
    }]);
})();
