(function () {

  'use strict';

  angular.module('mainApp')
    
  .directive("filterRecipes", ['$location', 
    function ($location) {
      return {
        restrict: 'E',
        replace: true,
        transclude: true,
        template: '<div ng-transclude></div>',
        scope: {
          pagePath: '@'
        },
        link: function(scope) {
          if (scope.pagePath == undefined) scope.pagePath = $location.path();

          var path = scope.pagePath;
          var locationServer = $location.protocol() + '://' + $location.host();
          
          if($location.host().indexOf('localhost') > -1) { // Development URL (add port number)
            locationServer += ':' +  $location.port();
          }

          path = locationServer + path;
          scope.pagePath = path;
        }
      };
    }]);
})();