(function () {

  'use strict';

  angular.module('mainApp')

    .factory('sessionService', ['$window', '$rootScope', '$route', '$timeout', '$http', '$q', 'webStorage', 'toolsService', 'appConfig',
      function ($window, $rootScope, $route, $timeout, $http, $q, webStorage, toolsService, appConfig) {
        if (!appConfig.isCacheEnabled) {
          webStorage.order(['memory']);
        } else {
          webStorage.order(['local', 'session', 'memory']);
        }

        var s = 'session';
        var now = new Date();
        var year = now.getFullYear();
        var month = now.getMonth() + 1;
        var day = now.getDate();
        var currentDate = year + '.' + month + '.' + day;
        webStorage.prefix(appConfig.appName + '-' + currentDate + '-');

        return {
          getUserToken: function (affiliates) {
            var affiliates_txt = '';
            if (affiliates && affiliates == true) affiliates_txt = 'Affiliates';

            var token = $window.sessionStorage['mcUserToken' + affiliates_txt];
            if (!toolsService.isEmpty(token)) {
              webStorage.set('mcUserToken' + affiliates_txt, token);
            } else {
              token = webStorage.get('mcUserToken' + affiliates_txt);
            }
            return token;
          },

          setUserToken: function (value, affiliates) {
            var affiliates_txt = '';
            if (affiliates && affiliates == true) affiliates_txt = 'Affiliates';
            webStorage.set('mcUserToken' + affiliates_txt, value);
          },

          logoutUser: function (delay, redirect) {
            webStorage.clear();
            var urlToRedirect;

            if ($rootScope.pageType == 'affiliates') {
              urlToRedirect = $rootScope.url('affiliates-home');
            } else {
              urlToRedirect = $rootScope.url('home');
            }

            if (redirect || ($route && $route.current && $route.current.$$route && $route.current.$$route.private)) {
              $timeout(function () {
                $window.location.href = urlToRedirect;
              }, (delay || 0));
            }
          },

          getValue: function (key, storageType) {
            storageType = storageType || 'local';
            var currentSession = this.getSession(storageType);
            var returnElement = (currentSession && currentSession[key] != null) ? currentSession[key] : null;
            return returnElement;
          },

          getSession: function (storageType) {
            var appSession = webStorage[storageType].get(s);

            if (!appSession) {
              appSession = {};
            }

            return appSession;
          },

          setValue: function (key, value, storageType) {
            storageType = storageType || 'local';
            var currentSession = this.getSession(storageType);

            currentSession[key] = value;
            webStorage[storageType].set(s, currentSession);
          },

          getUserIP: function () {
            var sessionService = this;
            var defer = $q.defer();
            var user_ip = sessionService.getValue('user_ip');

            if (!toolsService.isEmpty(user_ip)) {
              defer.resolve(user_ip);
            } else {
              var urlDestino = 'https://extreme-ip-lookup.com/json/'; //https://ipv4.myexternalip.com/json

              $http({
                method: 'GET',
                url: urlDestino
              }).then(function(result) {
                if(result && result.data) {
                  //result.data.countryCode = 'RU';
                  user_ip = result.data;
                  sessionService.setValue('user_ip', user_ip);
                  defer.resolve(user_ip);
                }
              }, function (err) {
                defer.reject(err);
              });
            }

            return defer.promise;
          }
        };
      }
    ]);
})();