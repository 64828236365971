(function (){
	angular.module('mainApp')

		.directive('genericProduct', function ($rootScope, $location, appConfig, timestamp, serviceStoreAnalytics) {
		  var directive = {};
		  directive.restrict = 'E';

		  directive.scope = {
        item: '=',
	      currency: '=',
	      translations: '=',
	      position: '=',
	      list: '='
		  };

		  directive.templateUrl = function (elem, attrs) {
        // Si ho carreguem del template peta :)
	      return 'template/store/' + attrs.templateType + '.html?rev=' + timestamp;
		  };

		  directive.link = function($scope, element, attrs) {
				$scope.productClicked = function(item, position) {
					serviceStoreAnalytics.addProductClick(item, position, $scope.list);

					$location.path($rootScope.url('store-product', { niceName: item.niceName } ));
				}
			}

		  return directive;
		})

		.controller('StoreProductController', [function($rootScope, $scope, $filter, $timeout, $routeParams, $log, saRouter, appConfig, storeFactory, serviceStoreAnalytics, serviceMautic, sessionService) {

			/*function getProductUrl(niceName) {
			  var route = saRouter.getRoute('store-product');
			  return saRouter.replaceUrlParams(route.url, { niceName: niceName});
      }*/
      
      sessionService.getUserIP().then(function(result) {
        $scope.countryCode = result.countryCode;
      });

			$scope.viewAddToCart = true;
			var related_view = false;
			var product_view = true;

			var timerImpressions;
			var arrImpressions = new Array();
      var listProductViews = new Array();
      
      $scope.email_stock = '';
      $scope.submitStockForm = function(email, niceName, price) {
        var data = {
          "email": email,
          "formId": '29',
          "modelo_mycook": niceName,
          "precio": price
        };

        var productUrl = $rootScope.url('store-product', {'niceName': niceName});

        serviceMautic.sendToMautic(data, '', productUrl).then(function(response) {
          $scope.email_stock = '';
          $scope.formMessageOK = true;
          $scope.formMessageKO = false;
        }, function(error) {
          $log.debug(error);
          $scope.formMessageKO = true;
          $scope.formMessageOK = false;
        });
      };

			$scope.productView = function(element, inview) {
				if(element == 'related') related_view = inview;
				if(element == 'product') product_view = inview;

				if((element == 'product' && inview === true) && !related_view) {
					$scope.viewAddToCart = true;
				} else if((element == 'product' && inview === true) && related_view) { 
					$scope.viewAddToCart = false;
				} else if(element == 'related' && product_view) {
					$scope.viewAddToCart = !inview;
				}
				return false;
			}

			var productNiceName = $routeParams.niceName;

      storeFactory.getConfiguration().then(function (config) {
        $scope.currency = config.displayCurrency;
      });

			storeFactory.getProduct(productNiceName).then(function (product) {
				console.log('StoreProductController > getProduct', product);

        var seo_title = (product.cms.seo.title) ? product.cms.seo.title : product.name;
        seo_title += " " + $rootScope.translations.seo.separator + " " + $rootScope.translations.seo.website_name;
        $rootScope.pageTitle = seo_title;
        $rootScope.pageDescription = (product.cms.seo.description) ? product.cms.seo.description : $filter('stripTags')(product.shortDescription);

        var robots = '';
        if(product.cms.seo.index) { robots += 'index'; } else { robots += 'noindex'; }
        if(product.cms.seo.follow) { robots += ',follow'; } else { robots += ',nofollow'; }

        $rootScope.seo_robots = robots;
        
        if(product.images.length > 0) {
        	$rootScope.pageImage = appConfig.currentDomain + product.images[0];
        }

        function getProductUrl(niceName) {
				  var route = saRouter.getRoute('store-product');
				  return saRouter.replaceUrlParams(route.url, { niceName: niceName });
				}

        for (var i = 0; i < product.relatedProducts.length; i++) {
          product.relatedProducts[i].url = getProductUrl(product.relatedProducts[i].cms.url.slug);
        }

				serviceStoreAnalytics.singleProductView(product, product.relatedProducts);

				$scope.item = product;
			});

			$scope.relatedProductView = function(element, inview, position) {
				var el = element;
				el.positionImpression = position;

				if(inview && listProductViews.indexOf(el.niceName) === -1) {
					$timeout.cancel(timerImpressions);
					listProductViews.push(el.niceName);

					arrImpressions.push(el);

					timerImpressions = $timeout(function() {
						sendImpressions();

						arrImpressions = [];
					}, 5000);
				}
				
				return false;
			}

			function sendImpressions() {
				$timeout.cancel(timerImpressions);

				serviceStoreAnalytics.addProductImpression(arrImpressions, 'single-product-related');
			}

		}]);
})();