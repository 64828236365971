(function () {
  angular.module('mainApp')

    .service('serviceApiAffiliates', function ($rootScope, $http, $q, $location, $route, $filter, appConfig, sessionService) {

      var getBanners = function (affiliate, type) {
        var defer = $q.defer();
        var url = appConfig.localApiBasePath + '/affiliates/banners/' + affiliate + '/' + type;

        $http({
          method: 'GET',
          url: url,
          headers: {
            'Authorization': 'Bearer ' + sessionService.getUserToken(true)
          }
        }).then(function (res) {
          var data = res.data;
          defer.resolve(data);
        });

        return defer.promise;
      };

      var getResources = function (type) {
        var defer = $q.defer();
        var url = appConfig.localApiBasePath + '/resources';

        $http({
          method: 'GET',
          url: url,
          headers: {
            'Authorization': 'Bearer ' + sessionService.getUserToken(true)
          }
        }).then(function (res) {
          var data = res.data.result[0];
          defer.resolve(data);
        });

        return defer.promise;
      };

      var getIncomes = function (affiliate) {
        var defer = $q.defer();
        var url = appConfig.localApiBasePath + '/conversions?profile=frontend';
        var queryData = {};

        queryData.query = {
          "affiliate.niceName": affiliate,
          returned: false
        }
        $http({
          method: 'POST',
          url: url,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionService.getUserToken(true)
          },
          data: queryData
        }).then(function (res) {
          var data = res.data.result;
          defer.resolve(data);
        });

        return defer.promise;
      };

      return { getBanners: getBanners, getResources: getResources, getIncomes: getIncomes };
    })

})();
