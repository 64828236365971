(function () {

  'use strict';

  angular.module('mainApp')

  .directive("pagination", ['$templateCache', '$location', 'toolsService',
    function ($templateCache, $location, toolsService) {
      return {
        restrict: 'E',
        replace: true,
        template: function(element, attrs) {
          return $templateCache.get('template/pager.html');
        },
        scope: {
          pagePath: '=?',
          pageQuery: '=?',
          currentPage: '=',
          lastPage: '=',
          firstPage: '=?',
          pageTranslation: '=?'
        },
        link: function(scope) {
          if (scope.firstPage == undefined) scope.firstPage = 1;
          if (scope.pageTranslation == undefined) scope.pageTranslation = 'page';
          if (scope.pagePath == undefined) scope.pagePath = $location.path();
          if (scope.pageQuery == undefined) scope.pageQuery = toolsService.fromObjToQuery($location.$$search, []);
          
          // convert string to integer
          if (scope.currentPage != undefined) scope.currentPage = parseInt(scope.currentPage);
          if (scope.firstPage != undefined) scope.firstPage = parseInt(scope.firstPage);

          var path = scope.pagePath;

          var hasPage = path.indexOf("/" + scope.pageTranslation);
          if (hasPage > -1) {
            path = path.substring(0, hasPage);
          }

          var locationServer = $location.protocol() + '://' + $location.host();

          if($location.host().indexOf('localhost') > -1) { // Development URL (add port number)
            locationServer += ':' +  $location.port();
          }

          path = locationServer + path;
          scope.pagePath = path;
        }
      };
    }]);
})();