(function () {

	'use strict';

	angular.module('mainApp')

		.factory('filtersManager', ['$rootScope', function ($rootScope) {

			var recipesManager = {};

			recipesManager.getFilters = function () {
				var bundle = {};
				bundle.accessoriesNoMycook = ["spatula", "steamer", "basket"];
				bundle.seconds = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];
				bundle.minutesMycook = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60];
				bundle.minutes = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59];
				bundle.hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

				bundle.speeds = [{k: 'S', v: '1/S'}, {k: '2', v: '2'}, {k: '3', v: '3'}, {k: '4', v: '4'}, {
					k: '5',
					v: '5'
				}, {k: '6', v: '6'}, {k: '7', v: '7'}, {k: '8', v: '8'}, {k: '9', v: '9'}, {k: '10', v: '10'}, {
					k: 'A',
					v: $rootScope.translations.common.speeds.knead
				}, {k: 'Turbo', v: $rootScope.translations.common.speeds.turbo}, {
					k: 'Progressiva',
					v: $rootScope.translations.common.speeds.progressive
				}];

				bundle.speedsTouch = [{k: '1', v: '1'}, {k: '2', v: '2'}, {k: '3', v: '3'}, {k: '4', v: '4'}, {k: '5', v: '5'}, {k: '6', v: '6'}, {k: '7', v: '7'}, {k: '8', v: '8'}, {k: '9', v: '9'}, {k: '10', v: '10'}, {
					k: 'S',
					v: $rootScope.translations.common.speeds.saute
				}, {k: 'A', v: $rootScope.translations.common.speeds.knead}, {
					k: 'Turbo',
					v: $rootScope.translations.common.speeds.turbo
				}, {k: 'Progressiva', v: $rootScope.translations.common.speeds.progressive}];

				bundle.temperatures = [{k: 40, v: '40º'}, {k: 50, v: '50º'}, {k: 60, v: '60º'}, {k: 70, v: '70º'}, {k: 80, v: '80º'}, {k: 90, v: '90º'}, {k: 100, v: '100º'}, {k: 110, v: '110º'}, {k: 120, v: '120º'}];

				bundle.temperaturesTouch = [{k: 40, v: '40º'}, {k: 50, v: '50º'}, {k: 60, v: '60º'}, {k: 70, v: '70º'}, {k: 80, v: '80º'}, {k: 90, v: '90º'}, {k: 100, v: '100º'}, {k: 110, v: '110º'}, {
					k: 120,
					v: '120º'
        }, {k: 130, v: '130º'}, {k: 140, v: '140º'}];
        
        bundle.coursesObject = {
          "breakfast": ['Breakfast'],
          "appetizer": ['Morning snack'],
          "snack": ['Afternoon snack'],
          "first": ['Lunch starter', 'Diner starter'],
          "second": ['Lunch main', 'Diner main'],
          "dessert": ['Lunch desert', 'Diner desert']
        };

				return bundle;
			};

			return recipesManager;
		}])

		.factory('ingredientsManager', ['$rootScope', '$http', '$q', '_', 'appConfig', 'sessionService', 'toolsService',
			function ($rootScope, $http, $q, _, appConfig, sessionService, toolsService) {
				var ingredients = {};

				ingredients.getIngredients = function () {
					var deferredIngredients;
					if (angular.isUndefined(deferredIngredients)) {
						deferredIngredients = $q.defer();
						var ingredientsBundle = sessionService.getValue('ingredients');

						if (!toolsService.isEmpty(ingredientsBundle)) {
							deferredIngredients.resolve(ingredientsBundle);
						} else {
							var urlDestino = appConfig.mycookTouchDomain + '/ingredients/';
							$http({
								method: 'get',
								url: urlDestino
							}).then(function (ingredients) {
								ingredientsBundle = ingredients.data;
								sessionService.setValue('ingredients', ingredientsBundle);
								deferredIngredients.resolve(ingredientsBundle);
							});
						}
					}
					return deferredIngredients.promise;
				};

				ingredients.getMeasurements = function () {
					var ingredientMeasures = [
						{
							'key': 'tsp',
							'name': $rootScope.translations.common.measures.tsp
						},
						{
							'key': 'tbsp',
							'name': $rootScope.translations.common.measures.tbsp
						},
						{
							'key': 'drp',
							'name': $rootScope.translations.common.measures.drp
						},
						{
							'key': 'gr',
							'name': $rootScope.translations.common.measures.gr
						},
						{
							'key': 'l',
							'name': $rootScope.translations.common.measures.l
						},
						{
							'key': 'coy',
							'name': $rootScope.translations.common.measures.coy
						},
						{
							'key': 'ml',
							'name': $rootScope.translations.common.measures.ml
						},
						{
							'key': 'pkt',
							'name': $rootScope.translations.common.measures.pkt
						},
						{
							'key': 'pch',
							'name': $rootScope.translations.common.measures.pch
						},
						{
							'key': 'scht',
							'name': $rootScope.translations.common.measures.scht
						},
						{
							'key': 'slc',
							'name': $rootScope.translations.common.measures.slc
						},
						{
							'key': 'bch',
							'name': $rootScope.translations.common.measures.bch
						},
						{
							'key': 'cp',
							'name': $rootScope.translations.common.measures.cp
						},
						{
							'key': 'u',
							'name': $rootScope.translations.common.measures.u
						},
						{
							'key': 'clv',
							'name': $rootScope.translations.common.measures.clv
						},
						{
							'key': 'lea',
							'name': $rootScope.translations.common.measures.lea
						},
						{
							'key': 'mc',
							'name': $rootScope.translations.common.measures.mc
						}
					];
					ingredientMeasures = _.orderBy(ingredientMeasures, 'name');

					ingredientMeasures.unshift({
						'key': '',
						'name': $rootScope.translations.new_recipe.select
					});

					return ingredientMeasures;
				};

				return ingredients;
		}]);
})();