(function () {
  angular.module('mainApp')

    .factory('affiliatesContactFactory', ['$http', '$q', 'appConfig', 'toolsService', 
      function ($http, $q, appConfig, toolsService) {

        var _contactFactory = {};

        _contactFactory.sendContactInformation = function (userData) {

          deferredResponse = $q.defer();
          var urlDestino = appConfig.localApiBasePath + '/affiliatecontact';

          var message = userData.message || userData.website_information;

          var data = {
            "contact_email": userData.contact_email,
            "topic": userData.subject.name,
            "name": userData.name,
            "email": userData.mail,
            "city": userData.town,
            "phone": userData.phone,
            "site": userData.web,
            "message": message,
            "date": new Date().toISOString()
          };

          $http({
            method: 'post',
            data: data,
            url: urlDestino
          }).then(function (res) {
            deferredResponse.resolve(true);
          }, function errorCallback(response) {
            deferredResponse.resolve(false);
          });

          return deferredResponse.promise;
        }

        return _contactFactory;
      }])

    .controller('AffiliatesContactController', [function ($scope, $rootScope, affiliatesContactFactory) {
      $scope.userData = {
        //"policy": true
      };

      /*$scope.$watch('currentUserAffiliates.niceName', function(niceName) {
        if($scope.currentUserAffiliates) {
          $scope.userData['name'] = $scope.currentUserAffiliates.name.firstName + ' ' + $scope.currentUserAffiliates.name.lastName;
          $scope.userData['mail'] = $scope.currentUserAffiliates.contact.mail;
          $scope.userData['town'] = $scope.currentUserAffiliates.location.town;
        }
      }, true);*/


      $scope.sendContactForm = function () {
        console.log('AffiliatesContactController > sendContactForm', $scope.userData)
        affiliatesContactFactory.sendContactInformation($scope.userData).then(function (response) {

          if (response === true) {
            $scope.contactForm.$setPristine();
            $scope.userData = {};
            $scope.successContact = true;
          } else {
            $scope.successContact = false;
          }
        });
      }
    }]);
})();