(function () {

  'use strict';

  angular.module('mainApp')

    .controller("RecipesByAuthorController", ['$rootScope', '$scope', '$filter', '$routeParams',
      '_', 'toolsService', 'appConfig', 'recipesFactory', 'seoService', 'recipeListService', 'usersManager',
      function ($rootScope, $scope, $filter, $routeParams,
        _, toolsService, appConfig, recipesFactory, seoService, recipeListService, usersManager) {

        var criteria = {};
        var pageQuery = '?';
        var currentPage = ($routeParams.currentPage) ? $routeParams.currentPage : 1;

        $scope.currentPage = parseInt(currentPage);

        $scope.showDeleteDialog = function (niceName, title) {
          recipeListService.showDeleteDialog(niceName, title);
        };

        $scope.showPublishDialog = function (recipe, title) {
          recipeListService.showPublishDialog(recipe, title, $scope.deviceDetector.raw.userAgent);
        };

        $scope.badgeIsDeactivate = function (bdgCode, userBadges) {
          var isDeactivate = (toolsService.isEmpty(bdgCode) || toolsService.isEmpty(userBadges) || userBadges.indexOf(bdgCode) === -1);
          return isDeactivate;
        };

        var orderInfo = recipeListService.setOrderFilters(criteria, pageQuery);
        pageQuery = orderInfo.pageQuery;
        criteria = orderInfo.criteria;
        criteria.user = $routeParams.user;
        criteria['status.idParent'] = '';
        $scope.pageQuery = pageQuery;
        $scope.criteria = criteria;

        //REFACTOR Q ALL

        usersManager.getUserInfo($routeParams.user, 'public').then(function (data) {
          $scope.currentAuthor = data;

          var author = $filter('capitalize')(data.name.displayName);
          $scope.recipesTitle = author;
          seoService.setTitle($filter('translateVariables')($rootScope.translations.seo.authorRecipeList.title, author));
          seoService.setDescription($filter('translateVariables')($rootScope.translations.seo.authorRecipeList.description, author));
        }, function (error) {
          toolsService.manageError('RecipesByAuthor -> getAuthorInfo  > ERROR', error, true);
        });

        recipesFactory.getTags().then(function (tags) {
          var tagsBundle = tags;

          $scope.tags = tagsBundle.tags;
          $scope.tagsDietas = tagsBundle.tagsDietas;

          $scope.$emit('LOAD');

          recipesFactory.getRecipesByCriteria(criteria, $scope.currentPage).then(function (responseBundle) {

            $scope.contentData = responseBundle.result;
            // Paginacion
            var _maxCount = responseBundle.status.count;
            $scope.maxCount = _maxCount;
            $scope.itemsByPage = appConfig.itemsByPage;
            $scope.lastPage = (_maxCount > appConfig.itemsByPage) ? Math.ceil(_maxCount / appConfig.itemsByPage) : 1;

            seoService.setPrevPage($scope.currentPage, $scope.pageQuery);
            seoService.setNextPage($scope.currentPage, $scope.pageQuery, $scope.lastPage);

            $scope.$emit('UNLOAD');

          }).catch(function (error) {
            toolsService.manageError("RecipesByAuthor > getRecipesByCriteria > ERROR: ", error);
          });
        });
      }
    ]);
})();