/**
 *  - commaSeparated
 *  - filterHours
 *  - filterMins
 *  - filterUrl
 *  - filterNull
 *  - filterAccessory
 *
 */

(function () {

    'use strict';

    angular.module('recipesFilters', [])

        .filter('commaSeparated', function () {
            return function (inputList) {
                return inputList && inputList.length > 0 ? inputList.join(', ') : '';
            };
        })

        .filter('removeCurrentPax', function () {
            return function(items, search) {
                if(!items || !search) {
                    return items;
                }

                var filtered = {};

                Object.keys(items).forEach(function(key) {
                    if(key !== search) {
                        filtered[key] = items[key];
                    }
                });
                return filtered;
            };
        })

        .filter('countWords', function () {
            return function (str, totalWords, ending) {
                var str_array = str.split(' ');

                if (str_array.length > totalWords) {
                    str = "";
                    for (var i = 0; i < totalWords; i++) {
                        str += str_array[i] + " ";
                    }
                    str += ending;
                } else {
                    str += '.';
                }
                return str;
            };
        })

        // new recipe filter
        .filter('showTags', function (appConfig) {
            return function (items, role) {
                if (items) {
                    return items.filter(function (item) {
                        return ( item.name !== 'Recetario' || appConfig.adminRoles.indexOf(role) > -1 );
                    });
                }
                return items;
            };
        })

        .filter('formatTemp', ['toolsService', function (toolsService) {
            return function (input, formatType) {
                var output = input;
                if (!toolsService.isEmpty(output) && formatType === true) {
                    if (input === 130 || input === 140) {
                        output = 120;
                    }
                }
                return output + 'º';
            };
        }])

        .filter('formatSpeed', ['$rootScope', 'toolsService', function ($rootScope, toolsService) {
            return function (input, formatType) {
                var output = input;
                if (input === "S") {
                    output = 'S (' + $rootScope.translations.common.speeds.saute + ')';
                } else if (!toolsService.isEmpty(input) && formatType === true) {
                    if (input === "1") { // En realidad el cambio es: "si es 1 tiene que mostrarse un 2", lo dejo así porque no existe ese caso aún.
                        // si es progresiva llega un Num-Num
                        output = "2";
                    }
                }
                return output;
            };
        }])

        .filter('filterHours', ['$rootScope', function ($rootScope) {
            return function (totalTime) {
                var tTime = parseInt(totalTime, 10);
                var totalHours = '?';
                if (!isNaN(tTime) && tTime > 0) {
                    totalHours = Math.floor(tTime / 3600);
                    totalHours = (totalHours > 0) ? totalHours += ' ' + $rootScope.translations.common.hours_abbreviation + ' ' : '';
                }
                return totalHours;
            };
        }])

        .filter('addZero', function () {
            return function (input) {
                return (parseInt(input, 10) < 10) ? '0' + input : input;
            };
        })

        .filter('filterMins', ['$rootScope', function ($rootScope) {
            return function (totalTime) {
                var tTime = parseInt(totalTime, 10);
                var totalMins = '?';
                if (!isNaN(tTime)) {
                    totalMins = Math.floor(tTime / 60) % 60;
                    totalMins = (totalMins > 0) ? totalMins += ' ' + $rootScope.translations.common.minutes_abbreviation : '';
                }
                return totalMins;
            };
        }])

        .filter('filterSecs', ['$rootScope', function ($rootScope) {
            return function (totalTime) {
                var tTime = parseInt(totalTime, 10);
                var totalSecs = '?';
                if (!isNaN(tTime)) {
                    totalSecs = tTime % 60;
                    totalSecs = (totalSecs > 0) ? totalSecs += ' ' + $rootScope.translations.common.seconds_abbreviation : '';
                }
                return totalSecs;
            };
        }])

        .filter('filterUrl', function () {
            return function (urlWeb) {
                if (urlWeb !== undefined) {
                    var tokenUrl = "http://";
                    if (urlWeb.indexOf(tokenUrl) == -1 && urlWeb.indexOf('https://') == -1 && tokenUrl !== '') {
                        urlWeb = tokenUrl + urlWeb;
                    }
                }
                return urlWeb;
            };
        })

        .filter('filterNull', function () {
            return function (input) {
                if (input == null) {
                    input = 0;
                }
                return input;
            };
        })

        // Prevent 0 before divide 0 * 100 / 0 (ratings)
        .filter('filterZero', function () {
          return function (input) {
              if (input == null || input == 0) {
                input = 1;
              }
              return input;
          };
      })

        .filter('filterQty', ['toolsService', function (toolsService) {
            return function (input) {
                var output = input;

                if (!toolsService.isEmpty(input) && input.indexOf(",") > -1) {
                    output = output.replace(",", ".").replace(" ", "");
                }

                return output;
            };
        }])

        .filter('filterAccessory', ['$rootScope', function ($rootScope) {
            return function (input) {
                var output = "";
                if (input && input !== "") {
                    output = $rootScope.translations.new_recipe.step_by_step.accessories[input];
                }
                return output;
            };
        }]);

})();
