(function (){
	angular.module('mainApp')
		.controller('HomeController', [function($scope, $http, $filter, $rootScope, $log, _, appConfig, saRouter, serviceApiBlog, serviceLandings) {
			var badges;

			$http({
				method: 'get',
				url: appConfig.mycookTouchDomain+'/socialfeed/',
				params: {
					limit: 15
				}
			}).then(function(res) {
				$scope.socialfeed = $filter('filter')(res.data, {type: '!user/new'}, true); //res.data;
				badges = $rootScope.translations.badges;
			});

			$scope.getAuthorUrlLink = function(userNiceName, userDisplayName) {
				var route = saRouter.getRoute('authorRecipeList');
				var url = saRouter.replaceUrlParams(route.url, {user: userNiceName});
				return '<a href="' + url + '" title="' + userDisplayName + '" rel="nofollow">' + userDisplayName + '</a>';
			};

			// find Badge by code
			$scope.getBadgeByCode = function(code, returnValue) {
				var found = $filter('filter')(badges, {niceName: code}, true);
				if (found.length) {
					return found[0][returnValue];
				}
			};

			//get els ultims 2 posts del blog
			serviceApiBlog.getLastPosts().then(function(result) {
				var posts = result.slice(0,2);

				for (var i = 0; i < posts.length; i++) {
					posts[i].url = getPostUrl(posts[i].cms.url.slug);
					if(posts[i].imagePreview) posts[i].imagePreview = posts[i].imagePreview.replace(/ /g, '%20');
				}

				$scope.postsBanner = posts;
			});

			function getPostUrl(post) {
				var route = saRouter.getRoute('blogPost');
				return saRouter.replaceUrlParams(route.url, {postNiceName: post});
      }

      // SPAIN
      if(appConfig.region == 'ES-MYCOOKTOUCH') {
        var projection = {
          img: 1,
          title: 1,
          niceName: 1,
          visibleHome: 1,
          _id: 0
        };

        var queryParamsLandings = {
          onlyHome: true,
          limit: 10
        }

        serviceLandings.getLandingsByType('landing', queryParamsLandings, projection).then(function(landings) {
          $log.debug('HomeController > getLandingsByType', landings);
          $scope.landings = landings.results;
        });

        var queryParamsEbooks = {
          onlyHome: true,
          limit: 5
        }

        serviceLandings.getLandingsByType('ebook', queryParamsEbooks, projection).then(function(result) {
          $log.debug('HomeController > getLandingsByType', result);
          var ebooks = result.results;
          var featured_ebook = ebooks.shift();
          
          // ordenar destacado en tercera posicion
          ebooks.splice(2, 0, featured_ebook);

          // para poder añadir las imagenes en grey & white
          _.map(ebooks, function(obj) {
            obj.niceName_noebook = obj.niceName.replace('ebook-', '');
            return obj;
          });

          $scope.ebooks = ebooks;
        });
      }
		}]);
})();