(function() {

  'use strict';

  angular.module('mainApp')

    .controller('CartController', ['$http','$scope','$rootScope','appConfig','toolsService','ngCart','webStorage','sessionService','_',
      function ($http, $scope, $rootScope, appConfig, toolsService, ngCart, webStorage, sessionService, _) {
        $scope.ngCart = ngCart;

        // access to saRoute url
        $scope.url = $rootScope.url;
    }])

    .controller('CartPopupController', ['$rootScope','$filter','$timeout','$scope',
      function ($rootScope, $filter, $timeout, $scope) {
        $scope.showPopUp = false;

        $rootScope.$on('ngCart:itemAdded', function (e, item) {
          $scope.showPopUp = true;

          $scope.view_cart_url = $rootScope.url('store-cart');

          $scope.txt = $filter('translateVariables')($rootScope.translations.shop.common.add_to_cart_text, item._data.name);

          $timeout(function() {
            $scope.showPopUp = false;
          }, 2500);
        });
    }])

    .controller('CartPageController', ['$http','$routeParams','$scope','$rootScope','appConfig','toolsService','ngCart','webStorage','sessionService','_','storeFactory','serviceStoreAnalytics',
      function ($http, $routeParams, $scope, $rootScope, appConfig, toolsService, ngCart, webStorage, sessionService, _, storeFactory, serviceStoreAnalytics) {

        serviceStoreAnalytics.checkoutProcess(1);

        if($routeParams['products']) {
          var list_products = $routeParams['products'].split(',');

          angular.forEach(list_products, function (item) {
            storeFactory.getProduct(item).then(function (product) {
              if(!checkCartProduct(product.niceName)) {
                var landing = ($routeParams['landing']) ? true : false;
                var productPrice = ($routeParams['landing']) ? product.landingPrice : product.price;
                ngCart.addItem(product.niceName, product.name, productPrice, 1, product, landing);
              }
            });
          });
        }

        if($routeParams['coupon']) {
          var list_coupons = $routeParams['coupon'].split(',');

          angular.forEach(list_coupons, function (item) {
            ngCart.setCoupon(item);
          });
        }

        // Mautic + coupones
        // Mycook: 22225
        // Roner: 27227
        // Jarra completa: 4734

        function checkCartProduct(niceName) {
          var inCart = false;
          angular.forEach(ngCart.getItems(), function (item) {
            if(item._niceName == niceName) inCart = true;
          });

          return inCart;
        }

        // Descuento Paco
        if($routeParams['descuento']) {
          storeFactory.getProduct('mycook-touch').then(function (product) {
            if(!checkCartProduct(product.niceName)) ngCart.addItem(product.niceName, product.name, product.price, 1, product);
            ngCart.setCoupon('descuento');
          });
        }

        // Descuento Renove miembros club
        if($routeParams['descuento-exclusivo']) {
          storeFactory.getProduct('mycook-touch').then(function (product) {
            if(!checkCartProduct(product.niceName)) ngCart.addItem(product.niceName, product.name, product.price, 1, product);
            ngCart.setCoupon('exclusivomiembrosclub');
          });
        }

        // Descuento Ebook
        if($routeParams['descuento-ebook']) {
          storeFactory.getProduct('mycook-touch').then(function (product) {
            if(!checkCartProduct(product.niceName)) ngCart.addItem(product.niceName, product.name, product.price, 1, product);
            ngCart.setCoupon('descuentoebook');
          });

          /*
          if(isset($_GET['utm_source'])) $utms .= '?utm_source='.$_GET['utm_source'];
          if(isset($_GET['utm_medium'])) $utms .= '&utm_medium='.$_GET['utm_medium'];
          if(isset($_GET['utm_campaign'])) $utms .= '&utm_campaign='.$_GET['utm_campaign'];
          if(isset($_GET['utm_content'])) $utms .= '&utm_content='.$_GET['utm_content'];

          header('Location: '. get_bloginfo('url') .'/tienda/mi-cesta/' . $utms);
          */
        }

        // Descuento Roner
        if($routeParams['descuento-roner']) {
          storeFactory.getProduct('roner-clip').then(function (product) {
            if(!checkCartProduct(product.niceName)) ngCart.addItem(product.niceName, product.name, product.price, 1, product);
            ngCart.setCoupon('descuentoroner');
          });

          /*
          if(isset($_GET['utm_source'])) $utms .= '?utm_source='.$_GET['utm_source'];
          if(isset($_GET['utm_medium'])) $utms .= '&utm_medium='.$_GET['utm_medium'];
          if(isset($_GET['utm_campaign'])) $utms .= '&utm_campaign='.$_GET['utm_campaign'];
          if(isset($_GET['utm_content'])) $utms .= '&utm_content='.$_GET['utm_content'];

          header('Location: '. get_bloginfo('url') .'/tienda/mi-cesta/' . $utms);
          */
        }

        // miramos si tenemos activo el cupon de la jarra, pero no tenemos los 2 productos
        /*if(ngCart.getCoupon() == 'jarra regalo') {
          var has_jarra = false,
              has_mycook = false;

          angular.forEach(ngCart.getItems(), function (item) {
            if(item._niceName == 'mycooktouch') has_mycook = true;
            if(item._niceName == 'jarra-conjunto-completo') has_jarra = true;
          });

          if(!has_jarra || !has_mycook) {
            ngCart.setCoupon(null);
          }
        }*/

        // cupon mycook + jarra
        if($routeParams['pack-mycook-touch']) {
          var has_jarra = false,
              has_mycook = false;

          angular.forEach(ngCart.getItems(), function (item) {
            if(item._niceName == 'mycooktouch') has_mycook = true;
            if(item._niceName == 'jarra-conjunto-completo') has_jarra = true;
          });

          /*if(!has_jarra || !has_mycook) {
            storeFactory.getProduct('jarra-conjunto-completo').then(function (product) {
              if(!checkCartProduct(product.niceName)) ngCart.addItem(product.niceName, product.name, product.price, 1, product);

              if(!has_mycook) {
                storeFactory.getProduct('mycook-touch').then(function (product) {
                  if(!checkCartProduct(product.niceName)) ngCart.addItem(product.niceName, product.name, product.price, 1, product);
                  ngCart.setCoupon('jarraregalo');
                });
              } else {
                ngCart.setCoupon('jarraregalo');
              }
            });
          } else {
            ngCart.setCoupon('jarraregalo');
          }*/
        } /*else {
          var has_jarra = false,
              has_mycook = false;

          angular.forEach(ngCart.getItems(), function (item) {
            if(item._niceName == 'mycooktouch') has_mycook = true;
            if(item._niceName == 'jarra-conjunto-completo') has_jarra = true;
          });

          if(has_jarra && has_mycook) {
            ngCart.setCoupon('jarraregalo');
          }
        }*/

        // access to saRoute url
        $scope.url = $rootScope.url;

        $scope.ngCart = ngCart;

        $scope.voucher = null;
        $scope.voucherMessage = '';
        $scope.voucherIsApplied = false;

        $scope.getNumber = function(num) {
          num = isNaN(parseInt(num))?[]:parseInt(num);
          return new Array(num);
        }

        //Refresh content from localStorage
        //ngCart.$refresh();

        /*$scope.handleResult = function(err,res) {
          //TODO improve error handling
          if(err) {
            console.log('err',err,res);
          }
        };*/

        $scope.$back = function() {
          window.history.back();
        };

        /////ngCart.setShipping(appConfig.shop.shippingCost);

        $scope.cartIsEmpty = function () {
          return ngCart.isEmpty();
        };

        //calculatePrice/:discount/:userNiceName
        $scope.addVoucher = function(code) {
          ngCart.setCoupon(code);
        }

        $scope.removeVoucher = function () {
          ngCart.setCoupon(null);
          $scope.voucher = null;
          $scope.voucherMessage = '';
          $scope.voucherIsApplied = false;
        };

        if(ngCart.getCoupon()) {
          $scope.voucher = ngCart.getCoupon();
          updateCartWithCoupon();
        }

        $rootScope.$on('ngCart:change', function () {
          // revisar codigo descuento y llamar a funcion
          updateCartWithCoupon();
        });

        function updateCartWithCoupon() {
          var cart = _.clone(ngCart.getCart());

          if(ngCart.getCoupon()) {
            cart.voucher = { code: ngCart.getCoupon() };

            var leanOrder = cleanCart(cart);

            var userNiceName = webStorage.get('userNiceName') || 'guest';
            console.log('CartPageController > updateCartWithCoupon > userNiceName', userNiceName, 'leanOrder', leanOrder);

            $http({
              method: 'post',
              headers: { 'Authorization': 'Bearer ' + sessionService.getUserToken()},
              url: appConfig.localApiBasePath + '/calculatePrice/' + ngCart.getCoupon() + '/' + userNiceName,
              data: JSON.stringify(leanOrder)
            }).then(function successCallback(response) {
              console.log("CartPageController > successCallback", response, 'totalDiscount:', response.data.totalDiscount, 'total:', response.data.total);

              ngCart.setDiscount(response.data.totalDiscount);
              ngCart.setTotalDiscount(response.data.total);

              if(response.status = 200) {
                console.log('CartPageController > order status 200', response);

                $scope.voucherIsApplied = true;
                $scope.voucherMessage = '<p>' + $rootScope.translations.shop.cart.promotional_code.messages.valid_discount + '</p>';
              }
            }, function errorCallback(response) {
              console.log("CartPageController > errorCallback", response);

              if(ngCart.getCoupon()) {
                $scope.voucherIsApplied = false;

                errorHandling(response.data);
              }
            });

          } else {
            //////////////////////
            ngCart.setDiscount(null);
            ngCart.setTotalDiscount(null);
            ///////////////////
          }
        }

        function cleanCart(fullCart) {
          var leanCart = _.pick(fullCart, ['shipping', 'shippingAddress', 'taxRate', 'voucher', 'comments', 'sendAsGift', 'customerValue', 'orderId']);
          var leanItems = [];

          fullCart.items.forEach(function(item) {
            var leanItem = _.pick(item, ['_niceName', '_name', '_price', '_quantity', '_data', '_landing']);
            delete leanItem.$$hashKey;
            delete leanItem._data.relatedProducts;
            delete leanItem._data.$$hashKey;
            leanItems.push(leanItem);
          });

          leanCart.items = leanItems;
          return leanCart;
        }

        function errorHandling(data) {
          // coupon errors
          // 20 - Discount code applied correctly
          // 21 - date range
          // 22 - Discount exceded valid uses
          // 23 - Discount not match user
          // 24 - Discount not match any product of your cart
          // 25 - Invalid discount code
          // 26 - You can't mix coupon codes
          // 27 - no estan todos los productos de un pack
          // 28 - producto con precio de landing no se puede aplicar doble descuento

          $scope.voucherMessage = '';

          console.log('CartPageController > errorHandling', data);

          var removeVoucher = false;

          if(data.length > 0) {
            data.forEach(function(item) {
              // coupon errors
              if(item.errorCode >= 21 && item.errorCode <= 28) {
                removeVoucher = true;
                $scope.voucherMessage += '<p>' + $rootScope.translations.shop.cart.promotional_code.messages[item.errorCode] + '</p>';
              }
            });
          }

          if(removeVoucher) ngCart.setCoupon(null);
        }
    }]);
})();