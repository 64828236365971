(function () {
  angular.module('mainApp')

    .controller('AffiliatesIncomeController', ['$scope', '$rootScope', 'serviceApiAffiliates', 'webStorage',
      function ($scope, $rootScope, $filter, serviceApiAffiliates, webStorage) {
        var userNiceName = webStorage.get('userNiceNameAffiliates');
        serviceApiAffiliates.getIncomes(userNiceName).then(function (data) {
          $scope.totalOrders = 0;
          $scope.totalIncomes = 0;

          data.forEach(function (element) {
            $scope.totalOrders += element.total;
            $scope.totalIncomes += element.income;
          }, this);
          $scope.incomes = data;
        });

        $scope.filterDate = function (date) {
          return $filter('date')(date, "dd-MM-yyyy");
        };
      }
    ]);
})();