(function () {
  angular.module('mainApp')

    .controller('AffiliatesResourcesController', ['$scope', '$rootScope', 'serviceApiAffiliates', 'webStorage',
    	function ($scope, $rootScope, serviceApiAffiliates, webStorage) {
        var userNiceName = webStorage.get('userNiceNameAffiliates');

        $scope.updateSizeFilter = function (size) {
          if(size == 'Todas') size = undefined;
          $scope.currentSize = size;
        }

        serviceApiAffiliates.getBanners(userNiceName, 'all').then(function (data) {
          var listSizes = new Array();

          angular.forEach(data, function (value, key) {
            if(value.type == 'banner') {
              value.size = value.width + 'x' + value.height;

              if (listSizes.indexOf(value.width + 'x' + value.height) == -1) {
                listSizes.push(value.width + 'x' + value.height);
              }
            }
          });
          listSizes.unshift('Todas');

          $scope.listSizes = listSizes;
          $scope.currentSize;
          $scope.banners = data;
        });

      	serviceApiAffiliates.getResources('all').then(function (data) {
          angular.forEach(data.videos, function (vid, key) {
            vid.iframe_url = '//www.youtube.com/embed/' + vid.url + '?rel=0&showinfo=0&autohide=1';
            vid.iframe_copy = '<iframe width="560" height="315" src="https://www.youtube.com/embed/' + vid.url + '" frameborder="0" allowfullscreen></iframe>';
          });

          $scope.currentResource = 'link_id';
    			$scope.resources = data;
    		});
    }]);
})();
