
(function(){

  'use strict';
  
  angular.module('mainApp')
  
    // landings Andrea Vicens, Regalos, espaisucre, cluster all, ebooks all
    .controller("LandingsController", ['$scope', '$route', '$log', 'serviceLandings', 'seoService',
      function ($scope, $route, $log, serviceLandings, seoService) {
        $log.debug("LandingsController", $route.current.name, $route.current.originalPath);
        
        var seoInfo = seoService.searchSeoAtTranslationsLandings();
  
        if (seoInfo) {
          seoService.setTitle(seoInfo.title);
          seoService.setDescription(seoInfo.description);
        }
  
        switch($route.current.name) {
          case 'all-cluster-landings': // Todos las landings
            var projection = {
              img: 1,
              niceName: 1,
              title: 1,
              shortDescription: 1,
              _id: 0
            };

            serviceLandings.getLandingsByType('landing', {}, projection).then(function(clusterLandings) {
              $log.debug('LandingsController > serviceLandings -> all-cluster-landings', clusterLandings);
              $scope.landings = clusterLandings.results;
            });
          break;
  
          case 'all-ebooks': // Todos los ebooks
            var projection = {
              img: 1,
              niceName: 1,
              title: 1,
              shortDescription: 1,
              _id: 0
            };
  
            serviceLandings.getLandingsByType('ebook', {}, projection).then(function(ebooks) {
              $log.debug('LandingsController > serviceLandings -> all-ebooks', ebooks);
              $scope.ebooks = ebooks.results;
            });
          break;
  
          case 'landing-regalos-club':   // regalos club
            serviceLandings.getRegalos().then(function(response) {
              $log.debug('LandingsController > serviceLandings -> getRegalos', response);
              $scope.regalos_club = response;
            });
          break;
  
          default:
            $log.debug('LandingsController DEFAULT', $route.current.name);
          break;
        }
    }]);
  })();