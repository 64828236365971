(function () {

  'use strict';

  angular.module('mainApp')
    .service("newsletterService", function ($log, $resource, $q, appConfig) {
      var self = this;

      this.deep_value = function (obj, path) {
        for (var i = 0, path = path.split('.'), len = path.length; i < len; i++) {
          obj = obj[path[i]];
        }
        return obj;
      };

      this.addSubscription = function (newsletterValues) {
        var defer = $q.defer();
        var result_data = {};

        if (!appConfig.webSettings.newsletter || Object.keys(newsletterValues).length === 0) {

          $log.debug("Website without newsletter subscription");
          defer.resolve(result_data);

        } else {

          $log.debug('newsletterService > addSubscription', newsletterValues);

          var actions,
            MailChimpSubscription,
            params = {},
            url;

          // Create a resource for interacting with the MailChimp API
          url = '//' + newsletterValues.username + '.' + newsletterValues.dc + '.list-manage.com/subscribe/post-json';

          var fields = Object.keys(newsletterValues);

          for (var i = 0; i < fields.length; i++) {
            params[fields[i]] = newsletterValues[fields[i]];
          }

          params.c = 'JSON_CALLBACK';

          $log.debug('newsletterService > addSubscription - params', params);

          actions = {
            'save': {
              method: 'jsonp'
            }
          };

          MailChimpSubscription = $resource(url, params, actions);

          // Send subscriber data to MailChimp
          MailChimpSubscription.save(
            // Successfully sent data to MailChimp.
            function (response) {

              $log.debug('newsletterService - addSubscription - response', response);

              // Define message containers.
              result_data.errorMessage = '';
              result_data.successMessage = '';

              // Store the result from MailChimp
              result_data.result = response.result;

              // Mailchimp returned an error.
              if (response.result === 'error') {
                if (response.msg) {
                  // Remove error numbers, if any.
                  var errorMessageParts = response.msg.split(' - ');
                  if (errorMessageParts.length > 1) errorMessageParts.shift(); // Remove the error number
                  result_data.errorMessage = errorMessageParts.join(' ');
                } else {
                  result_data.errorMessage = 'Sorry! An unknown error occured.';
                }
              }
              // MailChimp returns a success.
              else if (response.result === 'success') {
                result_data.successMessage = response.msg;
              }

              defer.resolve(result_data);

              //Broadcast the result for global msgs
              //$rootScope.$broadcast('mailchimp-response', response.result, response.msg);
            },

            // Error sending data to MailChimp
            function (error) {
              $log.error('MailChimp Error: %o', error);
            }
          );
        }

        return defer.promise;
      };
    })

    .controller("NewsletterController", ['$rootScope', '$scope', '$log', 'appConfig', 'newsletterService', 'legalTerms',
      function ($rootScope, $scope, $log, appConfig, newsletterService, legalTerms) {
        $scope.mailchimp = appConfig.newsletter.mailchimp;
        $scope.signature_contract = 'deactivated';
        $scope.userLegalData = {};
        $scope.anonymousUserData = true;
        var view_values = [];

        Object.keys(appConfig.newsletter.items).forEach(function (key, i) {
          if ($rootScope.translations.footer.newsletter.newsletter_fields && $rootScope.translations.footer.newsletter.newsletter_fields[i]) {
            view_values.push({
              "title": $rootScope.translations.footer.newsletter.newsletter_fields[i].field,
              "model": key
            });
          } else {
            $log.error('NewsletterController > fields not found in translations');
          }
        });

        $scope.form_fields = view_values;

        $scope.cancelContract = function (userLegalData) {
          $scope.signature_contract = 'deactivated';
          $scope.contract_not_accepted = true;
        };

        $scope.acceptContract = function (validForm, userLegalData) {
          $scope.signature_contract = 'loading';

          $scope.contract_not_accepted = false;
          var sendToApi = new Array();
          for(var k in userLegalData) {
            if(userLegalData[k]) {
              sendToApi.push(k);
            }
          }
  
          legalTerms.acceptLegalTerms('multi', sendToApi, $scope.mailchimp.EMAIL).then(function(result) {
            newsletterService.addSubscription($scope.mailchimp).then(function (result) {
              $scope.newsletterResult = result;
            });
  
            $scope.signature_contract = 'deactivated';
          });
        };

        $scope.submitForm = function (userData) {

          $scope.signature_contract = 'activated';

          legalTerms.getLegalTerms(['newsletterConditions']).then(function(legal) {
            legal.newsletterConditions.forceValidationNewsletter = true;
            
            $scope.legal = legal;
            $scope.signature_contract = 'activated';
          });
        };
      }
    ]);
})();