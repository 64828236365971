(function () {
  angular.module('mainApp')

    .controller('ChangePasswordController', [function ($scope, $rootScope, $route, forgotPasswordFactory, $timeout, $location) {

      $scope.submitForm = function (oldPassword, newPassword) {

        forgotPasswordFactory.changePasswordAuthenticated($scope.currentUser.niceName, newPassword, oldPassword).then(function (response) {

          if (response) {
            $scope.successChangePassword = true;

            $timeout(function () {
              $location.path($rootScope.url('home'));
            }, 1000);

          } else {
            $scope.successChangePassword = false;
          }
        });
      }
    }]);
})();