/**
 *  - recipesFactory
 *  - RecipeController
 *  - AsideController
 */

(function () {

  'use strict';

  angular.module('mainApp')

    .controller("RecipeController", ['$scope', '$rootScope', '$routeParams', '$route', '$location', '$window', '$filter', '$log', 'saRouter', '_', 'toolsService', 'appConfig', 'recipesFactory', 'usersManager', 'ngDialog', 'mycookTouchWebSocketFactory', 'serviceRanks', 'unitsService', 'serviceLandings',
      function ($scope, $rootScope, $routeParams, $route, $location, $window, $filter, $log, saRouter, _, toolsService, appConfig, recipesFactory, usersManager, ngDialog, mycookTouchWebSocketFactory, serviceRanks, unitsService, serviceLandings) {

        $scope.region = appConfig.region;

        function init() {
          $scope.responseMsg = "";
          $scope.currentDate = new Date();
          $scope.showDeleteDiag = false;

          $scope.commentIsValid = function (c) {
            var emtpyComment = {
              'date': '',
              'comments': [
                { 'date': '' }
              ]
            };

            var _c = angular.copy(c);
            return c && c != null && !angular.equals(c, emtpyComment);
          };

          var idrec = $routeParams.recipeNiceName;

          // comments
          $scope.replies = {};
          $scope.replies.boxes = [];
          $scope.oldStyle = false;

          recipesFactory.getRecipe($filter('urlEncode')(idrec)).then(function (recipe) {
            $log.debug("RecipeController > getRecipe > SUCCESS: ", recipe);

            // Reverse order comments from new to old
            recipe.comments = recipe.comments.reverse();
            recipe.ratings = recipe.ratings.reverse();

            // Get rating stats
            var rating_stats = _.countBy(recipe.ratings, function(str) {
              return str.rate;
            });

            recipe.rating_stats = rating_stats;
            if(toolsService.isEmpty(recipe.rate)) recipe.rate = 0;

            // default ratings not empty
            if(toolsService.isEmpty(recipe.ratings)) recipe.ratings = [];

            // Get rating by logged user
            if(recipe.status.rated) {
              $scope.$watch('currentUser.niceName', function(currentUserNiceName) {
                if(currentUserNiceName) {
                  var foundElementPosition = _.findIndex(recipe.ratings, function(o) {
                    return o.niceName == $scope.currentUser.niceName;
                  });

                  var foundElement = _.find(recipe.ratings, function(o) {
                    return o.niceName == $scope.currentUser.niceName;
                  });

                  if(foundElement) {
                    recipe.rating_by_user = foundElement;
                  }

                  if(foundElementPosition != -1) {
                    recipe.rating_by_user_position = foundElementPosition;
                  }
                }
              });
            }

            if (recipe.seo && (recipe.seo.suggestions) && (recipe.seo.suggestions.length > 0)) {
              $scope.suggestedRecipes = recipe.seo.suggestions;
            } else {
              $scope.suggestedRecipes = null;
            }

            //set robots meta
            var follow = 'follow',
                index = 'index';

            if(recipe.seo.follow === false) follow = 'nofollow';
            if(recipe.seo.index === false) index = 'noindex';

            var robots = [follow, index];
            robots = robots.join();
            $rootScope.seo_robots = robots;
            //end set robots meta

            var seo_title = (!toolsService.isEmpty(recipe.seo) && !toolsService.isEmpty(recipe.seo.title)) ? recipe.seo.title : recipe.title;
            seo_title += " " + $rootScope.translations.seo.separator + " " + $rootScope.translations.seo.website_name;
            $rootScope.pageTitle = seo_title;
            $rootScope.pageImage = appConfig.mycookTouchDomain + '/image/recipe/540x391/' + idrec; // fb og image -
            var pgDesc = "";

            if (!toolsService.isEmpty(recipe.seo) && !toolsService.isEmpty(recipe.seo.description)) {
              pgDesc = recipe.seo.description;
            } else {
              pgDesc = $filter('translateVariables')($rootScope.translations.seo.recipe.description, recipe.title) + " " + recipe.ingredientsSummary.join(", ");
            }

            $rootScope.pageDescription = (!toolsService.isEmpty(recipe.seo) && !toolsService.isEmpty(recipe.seo.description)) ? recipe.seo.description : pgDesc;

            if (!toolsService.isEmpty(recipe.seo) && !toolsService.isEmpty(recipe.seo.canonical)) {
              $rootScope.canonical = recipe.seo.canonical;
            } else if (recipe.status && recipe.status.idParent) {
              var browserUrl = $location.path();
              browserUrl = browserUrl.split("/");
              var parentUrl = $location.protocol() + "://" + $location.host() + "/" + browserUrl[1] + "/" + recipe.status.idParent;
              $rootScope.canonical = parentUrl;
            }

            // Set Meta cooktime
            recipe.metaCookTime = 'PT';
            var hours = parseInt(recipe.totalTime / 3600, 10) % 24;
            var minutes = parseInt(recipe.totalTime / 60, 10) % 60;
            if (hours) {
              recipe.metaCookTime += hours + 'H';
            }
            if (minutes) {
              recipe.metaCookTime += minutes + 'M';
            }
            if (recipe.totalTime < 60) {
              recipe.metaCookTime += recipe.totalTime + 'S';
            }

            // Fake step number
            var fakeCounter = 1;
            for (var i = 0; i < recipe.groups.length; i++) {
              for (var j = 0; j < recipe.groups[i].steps.length; j++) {
                recipe.groups[i].steps[j].fakeStep = fakeCounter;
                fakeCounter++;
              }
            }

            if (recipe.videos.length > 0 && recipe.videos[0] !== '') {
              recipe.videoUrl = '//www.youtube.com/embed/' + recipe.videos[0] + '?rel=0&showinfo=0&autohide=1&enablejsapi=1&modestbranding=1';
            }

            // change rank
            serviceRanks.getRanks().then(function (ranks) {
              if (recipe.user.rank) {
                var found = $filter('filter')(ranks, { niceName: recipe.user.rank }, true);
                if (found.length > 0) {
                  recipe.user.rank = found[0].name;
                }
              } else {
                recipe.user.rank = '';
              }
            });

            // get nutritional
            if (recipe.nutritional && recipe.status && (recipe.status.nutritional == 'Auto (Good)' || recipe.status.nutritional == 'Manual')) {
              var array = [['Energy'], ['Lipid Tot',['FA Sat']], ['Carbohydrt', ['Sugar Tot']], ['Protein'], ['Fiber TD'],/*['Colesterol'],*/['Sodium']/*,['Water']*/];
              recipe.nutritionalTable = unitsService.getNutritionalTable(recipe.nutritional, array);
            }

            // Custom content - only Spain
            if(appConfig.region == 'ES-MYCOOKTOUCH') {
              // ebooks
              var recipeEbooks = [];

              recipe.accesoriesList.forEach(function(acc) {
                recipeEbooks.push('accessories/' + acc);
              });

              recipe.tags.forEach(function(tag) {
                recipeEbooks.push(tag);
              });

              recipeEbooks.push('category/' + recipe.categoryNiceName);

              // TODO: random ebooks
              //serviceLandings.getRandomEbook();
              /*var contentEbook = serviceLandings.getEbookDescriptions();

              var tagFound = _.find(recipeEbooks, function(element) {
                return contentEbook[element];
              });

              if(tagFound) {
                $scope.other_content = contentEbook[tagFound];
              }*/

              var diets = {
                'Dietas/Sin lactosa': 'LowLactoseDiet',
                'Dietas/Sin gluten': 'GlutenFreeDiet',
                'Dietas/Baja en calorías': 'LowCalorieDiet',
                'Dietas/Vegetariana': 'VegetarianDiet'
              };

              var tagDietsFound = _.filter(recipe.tags, function(element) {
                return diets[element];
              });

              recipe.suitableForDiet = [];

              tagDietsFound.forEach(function(el) {
                recipe.suitableForDiet.push(diets[el]);
              });

              // Gastronomía
              recipe.recipeCuisine = [];
              recipe.tags.forEach(function(el) {
                if(_.startsWith(el, 'Gastronomía')) {
                  var type = _.last(_.split(el, '/'));
                  if(recipe.recipeCuisine.indexOf(type) == -1) {
                    recipe.recipeCuisine.push(type);
                  }
                }
              });
            }

            // set current PAX
            $scope.selectedPax = recipe.size[recipe.size.current];
            $scope.lengthPax = Object.keys(recipe.size).length - 1;

            $scope.recipeContent = recipe;

            var route = saRouter.getRoute('recipe');
            var recipeUrl = saRouter.replaceUrlParams(route.url, {recipeNiceName: recipe.niceName});
            recipeUrl = $location.protocol() + '://' + $location.host() + recipeUrl;
            $scope.currentUrl = recipeUrl;

            $rootScope.$broadcast('UPDATED_RECIPE_DATA', recipe);
            
            var twMsg = $filter('translateVariables')($rootScope.translations.single_recipe.share_by_twitter_text, $scope.recipeContent.title);
            $scope.pinterestMsg = encodeURIComponent(twMsg);

            twMsg = '?url=' + recipeUrl + '&text=' + encodeURIComponent(twMsg);
            if ($rootScope.translations.config.social_media.twitter != '') twMsg += '&via=' + $rootScope.translations.config.social_media.twitter;

            $scope.twMsg = twMsg;
          }, function (error) {
            $log.error("RecipeController > getRecipe >  ERROR2: ", error);
            $window.location.href = "404";
          });
        }

        /*$scope.getPercentage = function (type, obj, semaforo) {
          if (semaforo) semaforo = 100;
          var percentage = unitsService.getPercentage(type, obj, semaforo);
          return percentage;
        }

        $scope.getSemaforo = function (type, qty) {
          return unitsService.semaforo(unitsService.getPercentage(type, qty), false);
        }*/

        $scope.openDialog = function (element, nutritionalTable, templateID, className) {
          $scope.recipe_item = element;
          $scope.nutritionalTable = nutritionalTable;

          ngDialog.open({
            template: templateID,
            className: className,
            scope: $scope
          });
        };

        // Get Price - Difficulty recipe text
        $scope.currentPriceDifficultyTitle = function (current, type) {
          switch (current) {
            case 1:
              current = 'low';
              break;
            case 2:
              current = 'medium';
              break;
            case 3:
              current = 'high';
              break;
          }
          return $rootScope.translations.common[type + '_' + current];
        };

        $scope.filterDate = function (date) {
          return $filter('date')(date, "dd-MM-yyyy");
        };

        $scope.showRecipeWarning = function () {
          return ($scope.recipeContent.designedTo == 'mycooktouch' && $scope.oldStyle === true);
        };

        // DELETE RECIPE
        $scope.showDeleteDialog = function (niceName, title) {

          if (!toolsService.isEmpty(niceName)) {
            $scope.recipeToDelete = {
              niceName: niceName,
              title: title
            };

            ngDialog.openConfirm({
              template: 'remove-recipe.html',
              className: 'new-recipe',
              scope: $scope
            }).then(function (r) {
              recipesFactory.deleteRecipe($scope.recipeToDelete.niceName).then(function (res) {
                $location.path($rootScope.url('recipeCategories'));
              }, function (err) {
                $log.error("CategoryController > deleteMyRecipe ERROR: ", err);
              });
            }, function (e) {
            });
          }
        };
        
        $scope.showPublishDialog = function (recipe, title) {
          if (!toolsService.isEmpty(recipe.niceName)) {
            $scope.recipeToPublish = {
              niceName: recipe.niceName,
              title: title
            };

            ngDialog.openConfirm({
              template: 'send-recipe.html',
              className: 'publish-recipe',
              scope: $scope
            }).then(function (r) {
              //quitar los tags de draft
              recipe.grants.view = ["public"];
              recipe.grants.search = ["public"];

              recipe.info = {
                creationDate: new Date(),
                creationSource: $scope.deviceDetector.raw.userAgent
              };

              recipesFactory.edit(recipe).then(function (res) {
                $rootScope.$broadcast('updateDraftRecipes', {});
                $route.reload();
              }, function (err) {
                $log.error("CategoryController > publishMyRecipe ERROR: ", err);
              });
            }, function (e) {
            });
          }
        };

        // FAVORITES
        $scope.markAsFavorite = function (userId, recipeId, event) {
          event.preventDefault(); // sino me vuelve a la pag. principal
          recipesFactory.markAsFavorite(userId, recipeId)
            .success(function (favoriteRes) {
              $scope.favoriteResult = "added";
              if (!$scope.recipeContent.social) {
                $scope.recipeContent.social = {};
              }

              if (!$scope.recipeContent.social.favorite) {
                $scope.recipeContent.social.favorite = 0;
              }

              $scope.recipeContent.social.favorite += 1;
              $scope.currentUser.favorites.push(recipeId);
            })
            .error(function (error) {
              $log.error("ERROR markAsFavorite: ", error);
            });
        };

        $scope.removeFromFavorite = function (userId, recipeId, event) {
          event.preventDefault();
          recipesFactory.removeFromFavorite(userId, recipeId)
            .success(function (favoriteRes) {
              $scope.favoriteResult = "removed";
              $scope.recipeContent.social.favorite -= 1;
              $scope.currentUser.favorites.pop(recipeId);
            })
            .error(function (error) {
              $log.error("ERROR removeFromFavorite: ", error);
            });
        };

        $scope.sendToMycookTouch = function () {
          $log.debug('sendToMycookTouch', $scope.recipeContent);
          mycookTouchWebSocketFactory.sendRecipe($scope.recipeContent.niceName, $scope.recipeContent.title, $scope.currentUser.niceName);
        }

        $scope.changePaxRecipe = function () {
          var url = $rootScope.url('recipe', { recipeNiceName: $scope.selectedPax });
          $location.path(url);
        }

        init();

      }]) // end RecipeController

    .controller("AsideController", ['$scope', '$rootScope', '$location', '$log', 'recipesFactory',
      function ($scope, $rootScope, $location, $log, recipesFactory) {

        $scope.$on('UPDATED_RECIPE_DATA', function (event, recipeInfo) {
          var totalSuggestions = 0;
          if ($scope.recipeContent.seo && $scope.recipeContent.seo.suggestions != null) {
            $scope.randomRecipes = $scope.recipeContent.seo.suggestions;
            totalSuggestions = $scope.recipeContent.seo.suggestions.length;
          }
          if (totalSuggestions < 4) {
            getSuggestions(recipeInfo.categoryNiceName, recipeInfo.niceName, 4 - totalSuggestions);
          }
        });

        function getSuggestions(catToSearchIn, recipeToExlude, numResults) {

          recipesFactory.getRandomRecipes(catToSearchIn, recipeToExlude, numResults).success(function (responseBundle) {
            if ($scope.recipeContent.seo && $scope.recipeContent.seo.suggestions != null) {
              $scope.randomRecipes = $scope.randomRecipes.concat(responseBundle);
            } else $scope.randomRecipes = responseBundle;

          }).error(function (error) {
            $log.error("AsideController > getSuggestions > ERROR1: ", error);
          });
        }
      }]);

})();