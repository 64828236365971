/**
 *  - SearchController
 */

(function () {

  'use strict';

  angular.module('mainApp')

    .controller("SearchController", ['$scope', '$rootScope', '$location', '$filter', '_', 'appConfig', 'toolsService', 'recipeCategoriesFactory', 'recipesFactory',
      function ($scope, $rootScope, $location, $filter, _, appConfig, toolsService, recipeCategoriesFactory, recipesFactory) {

        $scope.sendSearch = function (criteria) {

          $location.path($rootScope.url('searchResult'))
            .search('terms', criteria.terms.toLowerCase())
            .search('sortBy', null)
            .search('verified', null)
            .search('hasVideo', null);
        };

        var searchCriteria = {};
        searchCriteria = _.clone($location.$$search, true);
        $scope.searchForm = searchCriteria;

      }
    ]);

})();