(function () {
    'use strict';

    angular.module('mainApp')

        .service("unitsService", function ($log) {

            var self = this;

            this.getNutritionalValue = function (value, nutritional_object) {
                var result = {};

                switch(value) {
                    case 'Energy':
                        result.value = nutritional_object['Energy']['value'];
                        result.unit = nutritional_object['Energy']['unit'];
                    break;

                    case 'Lipid Tot':
                        result.value = nutritional_object['Lipid Tot']['value'];
                        result.unit = nutritional_object['Lipid Tot']['unit'];
                    break;

                    case 'FA Sat':
                        result.value = nutritional_object['FA Sat']['value'];
                        result.unit = nutritional_object['FA Sat']['unit'];
                    break;

                    case 'FA Mono':
                        result.value = nutritional_object['FA Mono']['value'];
                        result.unit = nutritional_object['FA Mono']['unit'];
                    break;

                    case 'FA Poly':
                        result.value = nutritional_object['FA Poly']['value'];
                        result.unit = nutritional_object['FA Poly']['unit'];
                    break;

                    case 'Carbohydrt':
                        result.value = nutritional_object['Carbohydrt']['value'];
                        result.unit = nutritional_object['Carbohydrt']['unit'];
                    break;

                    case 'Sugar Tot':
                        result.value = nutritional_object['Sugar Tot']['value'];
                        result.unit = nutritional_object['Sugar Tot']['unit'];
                    break;

                    case 'Protein':
                        result.value = nutritional_object['Protein']['value'];
                        result.unit = nutritional_object['Protein']['unit'];
                    break;

                    case 'Fiber TD':
                        result.value = nutritional_object['Fiber TD']['value'];
                        result.unit = nutritional_object['Fiber TD']['unit'];
                    break;

                    case 'Cholestrl':
                        result.value = nutritional_object['Cholestrl']['value'];
                        result.unit = nutritional_object['Cholestrl']['unit'];
                    break;

                    case 'Sodium':
                        result.value = nutritional_object['Sodium']['value'];
                        result.unit = nutritional_object['Sodium']['unit'];
                    break;

                    case 'Water':
                        result.value = nutritional_object['Water']['value'];
                        result.unit = nutritional_object['Water']['unit'];
                    break;
                }

                return result;
            };

            this.getNutritionalTable = function (nutritional_object, values) {
                var result = [];

                values.forEach(function callback(element, index) {

                    var obj = self.getNutritionalValue(element[0], nutritional_object);
                    //var percentage = self.getPercentage(element[0], obj);

                    //if(percentage) {
                        //var semaforo = (element[0] == 'Fiber') ? self.semaforo(percentage, false, true) : self.semaforo(percentage);

                        var el = {
                            "name": element[0],
                            "quantity": obj,
                            //"percentage": percentage,
                            //"semaforo": semaforo,
                            "child": []
                        }

                        if(element[1]) {
                            element[1].forEach(function callback(el_child) {
                                var obj = self.getNutritionalValue(el_child, nutritional_object);
                                //var percentage = self.getPercentage(el_child, obj);

                                //if(percentage) {
                                    //var semaforo = self.semaforo(percentage);

                                    el.child.push({
                                        "name": el_child,
                                        "quantity": obj,
                                        //"percentage": percentage,
                                        //"semaforo": semaforo
                                    });
                                //}
                            });
                        }

                        result.push(el);
                    //}
                });
                $log.debug('getNutritionalTable - result', result);

                return result;
            };

            this.getPercentage = function (type, obj, total) {
                // total == 100 o 35
                var qty = obj.value;
                // var max_value = 0;
                var refObj = {};
                refObj.value = 0;

                //console.log('nutritional -> getPercentage', type, obj, total);

                switch(type) {
                    case 'Energy': //kcal
                        refObj.value = 2000;
                        break;

                    case 'Lipid Tot': //gr
                        refObj.value = 70;
                        refObj.unit = 'g';
                        break;

                    case 'FA Sat': //gr
                        refObj.value = 20;
                        refObj.unit = 'g';
                        break;

                    case 'Carbohydrt': //gr
                        refObj.value = 270;
                        refObj.unit = 'g';
                        break;

                    case 'Sugar Tot': //gr
                        refObj.value = 90;
                        refObj.unit = 'g';
                        break;

                    case 'Protein': //gr
                        refObj.value = 50;
                        refObj.unit = 'g';
                        break;

                    case 'Fiber TD': //gr
                        refObj.value = 25;
                        refObj.unit = 'g';
                        break;

                    case 'Sodium': //gr
                        refObj.value = 2.4;
                        refObj.unit = 'g';
                        break;
                }

                if(refObj.value == 0) {
                    $log.error('nutritional - getPercentage: no MAX value', type);

                    return undefined;

                } else {
                    convertUnits(obj,refObj);
                    // console.log("OBJ", obj);
                    // console.log("RefOBJ", refObj);
                    var percentage = (obj.value * 100) / refObj.value;

                    if(total && total == 100) {
                        // 75 == 100
                        // percentage == X
                        percentage = (percentage * 100) / 75;

                        //if(type == 'Energy') console.log('/////////', percentage);
                        if(percentage > 100) percentage = 100;
                    }

                    return percentage;
                }
            };

            this.semaforo = function (percentage, hex, oposite) {
                // < 10% bajo
                // 10% - 35% medio
                // > 35% alto

                if(oposite) {
                    if(percentage > 35) {
                        return (hex) ? '#6cb437' : 'green';
                    } else if(percentage < 10) {
                        return (hex) ? '#bc4445' : 'red';
                    } else {
                        return (hex) ? '#e1b90a' : 'yellow';
                    }
                } else {
                    if(percentage > 35) {
                        return (hex) ? '#bc4445' : 'red';
                    } else if(percentage < 10) {
                        return (hex) ? '#6cb437' : 'green';
                    } else {
                        return (hex) ? '#e1b90a' : 'yellow';
                    }
                }
            };

            function getUnitExponent(unit) {
                switch(unit) {
                    case "g":
                        return 0;
                    case "mg":
                        return -3;
                    case "ug":
                        return -6;
                }
            }

            function convertUnits(a, b) {
                if(a.unit == b.unit || a.unit=='Kcal') {
                    return;
                }
                var aExp = getUnitExponent(a.unit);
                var bExp = getUnitExponent(b.unit);
                var minExp = Math.min(aExp, bExp);

                if(minExp == bExp) {
                    a.value = a.value / Math.pow(10.0, minExp - aExp);
                    a.unit = b.unit;
                } else {
                    b.value = b.value / Math.pow(10.0, minExp - bExp);
                    b.unit = a.unit;
                }
            }

        });
})();