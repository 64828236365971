(function() {

    'use strict';

    angular.module('ngCart', ['ngCart.directives'])

        /*.config(function (AnalyticsProvider) {
            // Add configuration code as desired - see below
            AnalyticsProvider.setAccount('UA-63734041-1').logAllCalls(true).trackPages(true);
            AnalyticsProvider.useECommerce(true, true);
        })*/

        .provider('$ngCart', function () {
            this.$get = function () {};
        })

        .run(['$rootScope', 'ngCart', 'NgCartItem', 'store', function ($rootScope, ngCart, NgCartItem, store) {

            $rootScope.$on('ngCart:change', function () {
                ngCart.$save();
            });

            if (angular.isObject(store.get('cart'))) {
                ngCart.$restore(store.get('cart'));
            } else {
                ngCart.init();
            }

        }])

        .service('ngCart', ['$rootScope', 'NgCartItem', 'store',
            function ($rootScope, NgCartItem, store) {

            this.init = function () {
                this.$cart = {
                    coupon: null,
                    customerValue: {},
                    shipping: null,
                    shippingAddress: null,
                    taxRate: null,
                    tax: null,
                    items: []
                };
            };

            this.addItem = function (niceName, name, price, quantity, data, landing) {
                //TO prevent mismatch add product conditions from different browser folders.
                this.$refresh();
                var inCart = this.getItemByNiceName(niceName);

                if (typeof inCart === 'object') {
                    //Update quantity of an item if it's already in the cart
                    inCart.setQuantity(quantity, true);
                    //var newItem = new NgCartItem(niceName, name, price, quantity, data);
                    $rootScope.$broadcast('ngCart:itemAdded', inCart);
                } else {
                    var newItem = new NgCartItem(niceName, name, price, quantity, data, landing);
                    this.$cart.items.push(newItem);

                    $rootScope.$broadcast('ngCart:itemAdded', newItem);
                }

                $rootScope.$broadcast('ngCart:change', {});
            };

            //this.getItemById = function (itemId) {
            this.getItemByNiceName = function (itemNiceName) {
                if(!itemNiceName) {
                    return false;
                }

                var items = this.getCart().items;
                var build = false;

                angular.forEach(items, function (item) {
                    if (item.getNiceName() === itemNiceName) {
                        build = item;
                    }
                });
                return build;
            };

            this.setShipping = function (shipping) {
                this.$cart.shipping = shipping;
                return this.getShipping();
            };

             this.getShipping = function () {
                if (this.getCart().items.length === 0) {
                    return 0;
                }
                return this.getCart().shipping;
            };

            this.setShippingAddress = function (shippingAddress) {
                this.$cart.shippingAddress = shippingAddress;
                $rootScope.$broadcast('ngCart:change', {});
                return this.getShippingAddress();
            };

            this.getShippingAddress = function () {
                return this.$cart.shippingAddress;
            };

            this.setMycookSerialNumber = function (mycook_serial) {
                this.$cart.customerValue.mycook_serial = mycook_serial;
                return this.getMycookSerialNumber();
            };

            this.getMycookSerialNumber = function () {
                return this.getCart().customerValue;
            };

            this.setSendAsGift = function (gift) {
                this.$cart.sendAsGift = gift;
                return this.getSendAsGift();
            };

            this.getSendAsGift = function () {
                return this.getCart().sendAsGift;
            };

            this.setComments = function (comments) {
                this.$cart.comments = comments;
                return this.getComments();
            };

            this.getComments = function () {
                return this.getCart().comments;
            };

            this.setTaxRate = function (taxRate) {
                this.$cart.taxRate = +parseFloat(taxRate).toFixed(2);
                return this.getTaxRate();
            };

            this.getTaxRate = function () {
                return this.$cart.taxRate;
            };

            this.getTax = function () {
                return +parseFloat(((this.getSubTotal() / 100) * this.getCart().taxRate )).toFixed(2);
            };

            this.setCart = function (cart) {
                this.$cart = cart;
                return this.getCart();
            };

            this.getCart = function () {
                return this.$cart;
            };

            this.getItems = function () {
                return this.getCart().items;
            };

            this.setOrderId = function (orderId) {
                this.$cart.orderId = orderId;
                $rootScope.$broadcast('ngCart:change', {});
                return this.getOrderId();
            };

            this.getOrderId = function () {
                return this.getCart().orderId;
            };

            this.setCoupon = function (coupon) {
                this.$cart.coupon = coupon;
                $rootScope.$broadcast('ngCart:change', {});
                return this.getCoupon();
            };

            this.getCoupon = function () {
                return this.getCart().coupon;
            };

            this.setDiscount = function (number) {
                this.$cart.discount = number;
                return this.getDiscount();
            };

            this.getDiscount = function () {
                return this.getCart().discount;
            };

            this.setTotalDiscount = function (number) {
                this.$cart.totalDiscount = number;

                return this.getTotalDiscount();
            };

            this.getTotalDiscount = function () {
                return this.getCart().totalDiscount;
            };

            this.getTotalItems = function () {
                var count = 0;
                var items = this.getItems();
                angular.forEach(items, function (item) {
                    count += item.getQuantity();
                });
                return count;
            };

            this.getTotalUniqueItems = function () {
                return this.getCart().items.length;
            };

            this.getSubTotal = function () {
                var total = 0;
                var coupon = this.getCoupon();
                angular.forEach(this.getCart().items, function (item) {
                  if((item.getNiceName() == 'mycook-touch' || item.getNiceName() == 'mycook-easy') && coupon) {
                    total += item.getTotal(true);
                  } else {
                    total += item.getTotal();
                  }
                });
                return +parseFloat(total).toFixed(2);
            };

            this.totalCost = function () {
                var totalDiscount = this.getTotalDiscount();
                //console.log('getTotalItems > totalDiscount', totalDiscount)
            
                if(totalDiscount && totalDiscount > 0) {
                    return totalDiscount;
                } else {
                    return +parseFloat(this.getSubTotal()).toFixed(2);
                }
                //return +parseFloat(this.getSubTotal() + this.getShipping() + this.getTax()).toFixed(2);
            };

            this.removeItem = function (index) {
                var removedItem = this.$cart.items[index];
                this.$cart.items.splice(index, 1);
                $rootScope.$broadcast('ngCart:itemRemoved', removedItem);
                $rootScope.$broadcast('ngCart:change', {});
            };

            //this.removeItemById = function (id) {
            this.removeItemByNiceName = function (niceName) {
                var cart = this.getCart();
                var removedItem;

                angular.forEach(cart.items, function (item, index) {
                    if (item.getNiceName() === niceName) {
                        removedItem = cart.items[index];
                        cart.items.splice(index, 1);
                    }
                });

                this.setCart(cart);
                $rootScope.$broadcast('ngCart:itemRemoved', removedItem);
                $rootScope.$broadcast('ngCart:change', {});
            };

            this.empty = function () {
                $rootScope.$broadcast('ngCart:change', {});
                this.$cart.orderId = null;
                this.$cart.items = [];
                localStorage.removeItem('cart');
            };

            this.isEmpty = function () {
                return (this.$cart.items.length > 0 ? false : true);
            };

            this.toObject = function () {
                if (this.getItems().length === 0) {
                    return false;
                }

                var items = [];
                angular.forEach(this.getItems(), function (item) {
                    items.push(item.toObject());
                });

                return {
                    shipping: this.getShipping(),
                    shippingAddress:this.getShippingAddress(),
                    tax: this.getTax(),
                    taxRate: this.getTaxRate(),
                    subTotal: this.getSubTotal(),
                    totalCost: this.totalCost(),
                    items: items
                };
            };

            this.$restore = function (storedCart) {
                var _self = this;
                _self.init();
                _self.$cart.coupon = storedCart.coupon;
                _self.$cart.shipping = storedCart.shipping;
                _self.$cart.shippingAddress = storedCart.shippingAddress;
                _self.$cart.tax = storedCart.tax;

                angular.forEach(storedCart.items, function (item) {
                    _self.$cart.items.push(new NgCartItem(item._niceName, item._name, item._price, item._quantity, item._data, item._landing));
                });
                this.$save();
            };

            this.$save = function () {
                return store.set('cart', JSON.stringify(this.getCart()));
            };

            this.$refresh = function() {
                this.$restore(store.get('cart'));
            }
        }])

        .factory('NgCartItem', ['$rootScope', '$log', function ($rootScope, $log) {
            var item = function (niceName, name, price, quantity, data, landing) {
                this.setNiceName(niceName);
                this.setName(name);
                this.setPrice(price);
                this.setQuantity(quantity);
                this.setData(data);
                if(landing) this.setPriceLanding(landing);
            };

            /*item.prototype.setId = function (id) {
                if (id) {
                    this._id = id;
                }
                else {
                    $log.error('An ID must be provided');
                }
            };

            item.prototype.getId = function () {
                return this._id;
            };*/

            item.prototype.setNiceName = function (niceName) {
              if (niceName) {
                  this._niceName = niceName;
              }
              else {
                  $log.error('An NICENAME must be provided');
              }
            };

            item.prototype.getNiceName = function () {
              return this._niceName;
            };

            item.prototype.getPriceLanding = function () {
                return this._landing;
            };

            item.prototype.setPriceLanding = function (landing) {
                this._landing = landing;
            };

            item.prototype.setName = function (name) {
                if (name) {
                    this._name = name;
                } else {
                    $log.error('A name must be provided');
                }
            };

            item.prototype.getName = function () {
                return this._name;
            };

            item.prototype.setPrice = function (price) {
                var priceFloat = parseFloat(price);
                if (priceFloat) {
                    if (priceFloat <= 0) {
                        $log.error('A price must be over 0');
                    } else {
                        this._price = (priceFloat);
                    }
                } else {
                    $log.error('A price must be provided');
                }
            };

            item.prototype.getPrice = function () {
                return this._price;
            };

            item.prototype.setQuantity = function (quantity, relative) {
                var quantityInt = parseInt(quantity);
                if (quantityInt % 1 === 0) {
                    if (relative === true) {
                        this._quantity += quantityInt;

                        // Analytics.addProduct('sku-2', this.getData().reference, name,'','',this.getData().reference,  this._price, quantityInt,'',1);
                        // Analytics.trackCart('add');
                    } else {
                        this._quantity = quantityInt;
                    }

                    if (this._quantity < 1) {
                        this._quantity = 1;
                    }

                } else {
                    this._quantity = 1;
                    $log.info('Quantity must be an integer and was defaulted to 1');
                }

                $rootScope.$broadcast('ngCart:change', {});
            };

            item.prototype.getQuantity = function () {
                return this._quantity;
            };

            item.prototype.setData = function (data) {
                if (data) {
                    this._data = data;
                }
            };

            item.prototype.getData = function () {
                if (this._data) {
                    return this._data;
                }
                else {
                    $log.info('This item has no data');
                }
            };


            item.prototype.getTotal = function (originalPrice) {
              if(originalPrice) {
                return +parseFloat(this.getQuantity() * this.getData().originalPrice).toFixed(2);
              } else {
                return +parseFloat(this.getQuantity() * this.getPrice()).toFixed(2);
              }
            };

            item.prototype.toObject = function () {
                return {
                    niceName: this.getNiceName(),
                    name: this.getName(),
                    price: this.getPrice(),
                    quantity: this.getQuantity(),
                    data: this.getData(),
                    total: this.getTotal()
                };
            };

            return item;

        }])

        .service('store', ['$window', function ($window) {
            return {

                get: function (key) {

                    if ($window.localStorage [key]) {
                        var cart = angular.fromJson($window.localStorage [key]);
                        return JSON.parse(cart);
                    }

                    return false;
                },

                set: function (key, val) {

                    if (val === undefined) {
                        $window.localStorage.removeItem(key);
                    } else {
                        $window.localStorage [key] = angular.toJson(val);
                    }

                    return $window.localStorage [key];
                }
            };
        }]);
})();