(function () {
  'use strict';

  angular.module('mainApp')

    .controller('PostController', [function ($scope, $routeParams, $rootScope, $filter, $location, saRouter, serviceApiBlog, appConfig, toolsService) {

      var postName = $routeParams.postNiceName;
      $scope.categories = [];

      angular.element('body').on('click', '.module-table-of-contents a', function (e) {
        e.preventDefault();
        $('html, body').animate({
          scrollTop: $(angular.element(this).attr('href')).offset().top - angular.element('#header').height()
        }, "slow");
      });

      serviceApiBlog.getPost(postName).then(function (result) {

        if (result) {
          $scope.post = result;

          $scope.recipeContent = {
            comments: ""
          }

          // Share txt
          $scope.pinterestImg = appConfig.currentDomain + encodeURIComponent(result.imagePreview);
          $scope.pinterestMsg = encodeURIComponent(result.title);

          var route = saRouter.getRoute('blogPost');
          var postUrl = saRouter.replaceUrlParams(route.url, {
            postNiceName: postName
          });
          postUrl = $location.protocol() + '://' + $location.host() + postUrl;

          var twMsg = '?url=' + postUrl + '&text=' + encodeURIComponent(result.title);
          if ($rootScope.translations.config.social_media.twitter != '') twMsg += '&via=' + $rootScope.translations.config.social_media.twitter;

          $scope.twMsg = twMsg;

          $scope.postUrl = postUrl;
          $scope.recipeContent.comments = result.comments;

          // Reverse order comments from new to old
          if (!toolsService.isEmpty($scope.recipeContent.comments)) $scope.recipeContent.comments = $scope.recipeContent.comments.reverse();

          var seo_title = (result.cms.seo.title) ? result.cms.seo.title : result.title;
          seo_title += " " + $rootScope.translations.seo.separator + " " + $rootScope.translations.seo.website_name;
          $rootScope.pageTitle = seo_title;
          $rootScope.pageDescription = (result.cms.seo.description) ? result.cms.seo.description : $filter('stripTags')(result.shortDescription);
          $rootScope.pageImage = appConfig.currentDomain + result.imagePreview; // fb og image -

          console.log('serviceApiBlog > getPost > follow', result.cms.seo.follow);
          console.log('serviceApiBlog > getPost > index', result.cms.seo.index);

          var robots = '';

          if(result.cms.seo.index) { robots += 'index'; } else { robots += 'noindex'; }
          if(result.cms.seo.follow) { robots += ',follow'; } else { robots += ',nofollow'; }

          $rootScope.seo_robots = robots;

          serviceApiBlog.getCategories().then(function (result) {

            var temp = [];

            for (var i = 0; i < $scope.post.category.length; i++) {
              for (var j = 0; j < result.length; j++) {
                if ($scope.post.category[i].cms.url.slug == result[j].cms.url.slug) {
                  temp.push(result[j]);
                }
              }
            }

            $scope.categories = temp;
          });
        } else {
          $location.path("/404");
        }
      });

      $scope.searchPosts = function (text) {
        serviceApiBlog.searchPosts(text);
      }

      serviceApiBlog.getCategories().then(function (result) {
        $scope.categoriesBlog = result;
      });

      serviceApiBlog.getLastPosts().then(function (result) {
        $scope.lastPosts = result;
      });

      $scope.getClassHeader = function (headerImage) {
        if (toolsService.isEmpty(headerImage)) {
          return false;
        } else {
          return true;
        }
      }

      $scope.filterDate = function (date) {
        return $filter('date')(date, "dd-MM-yyyy");
      };

    }])
})();