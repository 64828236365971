(function () {
  angular.module('mainApp')

    .config(['$locationProvider', '$routeProvider', 'saRouterProvider', 'appConfig', 'timestamp', function ($locationProvider, $routeProvider, saRouterProvider, appConfig, timestamp) {

      if (window.app.config) {
        saRouterProvider.setDefaultLang(appConfig.apiDefaultLang);
        var dynamicRoutes = window.app.config.routes;

        dynamicRoutes.forEach(function (newRoute) {
          newRoute.settings.templateUrl = newRoute.settings.templateUrl + '?rev=' + timestamp;
          saRouterProvider.when(newRoute.route, newRoute.settings);
        });

        if (appConfig.region && appConfig.region == "ES-MYCOOKTOUCH") {
          saRouterProvider.when('/cocina/tipos/:tagsNiceName', {
            redirectTo: function (param, path) {
              path = path.replace(/:/g, '/');
              path = path.replace('/cocina', '');
              return path;
            }
          });
          saRouterProvider.when('/api/gallery/postpage/:ano/:mes/:img', {
            redirectTo: function (param, path) {
              window.location.href = 'https://mycook.es' + path;
            }
          });
        }
        saRouterProvider.otherwise({
          redirectTo: function () {
            return '/404';
          }
        });
      }
      saRouterProvider.install($routeProvider);
      $locationProvider.html5Mode(true).hashPrefix('!');
    }])

    .config(['$compileProvider', function ($compileProvider) {
      $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|chrome-extension|whatsapp):/);
    }])

    // White list url's
    .config(['$sceDelegateProvider', function ($sceDelegateProvider) {
      $sceDelegateProvider.resourceUrlWhitelist([
        'self',
        'http://www.youtube.com/embed/**',
        'https://www.youtube.com/embed/**'
      ]);
    }])

    .config(['cfpLoadingBarProvider', function (cfpLoadingBarProvider) {
      cfpLoadingBarProvider.includeSpinner = false;
    }])

    //intercepts request and check credentials, based on the userToken (localStorage) if it exists
    .config(function ($httpProvider) {
      $httpProvider.interceptors.push('authInterceptor');
    });
})();