
(function(){

  'use strict';
  
  angular.module('mainApp')
    
    .controller("DynamicLandingsController", ['$rootScope', '$scope', '$location', '$route', 'serviceLandings', 'seoService',
      function ($rootScope, $scope, $location, $route, serviceLandings, seoService) {
        console.log("DynamicLandingsController", $route.current.name);
        $scope.class = $route.current.name;
  
        var location = $location.protocol() + '://' + $location.host();
        if($location.host().indexOf('localhost') > -1) { // Development URL (add port number)
          location += ':' +  $location.port();
        }
  
        var projection = {
          json: 1,
          niceName: 1,
          _id: 0,
          seo: 1
        };
  
        serviceLandings.getLandingByNiceName($route.current.name, projection).then(function(landing) {
          $scope.landing = landing.json;

          if (landing.seo) {
            seoService.setTitle(landing.seo.title);
            seoService.setDescription(landing.seo.description);
            seoService.setRobots(landing.seo.index, landing.seo.follow);
          }

          if($route.current.name.indexOf('ebook') > -1) { // ebook-
            $rootScope.pageImage = location + '/img/landings/' + landing.niceName + '/taurus-mycook-fb-ebook-' + landing.niceName + '.jpg';
          } else {
            $rootScope.pageImage = location + '/img/landings/' + $route.current.name + '/share.jpg';
          }
        }).catch(function(error) {
          toolsService.manageError("DynamicLandingsController > getLandingByNiceName error", error);
        });
    }]);
  })();