(function () {

  'use strict';

  angular.module('mainApp')

    .factory('usersManager', ['$http', '$rootScope', '$filter', '$log', 'appConfig', 'sessionService', '$window', 'webStorage', 'mycookTouchWebSocketFactory', 'serviceRanks', function ($http, $rootScope, $filter, $log, _, appConfig, sessionService, $window, webStorage, mycookTouchWebSocketFactory, serviceRanks) {

      var _usersFactory = {};
      var userInfoPromise;

      _usersFactory.getCurrentUserInfo = function (niceName) {
        webStorage.set('userNiceName', niceName);
        return _usersFactory.getUserInfo(niceName, 'private');
      };

      _usersFactory.getMachinesType = function (type) {
        // si el user no tiene nuevas, tiene antiguas
        // Devuelve máquinas en las que es owner o guest
        var urlDestino = appConfig.mycookTouchDomain + '/user/machines/list?model=' + type;

        return $http({
          'method': 'get',
          url: urlDestino
        }).then(function (res) {
          return res.data;
        }, function (err) {
          return err;
        });
      };

      _usersFactory.getMachineList = function () {

        var urlDestino = appConfig.mycookTouchDomain + '/user/machines/list?model=all&onlyOwner=true';

        return $http({
          'method': 'get',
          url: urlDestino
        }).then(function (res) {
          return res.data;
        });
      };

      _usersFactory.updateMachineList = function (machineList, userNiceName) {

        var urlDestino = appConfig.localApiBasePath + '/updateMachines/' + userNiceName;

        return $http({
          'method': 'post',
          url: urlDestino,
          data: machineList
        }).then(function (res) {
          return res;
        });
      };

      _usersFactory.getUserInfo = function (niceName, profile) {
        profile = profile || 'public';
        var urlDestino = appConfig.mycookTouchDomain + '/user/' + niceName + '?profile=' + profile;

        return $http({
          'method': 'get',
          url: urlDestino
        }).then(function (res) {
          var user_info = res.data;
          serviceRanks.getRanks().then(function (response) {
            if (user_info.rank) {
              var found = $filter('filter')(response, {
                niceName: user_info.rank
              }, true);

              if (found.length > 0) {
                user_info.rank = found[0]['name'];
              }
            } else user_info.rank = '';
          });
          return user_info;
        });
      };

      _usersFactory.createUser = function (user) {

        var urlDestino = appConfig.localApiBasePath + '/newUser';

        return $http({
          'method': 'post',
          data: user,
          url: urlDestino
        }).then(function (res) {
          return res;

        }, function (err) {
          $log.error('USER ERROR: ', err);
          return err;

        });
      };

      _usersFactory.updateUser = function (user, formatObject) {
        var urlDestino = appConfig.mycookTouchDomain + '/user/' + user.niceName;
        if(formatObject || formatObject == undefined) { 
          user = formatObjectUserToUpdate(user);
        } else {
          delete user.niceName;
        }

        return $http({
          url: urlDestino,
          method: 'put',
          data: user
        }).then(function (res) {
          return res;
        }, function (err) {
          $log.error('USER_UPDATE_ERROR: ', err);
          return err;
        });
      };

      _usersFactory.loginUser = function (userName, userPassword) {
        var urlDestino = appConfig.mycookTouchDomain + '/login/';

        var data = {
          login: userName,
          password: userPassword
        };

        return $http({
          'method': 'post',
          data: data,
          url: urlDestino
        }).then(function (res) {
          webStorage.set('userNiceName', res.data.niceName);
          sessionService.setUserToken(res.data.token);
          return res.data;
        });
      };

      _usersFactory.logoutUser = function () {
        var userNiceName = webStorage.get('userNiceName');
        sessionService.setUserToken('');
        mycookTouchWebSocketFactory.logout(userNiceName);
        webStorage.remove('userNiceName');
        webStorage.remove('userMachine');
      };

      _usersFactory.checkToken = function (token) {
        var urlDestino = appConfig.mycookTouchDomain + '/checkToken/' + token;
        return $http({
          'method': 'get',
          url: urlDestino
        });
      };

      _usersFactory.deleteUser = function (niceName) {
        var urlDestino = appConfig.mycookTouchDomain + '/user/' + niceName;
        return $http({
          'method': 'delete',
          url: urlDestino
        }).then(function (res) {
          return res.data;
        });
      };

      _usersFactory.getDraftRecipes = function (niceName) {

        var urlDestino = appConfig.mycookTouchDomain + '/user/' + niceName + '/draftRecipes';

        return $http({
          'method': 'get',
          url: urlDestino
        }).then(function (res) {
          return res.data;
        });
      };

      _usersFactory.getUserSchema = function () {

        return {
          "contact": {
            "mail": "",
            "phone": ""
          },
          "login": "",
          "password": "",
          "niceName": "",
          "name": {
            "displayName": "",
            "firstName": "",
            "lastName": ""
          },
          "location": {
            "address": "",
            "country": "",
            "state": "",
            "town": "",
            "zip": ""
          },
          "profile": {
            "about": "",
            "birthday": "",
            "language": "",
            "social": {
              "googleplus": "",
              "instagram": "",
              "twitter": "",
              "webName": "",
              "web": ""
            }
          },
          "status": {
            //"newsletter": false,
            "privacy": false,
            "feedback": false
          }
        };
      };

      function formatObjectUserToUpdate(userObject) {
        userObject = _.pick(userObject, ['contact','location','name','profile','status']);
        
        return userObject;
      };

      return _usersFactory;
    }])
    // connectar quan clico "enviar receta" i no desconnectar fins que faig logout
    .factory('mycookTouchWebSocketFactory', ['$log', '$q', 'appConfig', 'sessionService', function ($log, $q, appConfig, sessionService) {
      var _mycookTouchFactory = {};
      var wsUri = appConfig.websocket;
      var openedWebSocket = false;
      var isLogged = false;

      _mycookTouchFactory.logout = function (userNiceName) {
        $log.debug("mycookTouchWebSocket > Logout", userNiceName, 'Websocket opened: ', openedWebSocket);
        if (openedWebSocket && userNiceName !== '') {
          isLogged = false;
          wsconnector.logout(userNiceName, appConfig.region);
        }
      }

      _mycookTouchFactory.sendRecipe = function (recipeNiceName, recipeTitle, userNiceName) {
        $log.debug("mycookTouchWebSocket > sendMessage: ", recipeNiceName, recipeTitle, userNiceName);
        var json_recipe = JSON.stringify({
          "user": userNiceName,
          "region": appConfig.region,
          "moveToRecipe": {
            "niceName": recipeNiceName,
            "title": recipeTitle
          },
          "onlyMachines": true
        });

        $log.debug("mycookTouchWebSocket > JSON: ", json_recipe);
        openWebSocket().then(function (result) {
          $log.debug('mycookTouchWebSocket - open > Success: ', result);
          if (!isLogged) {
            var token = sessionService.getUserToken();
            $log.debug('mycookTouchWebSocket > Login....', isLogged, token, appConfig.region);
            wsconnector.login(token, appConfig.region);
            wsconnector.eventListener = function (evt, type) {
              $log.debug("mycookTouchWebSocket - event eventListener", evt, type);
              if (type == 'session') {
                isLogged = true;
                wsconnector.sendMessage(json_recipe);
              }
            }
          } else {
            $log.debug('mycookTouchWebSocket - Login > User is already logged in', isLogged);
            wsconnector.sendMessage(json_recipe);
          }
        }, function (err) {
          $log.debug('mycookTouchWebSocket - open > ERROR: ', result);
        });
      }

      function openWebSocket() {
        var deferred = $q.defer();

        if (!openedWebSocket) {
          var ws = wsconnector.create(wsUri);
          wsconnector.eventListener = function (evt, type) {
            // GENERATE SOCKET
            if (evt.type == 'open') {
              deferred.resolve(evt);
            } else {
              deferred.reject(evt);
            }
            $log.debug("mycookTouchWebSocket > openWebSocket > wsconnector", evt, type);
            onEvent(evt, type);
          }
        } else {
          deferred.resolve('ok');
        }
        return deferred.promise;
      };

      function onEvent(evt, type) {
        $log.debug("mycookTouchWebSocket > Events: ", evt, type);

        switch (type) {
          case "channel":
            logChannel(evt);
            if (evt.type == 'open') openedWebSocket = true;
            break;
          case "session":
            logSession(evt);
            break;
          case "message":
            logMessage(evt);
            break;
          case "error":
            logError(evt);
            break;
        }
      }

      function logMessage(evt) {
        logElementToConsole("MESSAGE: ", evt.data || evt);
      }

      function logChannel(evt) {
        logElementToConsole("CHANNEL: ", evt.type);
      }

      function logSession(evt) {
        logElementToConsole("SESSION: ", evt.data || evt);
      }

      function logError(evt) {
        logElementToConsole("ERROR: ", evt);
      }

      function logElementToConsole(type, element) {
        $log.debug("mycookTouchWebSocket > Logs: ", type, element);
      }

      return _mycookTouchFactory;
    }]);
})();