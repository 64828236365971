(function () {

  'use strict';

  angular.module('mainApp')

    .controller("FavoriteRecipesController", ['$rootScope', '$scope', '$routeParams', '$log', 'toolsService', '_', 'recipeCategoriesFactory', 'seoService', 'usersManager',
      function ($rootScope, $scope, $routeParams, $log, toolsService, _, recipeCategoriesFactory, seoService, usersManager) {
        $scope.recipesTitle = $rootScope.translations.menu.user_logged.my_favorite_recipes;
        seoService.setTitle($rootScope.translations.menu.user_logged.my_favorite_recipes);

        recipeCategoriesFactory.getCategories().then(function (cats) {
          $scope.categories = cats;
        });

        $scope.badgeIsDeactivate = function (bdgCode, userBadges) {
          var isDeactivate = (toolsService.isEmpty(bdgCode) || toolsService.isEmpty(userBadges) || userBadges.indexOf(bdgCode) === -1);
          return isDeactivate;
        };

        $scope.getTotal = function () {
          return _.sumBy(_.map($scope.categories, 'favoritesCount'));
        }

        var authorNiceName = $routeParams.user;

        usersManager.getUserInfo(authorNiceName, 'public').then(function (data) {
          $log.debug("FavoriteRecipesController > getUserInfo DATA: ", data);
          $scope.currentAuthor = data;
        });

        $scope.badgeIsDeactivate = function (bdgCode, userBadges) {
          var isDeactivate = (toolsService.isEmpty(bdgCode) || toolsService.isEmpty(userBadges) || userBadges.indexOf(bdgCode) === -1);
          return isDeactivate;
        };
      }
    ]);
})();