(function(){

  'use strict';
  
  angular.module('mainApp')
  
    .service("serviceMautic", function ($rootScope, $q, $location, $http, $log, sessionService, toolsService) {
      var self = this;
  
      self.sendToMautic = function(data, ebookNicename, returnUrl) {
        var defer = $q.defer();
        var urlDestino = 'https://mycook.es/mautic/form/submit?formId=' + data.formId;

        if(ebookNicename !== '') {
          returnUrl = $rootScope.url('ebook-gracias', {ebookName: ebookNicename});
        } else if(toolsService.isEmpty(returnUrl)) {
          returnUrl = 'https://mycook.es';
        }

        var dataMautic = {
          "mauticform": data
        };
        
        $log.debug('User data before sending to Mautic: ', urlDestino, dataMautic, returnUrl);

        sessionService.getUserIP().then(function(user_ip) {
          if(user_ip && user_ip.query) {
            $http({
              method: 'post',
              url: urlDestino,
              data: dataMautic,
              headers: { 'X-Forwarded-For': user_ip.query }
            }).then(function(result) {
              $log.debug('Response Mautic: ', result);
              $location.path(returnUrl);
              defer.resolve("ok");
            }, function errorCallback(response) {
              $log.debug('Error Mautic: ', response);
              $location.path(returnUrl);
              defer.reject(response);
            });
          }
  
        }, function(error) {
          defer.reject(error);
        });
  
        return defer.promise;
      };
    });
  })();