(function (){
	angular.module('mainApp')

		.controller('AffiliatesLoginController', ['$scope', '$location', '$rootScope', '$routeParams', '$timeout', '_', 'appConfig', 'affiliatesUsersManager', 'webStorage',
			function ($scope, $location, $rootScope, $routeParams, $timeout, _, appConfig, affiliatesUsersManager, webStorage) {

				$scope.login = function (user, pass) {
				  affiliatesUsersManager.loginUser(user, pass).then(function (userInfo) {
				    $scope.errorLogin = false;
				    webStorage.set('userNiceNameAffiliates', userInfo.niceName);
				    $rootScope.$emit('userAffiliatesLoggedIn', userInfo);

				  }, function (e) {
				    $scope.errorLogin = true;

				    $timeout(function () {
				      $scope.errorLogin = false;
				    }, 1500);
				  });
				};

		}]);
})();