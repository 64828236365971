
(function(){

  'use strict';
  
  angular.module('mainApp')
  
    .directive('tableOfContentsMulti', function() {
      var template = '<li ng-repeat="item in content">';
            template += '<a ng-href="{{ \'#\' + item.id }}" title="{{ item.text }}" ng-click="$parent.scrollTo(\'#\' + item.id);">{{ item.text }}</a>';
            template += '<ul ng-if="item.modules && item.modules.length > 0">';
              template += '<li ng-repeat="subitem in item.modules">';
                template += '<a ng-href="{{ \'#\' + subitem.id }}" title="{{ subitem.text }}" ng-click="$parent.scrollTo(\'#\' + subitem.id);">{{ subitem.text }}</a>';
              template += '<li>';
            template += '<ul>';
          template += '</li>';
  
      /*var template = '<v-accordion id="my-accordion" class="vAccordion--mycook" control="accordion">';
            template += '<v-pane ng-repeat="item in content">';
              template += '<v-pane-header>';
                template += '<h3>';
                  template += '<a ng-if="!item.modules || item.modules.length == 0" ng-href="{{ \'#\' + item.id }}" title="{{ item.text }}" ng-click="$parent.scrollTo(\'#\' + item.id);">{{ item.text }}</a>';
                  template += '<span ng-if="item.modules && item.modules.length > 0">{{ item.text }}</span>';
                template += '</h3>';
              template += '</v-pane-header>';
  
              template += '<v-pane-content>';
                template += '<ul ng-if="item.modules && item.modules.length > 0">';
                  template += '<li ng-repeat="subitem in item.modules">';
                    template += '<a ng-href="{{ \'#\' + subitem.id }}" title="{{ subitem.text }}" ng-click="$parent.scrollTo(\'#\' + subitem.id);">{{ subitem.text }}</a>';
                  template += '</li>';
                template += '</ul>';
              template += '</v-pane-content>';
            template += '</v-pane>';
          template += '</v-accordion>';*/
  
      return {
        template: template,
        restrict: 'A',
        scope: {
          content: '=data'
        },
        link: function(scope, elm, attrs) {
          scope.scrollTo = function(elementID) {
            $('html, body').animate({scrollTop: $(elementID).offset().top - angular.element('#header').height()}, "slow");
          }
        }
      }
    })
  
    .directive('tableOfContents', function() {
      var template = '<li ng-repeat="(key,value) in content">';
          template += '<a ng-href="{{ \'#\' + value }}" title="{{ key }}" ng-click="$parent.scrollTo(\'#\' + value);">{{ key }}</a>';
        template += '</li>';
  
      return {
        template: template,
        restrict: 'A',
        scope: {
          content: '=data'
        },
        link: function(scope, elm, attrs) {
          scope.scrollTo = function(elementID) {
            $('html, body').animate({scrollTop: $(elementID).offset().top - angular.element('#header').height()}, "slow");
          }
        }
      }
    })
  
    .directive('dynamicContent', ['$http', '$compile', '$templateCache', 'appConfig', 'toolsService',
      function($http, $compile, $templateCache, appConfig, toolsService) {
  
      return {
        restrict: "E",
        scope: {
          currentUser: '=',
          json: '=',
          mediaDomain: '='
        },
  
        link: function (scope, elem, attrs) {
          var container_landing = elem;
  
          var unregister = scope.$watch('json', function(newVal) {
            if(newVal) {
              if(!newVal) return false;
  
              unregister(); // stop watching json
  
              var currentJson = newVal;
  
              console.log('currentJson', currentJson);
  
              angular.forEach(currentJson, function(item, i) {
                container_landing.append('<span id="mod_' + i + '"></span>');
  
                var newScope = scope.$new(true);
                newScope.content = item.content;
  
                if(item.template == 'two-columns-30-60' && item.content.ebook) {
                  scope.$watch('currentUser', function(currentUser) {
                    newScope.currentUser = currentUser;
                  }, true);
                }
  
                var html = $templateCache.get('template/static/modules/' + item.template + '.html');
                var html_template = angular.element(html);
                var container_element = container_landing.find('#mod_' + i);
  
                if(item.template == 'recipe-list-nutritional') {
                  var urlDestino = appConfig.mycookTouchDomain + '/recipes/getNutritional';
  
                  if(!toolsService.isEmpty(item.content.sortBy)) {
                    urlDestino += '?sortBy=' + item.content.sortBy;
                  }
  
                  console.log(urlDestino, { niceName: item.content.modules });
  
                  $http({
                    method: 'post',
                    url: urlDestino,
                    data: { niceName: item.content.modules }
                  }).then(function successCallback(response) {
                    newScope.content.modules = response.data;
                    newScope.mediaDomain = scope.mediaDomain;
                    container_element.append(html_template);
                    $compile(html_template)(newScope);
                   }, function errorCallback(error) {
                    console.log('MycookTouchController - nutritionalRecipesNicename > errorCallback', error);
                  });
                } else {
                  container_element.append(html_template);
                  $compile(html_template)(newScope);
                }
              });
            }
          }, true);
        }
      };
    }]);
  })();