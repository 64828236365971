/**
 *  - recipeCategoriesFactory
 *  - CategoriesController
 *  - CategoryController
 */

(function () {

  'use strict';

  angular.module('mainApp')

    /**
     Categories Factory
     */

    .factory('recipeCategoriesFactory', ['$http', '$q', '$filter', '$rootScope', '$log', 'appConfig', 'toolsService',
      function ($http, $q, $filter, $rootScope, $log, appConfig, toolsService) {

        var _recipeCategoriesFactory = {};
        var deferredCats;

        _recipeCategoriesFactory.getCategories = function () {
          // IMPROVE: faltaría aplicar caché
          if (angular.isUndefined(deferredCats)) {
            deferredCats = $q.defer();
            var urlDestino = appConfig.mycookTouchDomain + '/categories/?visibility=true';

            $http({
              method: 'get',
              url: urlDestino
            }).then(function (res) {
              var cats = res.data;
              // Añadir categoría todas
              var catTodas = {};
              catTodas.name = $rootScope.translations.common.category_all.title;
              catTodas.niceName = $rootScope.translations.common.category_all.link;
              catTodas.active = $rootScope.translations.common.category_all.active;
              catTodas.type = 'all';
              cats.splice(0, 0, catTodas);
              deferredCats.resolve(cats);
            });
          } else {
            $log.debug("recipeCategoriesFactory > getCategories > reaprovecho", deferredCats);
          }

          return deferredCats.promise;
        };

        _recipeCategoriesFactory.getCategoryByNiceName = function (catNiceName) {
          if (!toolsService.isEmpty(catNiceName)) {
            return _recipeCategoriesFactory.getCategories().then(function (cats) {
              var found = $filter('filter')(cats, {niceName: catNiceName}, true);

              if (found.length) {
                return found[0];
              }
            });
          } else {
            return $q.when(undefined);
          }
        };

        return _recipeCategoriesFactory;
      }
    ])

    .controller("RecipesCategoryController", ['$scope', 'recipeCategoriesFactory', '_', 'serviceLandings',
      function ($scope, recipeCategoriesFactory, _, serviceLandings) {

        /*function getRandomEbook() {

          var contentEbook = serviceLandings.getEbookDescriptions();

          var recipeEbooks = [
            "Gastronomia/Norteamericana/Mexicana",
            "category/arroces",
            "category/salsas-y-guarniciones",
            "accessories/steamer"
          ];

          var random = Math.floor(Math.random()*recipeEbooks.length);

          $scope.ebookContent = contentEbook[recipeEbooks[random]];
        }*/

        // TODO: random ebooks
        //serviceLandings.getRandomEbook();

        recipeCategoriesFactory.getCategories().then(function (cats) {
          $scope.categories = cats;
        });

        $scope.getTotal = function () {
          return _.sumBy(_.map($scope.categories, 'count'));
        }
      }
    ]);

})();