(function () {

  'use strict';

  angular.module('mainApp')

    .factory('affiliatesUsersManager', ['$http', '$rootScope', '$filter', '$log', '$q', 'appConfig', 'sessionService', '$window', 'webStorage',
      function ($http, $rootScope, $filter, $log, $q, appConfig, sessionService, $window, webStorage) {

        var _affiliatesUsersFactory = {};
        var userInfoPromise;

        _affiliatesUsersFactory.loginUser = function (userName, userPassword) {
          var urlDestino = appConfig.localApiBasePath + '/affiliates/login';

          var data = {
            login: userName,
            password: userPassword
          };

          return $http({
            method: 'post',
            data: data,
            url: urlDestino
          }).then(function (res) {
            webStorage.set('userNiceNameAffiliates', res.data.niceName);
            sessionService.setUserToken(res.data.token, true);
            return res.data;
          });
        };

        _affiliatesUsersFactory.logoutUser = function () {
          sessionService.setUserToken('', true);
          webStorage.remove('userNiceNameAffiliates');
        };

        _affiliatesUsersFactory.checkToken = function (token) {
          var urlDestino = appConfig.localApiBasePath + '/affiliates/checkToken/' + token;
          return $http({
            'method': 'get',
            url: urlDestino
          });
        };

        _affiliatesUsersFactory.getAffiliateInfo = function (affiliate, profile) {
          var defer = $q.defer();
          var url = appConfig.localApiBasePath + '/affiliate/' + affiliate;
          if (profile) {
            url += '?profile=' + profile
          }
          $http({
            method: 'GET',
            url: url,
            headers: {
              'Authorization': 'Bearer ' + sessionService.getUserToken(true)
            }
          }).then(function (res) {
            var data = res.data;
            defer.resolve(data);
          }).then(function errorCallBack(err) {
            var error = err;
            defer.reject(error);
          });
          return defer.promise;
        };

        _affiliatesUsersFactory.updateAffiliateInfo = function (affiliate, info) {
          var defer = $q.defer();
          var url = appConfig.localApiBasePath + '/affiliate/' + affiliate;
          var body = info;
          $http({
            method: 'PUT',
            url: url,
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + sessionService.getUserToken(true)
            },
            data: info
          }).then(function (res) {
            var data = res.data;
            defer.resolve(true);
          }, function errorCallBack(err) {
            var error = err;
            defer.resolve(error);
          });

          return defer.promise;
        };

        return _affiliatesUsersFactory;
      }]);
})();