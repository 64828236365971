(function () {
  angular.module('mainApp')

    .factory('contactFactory', ['$http', '$q', 'appConfig', 'toolsService', 'storeFactory', function ($http, $q, appConfig, toolsService, storeFactory) {

      var _contactFactory = {};

      _contactFactory.sendContactInformation = function (userData) {

        deferredResponse = $q.defer();
        var urlDestino = '/api/contact/';

        var topic = userData.subject;
        var name = userData.name;
        var email = userData.mail;

        var data = {
          "contact_email": userData.contact_email,
          "topic": topic.name,
          "name": name,
          "email": email,
          "date": new Date().toISOString()
        };

        if(userData.machine) data.serialNumber = userData.machine;
        if(userData.message) data.message = userData.message;
        if(userData.order_id) data.order_id = userData.order_id;
        if(userData.address) data.address = userData.address;
        if(userData.state) data.state = userData.state;
        if(userData.surname_1) data.surname_1 = userData.surname_1;
        if(userData.surname_2) data.surname_2 = userData.surname_2;
        if(userData.town) data.city = userData.town;
        if(userData.zip) data.postal_code = userData.zip;
        if(userData.idcard) data.idcard = userData.idcard;

        $http({
          method: 'post',
          data: data,
          url: urlDestino
        }).then(function (res) {
          deferredResponse.resolve(true);
        }, function errorCallback(response) {
          deferredResponse.resolve(false);
        });

        return deferredResponse.promise;
      }

      return _contactFactory;
    }])

    .controller('ContactController', [function ($scope, $rootScope, $location, $timeout, appConfig, contactFactory, usersManager, toolsService, legalTerms, storeFactory, _) {
      $scope.userData = {
        "newsletter": false,
        "policy": false
      };

      usersManager.getMachineList().then(function(result) {
        if (result.length > 0) {
          $scope.userData.machine = result[0].niceName;
        }
      });

      if (!toolsService.isEmpty($scope.currentUser)) {
        $scope.userData['name'] = $scope.currentUser.name.firstName + ' ' + $scope.currentUser.name.lastName;
        $scope.userData['mail'] = $scope.currentUser.contact.mail;
      }

      if(appConfig.webSettings.shop) {
        storeFactory.getStates().then(function(states) {
          $scope.states = states;
        });

        if($location.search()) {
          if($location.search().order_id) {
            $scope.userData['order_id'] = $location.search().order_id;
  
            var foundElement = _.find($rootScope.translations.help.contact.form.subject_list, function(o) { 
              return o.name == 'Solicitar factura';
            });
            $scope.userData['subject'] = foundElement;
            $scope.userData['contact_email'] = foundElement.contact_email;

            $timeout(function() {
              $scope.contactForm.subject.$setDirty();
            }, 0);
          }

          if($location.search().name) $scope.userData['name'] = $location.search().name;
          if($location.search().address) $scope.userData['address'] = $location.search().address;
          if($location.search().state) $scope.userData['state'] = $location.search().state;
          if($location.search().surname) $scope.userData['surname_1'] = $location.search().surname;
          if($location.search().town) $scope.userData['town'] = $location.search().town;
          if($location.search().zip) $scope.userData['zip'] = $location.search().zip;
          if($location.search().idcard) $scope.userData['idcard'] = $location.search().idcard;
          if($location.search().mail) $scope.userData['mail'] = $location.search().mail;
        }
      }

      $scope.signature_contract = 'deactivated';
      $scope.userLegalData = {};
      $scope.anonymousUserData = true;

      $scope.cancelContract = function (userLegalData) {
        $scope.signature_contract = 'deactivated';
        $scope.contract_not_accepted = true;
      };

      $scope.acceptContract = function (validForm, userLegalData) {
        $scope.signature_contract = 'loading';
        $scope.contract_not_accepted = false;
        var sendToApi = new Array();
        for(var k in userLegalData) {
          if(userLegalData[k]) {
            sendToApi.push(k);
          }
        }

        legalTerms.acceptLegalTerms('multi', sendToApi, $scope.userData.mail).then(function(result) {
          contactFactory.sendContactInformation($scope.userData).then(function (response) {
            if (response === true) {
              $scope.contactForm.$setPristine();
              $scope.userData = {};
              $scope.successContact = true;
            } else {
              $scope.successContact = false;
            }
          });

          $scope.signature_contract = 'deactivated';
        });
      };

      $scope.sendContactForm = function () {
        // check solicitar factura
        if(!toolsService.isEmpty($scope.userData.order_id)) {
          storeFactory.checkMailOrder($scope.userData.mail, $scope.userData.order_id).then(function(data) {
            $scope.error_order_mail = false;
            $scope.error_order_id = false;
            signContract();
          }).catch(function (error) {
            if(error.status == '401') { // email incorrecte
              $scope.error_order_mail = true;
              $scope.error_order_id = false;
            } else { // order not found
              $scope.error_order_mail = false;
              $scope.error_order_id = true;
            }
          });
        } else {
          signContract();
        }
      }

      function signContract() {
        $scope.signature_contract = 'activated';

        legalTerms.getLegalTerms(['contactConditions']).then(function(legal) {
          $scope.legal = legal;
          $scope.signature_contract = 'activated';
        });

        // open contract
        // subscribe to newsletter params
        /*var data_newsletter = {};

        if (appConfig.webSettings.newsletter && $scope.userData.newsletter) {

          data_newsletter = appConfig.newsletter.mailchimp;

          Object.keys(appConfig.newsletter.items).forEach(function (key) {
            var value = '';

            appConfig.newsletter.items[key].forEach(function (el) {
              switch (el) {
                case 'name.firstName':
                  value = $scope.userData.name;
                  break;

                case 'contact.mail':
                  value = $scope.userData.mail;
                  break;
              }
            });

            value = value.trim();
            data_newsletter[key] = value;
          });
        }*/

        // subscribe to newsletter
        //newsletterService.addSubscription(data_newsletter).then(function (response_newsletter) {});
      }
    }]);
})();