(function(){
  'use strict';

  angular.module('mainApp')

  .controller('OrderResponseController', ['$log', '$filter', '$location','$window','$scope','$routeParams','$rootScope','appConfig','orderSvc','ngCart','TPVerrors','serviceStoreAnalytics',
    function($log, $filter, $location, $window, $scope, $routeParams, $rootScope, appConfig, orderSvc, ngCart, TPVerrors, serviceStoreAnalytics) {

      $scope.init = false;

      $scope.orderId = $routeParams.id;
      var signature = $routeParams.Ds_Signature;
      var merchant = $routeParams.Ds_MerchantParameters;

      console.log('OrderResponseController', $scope.orderId, signature);

      if($scope.orderId && signature && merchant) {
        orderSvc.get($scope.orderId, signature, merchant).then(function(order) {

          // SEO
          var seo_title = $rootScope.translations.shop.seo['store-payment-response'].title;
          seo_title += ' ' + $rootScope.translations.seo.separator + ' ' + $rootScope.translations.seo.website_name;
          $rootScope.pageTitle = $filter('translateVariables')(seo_title, order.data.id);

          console.log('order: ', order);

          if(order.data) {
            $scope.order = order.data;
            order = order.data;

            if(order.state && order.state == 'Paid') {
              $scope.status = true;

              serviceStoreAnalytics.checkoutProcess(5, 'response-ok');
              serviceStoreAnalytics.purchase(order);

              // If production call pixel and conversion
              // if(appConfig.domain.indexOf('conkansei.com')) {
              //     $scope.fireConversion(order.total);
              // }

              ngCart.empty();

            } else if (order.paymentInfo && order.paymentInfo.dsResponse) {
              serviceStoreAnalytics.checkoutProcess(5, 'response-ko');

              ngCart.setOrderId(order.id);

              var dsr = order.paymentInfo.dsResponse;
              var codeNum = parseInt(dsr, 10);
              $scope.errMsg = '';
              $scope.status = false;
              
              if(dsr && !isNaN(parseInt(codeNum, 10))) {
                var resKey = '';

                if(codeNum >= 181 && codeNum <= 182) {
                  resKey = '0181 - 0182';
                } else if(codeNum >= 208 && codeNum <= 209) {
                  resKey = '0208 - 209';
                } else if(codeNum >= 501 && codeNum <= 503) {
                  resKey = '0501 - 0503';
                } else {
                  resKey = dsr;
                }

                if(TPVerrors.hasOwnProperty(resKey)) {
                  $scope.errMsg = '<strong>' + TPVerrors[resKey].msg + " (" + $filter('translateVariables')($rootScope.translations.shop.response_payment.messages.code, order.paymentInfo.dsResponse) + ")</strong><br />" + TPVerrors[resKey].description;
                } else {
                  $scope.errMsg = '<strong>' + $filter('translateVariables')($rootScope.translations.shop.response_payment.messages.error_code, order.paymentInfo.dsResponse) + '</strong>';
                }
              }
            } else if (order.state && order.state == 'Error') {
              serviceStoreAnalytics.checkoutProcess(5, 'response-ko');

              $scope.errMsg = '<strong>' + $rootScope.translations.shop.response_payment.messages.payment_error + '</strong>';
              $scope.status = false;

              ngCart.setOrderId(order.id);

            } else  if (order.state && order.state == 'WaitingPayment') {
              serviceStoreAnalytics.checkoutProcess(5, 'response-ko');

              $scope.errMsg = '<strong>' + $rootScope.translations.shop.response_payment.messages.payment_error + '</strong>';
              $scope.status = false;

              ngCart.setOrderId(order.id);
            }
          } else {
            serviceStoreAnalytics.checkoutProcess(5, 'response-ko');

            $scope.status = false;
            $scope.errMsg = '<strong>' + $rootScope.translations.shop.response_payment.messages.incorrect_order + '</strong>';
          }

          $scope.init = true;
        },
        function(data) {
          $location.path($rootScope.url('notFound'));
        });

      } else {
        $scope.init = true;
        $scope.status = false;
        $scope.errMsg = $rootScope.translations.shop.response_payment.messages.incorrect_order;
      }

      $scope.fireConversion = function(price) {
        /*if(window.google_trackConversion) {
          window.google_trackConversion({
            google_conversion_id: 934768472,
            google_conversion_language: "es",
            google_conversion_format: "3",
            google_conversion_color: "ffffff",
            google_conversion_label: "5NTRCLuPhmIQ2N7dvQM",
            google_conversion_value: price,
            google_conversion_currency: "EUR",
            google_remarketing_only: false
          });
        }*/
      }
    }]);
})();