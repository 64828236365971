(function () {
  angular.module('mainApp')
    .controller('MycookSectionController', [function ($scope, $route, $rootScope, ngDialog, appConfig) {

      $scope.machine = '';
      if ($route.current.pageType == 'mycook-touch-functions') $scope.machine = 'touch';

      $scope.openDialog = function (element, templateID, className) {
        $scope.item = element;
        if ($scope.machine == 'touch') {
          if (element.video_touch) $scope.video = '//www.youtube.com/embed/' + element.video_touch + '?rel=0&showinfo=0&autohide=1&autoplay=1&enablejsapi=1&cc_load_policy=1&modestbranding=1&hl=' + appConfig.youtubeTranslations;
        } else {
          if (element.video) $scope.video = '//www.youtube.com/embed/' + element.video + '?rel=0&showinfo=0&autohide=1&autoplay=1&enablejsapi=1&cc_load_policy=1&modestbranding=1&hl=' + appConfig.youtubeTranslations;
        }
        ngDialog.open({
          template: templateID,
          className: className,
          scope: $scope
        });
      };
    }])

    .controller('MycookTouchController', [function ($scope, $rootScope, $http, $log, recipesFactory, appConfig) {
      var urlDestino;

      if (appConfig.region == "ES-MYCOOKTOUCH") {
        urlDestino = appConfig.mycookTouchDomain + '/recipes/getNutritional';
        $http({
          method: 'post',
          url: urlDestino,
          data: {
            niceName: ["focaccia-de-verduras", "croquetas-de-pescado", "petit-four-de-almendra-caramelizada", "pollo-al-ajillo", "tallarines-con-crema-de-espinacas-y-gambas", "conejo-a-la-mostaza", "brocoli-con-bechamel", "canelones-de-miel-y-requeson"]
          }
        }).then(function successCallback(response) {
          console.log("MycookTouchController - nutritionalRecipesNicename", response);
          $scope.nutritionalRecipes = response.data;
        }, function errorCallback(error) {
          $log.error('MycookTouchController - nutritionalRecipesNicename > errorCallback', error);
        });
      }

      $scope.currentUrl = $rootScope.ogUrl;
      $scope.tweet = encodeURIComponent($rootScope.translations.mycook_section.mycook_touch.share.tweet_text);

      urlDestino = appConfig.mycookTouchDomain + '/recipes/';
      var queryParams = {};
      queryParams.limit = 1;
      queryParams.sortBy = 'recent';
      queryParams.profile = 'seo';
      var query = {};

      query["status.verified"] = true;

      $http({
        method: 'post',
        url: urlDestino,
        params: queryParams,
        data: query
      }).then(function successCallback(response) {
        $scope.featuredRecipe = response.data.result[0];
      }, function errorCallback(error) {
        $log.error('MycookTouchController > errorCallback', error);
      });
    }]);
})();