(function () {

  'use strict';

  angular.module('mainApp')

    .controller("RecipesByTagsController", ['$rootScope', '$scope', '$filter', '$q', '$routeParams', 'toolsService', 'appConfig', 'recipesFactory', 'seoService', 'recipeListService', '_', 'sessionService', 'serviceLandings',
      function ($rootScope, $scope, $filter, $q, $routeParams, toolsService, appConfig, recipesFactory, seoService, recipeListService, _, sessionService, serviceLandings) {
        var criteria = {};
        var pageQuery = '?';
        var dieta = $routeParams.tipoDieta;
        var currentPage = ($routeParams.currentPage) ? $routeParams.currentPage : 1;

        $scope.currentPage = parseInt(currentPage);

        // Dieta deep linking
        if (!toolsService.isEmpty($routeParams.tipoDietaParam2)) {
          dieta += appConfig.itemSeparator + $routeParams.tipoDietaParam2;
        }

        if (!toolsService.isEmpty($routeParams.tipoDietaParam3)) {
          dieta += appConfig.itemSeparator + $routeParams.tipoDietaParam3;
        }

        $scope.showDeleteDialog = function (niceName, title) {
          recipeListService.showDeleteDialog(niceName, title);
        };

        $scope.showPublishDialog = function (recipe, title) {
          recipeListService.showPublishDialog(recipe, title, $scope.deviceDetector.raw.userAgent);
        };
        
        recipesFactory.getTags().then(function(result) {
          var tagsBundle = result;

          $scope.tags = tagsBundle.tags;
          $scope.tagsDietas = tagsBundle.tagsDietas;

          // ÑAPA (aunque es mejor que ir paseando el '/' por las urls...) -> hablar con Juan para que lo cambie
          var strRgx = new RegExp("\\" + appConfig.itemSeparator, "gi");
          criteria.tags = [$scope.tags[dieta].replace(strRgx, appConfig.decorator)];

          var tagLandingsCounter = sessionService.getValue('tag-' + criteria.tags[0], 'session');
          console.log('tagLandingsCounter ', tagLandingsCounter, 'tag-' + criteria.tags[0]);
          var offSetItems = (tagLandingsCounter) ? tagLandingsCounter : 0;
          console.log('offSetItems at ' + dieta, offSetItems);

          if(($scope.currentPage == 1 || !tagLandingsCounter) && appConfig.webSettings.landingsAtRecipesList) {
            // get Landings & ebooks
            var landingProjection = {
              title: 1,
              img: 1,
              niceName: 1,
              type: 1,
              shortDescription: 1,
              _id: 0
            };

            console.log('getLandingsByTerm', criteria.tags[0], 'tags');

            serviceLandings.getLandingsByTerm(criteria.tags[0], 'tags', landingProjection).then(function(landingsBundle) {
              loadRecipes(offSetItems, landingsBundle);
            });

          } else loadRecipes(offSetItems);
        });

        function loadRecipes(offSetItems, landingsBundle) {
          var orderInfo = recipeListService.setOrderFilters(criteria, pageQuery);
          pageQuery = orderInfo.pageQuery;
          criteria = orderInfo.criteria;

          $scope.pageQuery = pageQuery;
          $scope.criteria = criteria;

          if($scope.currentPage == 1 && landingsBundle && landingsBundle.status.count > 0) {
            landingsBundle.results = toolsService.getRandomValuesFromArray(landingsBundle.results, 3);
            offSetItems = landingsBundle.results.length;

            _.map(landingsBundle.results, function(obj) {
              if(obj.type == 'ebook') obj.niceName_noebook = obj.niceName.replace('ebook-', '');
              return obj;
            });
          }

          // Save counter in session
          sessionService.setValue('tag-' + criteria.tags[0], offSetItems, 'session');

          $scope.$emit('LOAD');

          recipesFactory.getRecipesByCriteria(criteria, $scope.currentPage, offSetItems).then(function (recipes) {
            var cTags = criteria.tags[0];
            var seo_title = _.replace(cTags, new RegExp("/", "g"), " - ");
            cTags = cTags.replace(/\//, " - ");

            seoService.setTitle(seo_title, $scope.currentPage);
            seoService.setDescription($filter('translateVariables')($rootScope.translations.seo.tagsRecipeList.description, cTags), $scope.currentPage);
            $scope.recipesTitle = cTags;

            var bundleRecipesAndLandings = recipes.result;

            // SET LANDING DATA TO VIEW
            if($scope.currentPage == 1 && landingsBundle && landingsBundle.status.count > 0) {
              bundleRecipesAndLandings = toolsService.pushElementsAtPositions(recipes.result, landingsBundle.results);
            }

            console.log('//////////// RecipesByCategory > bundleRecipesAndLandings', bundleRecipesAndLandings);

            $scope.contentData = bundleRecipesAndLandings;

            // Paginacion
            var _maxCount = recipes.status.count
            if(offSetItems > 0) _maxCount += offSetItems;
            $scope.maxCount = _maxCount;
            $scope.itemsByPage = appConfig.itemsByPage;
            $scope.lastPage = (_maxCount > appConfig.itemsByPage) ? Math.ceil(_maxCount / appConfig.itemsByPage) : 1;

            seoService.setPrevPage($scope.currentPage, $scope.pageQuery);
            seoService.setNextPage($scope.currentPage, $scope.pageQuery, $scope.lastPage);

            $scope.$emit('UNLOAD');

          }).catch(function (error) {
            toolsService.manageError("RecipesByTagsController > ERROR: ", error);
          });
        }
      }
    ]);
})();