(function () {
  angular.module('mainApp')

    .run(['$rootScope', '$anchorScroll', '$timeout', '$location', 'appConfig', 'toolsService', 'sessionService', 'amMoment', 'storeFactory',
      function ($rootScope, $anchorScroll, $timeout, $location, appConfig, toolsService, sessionService, amMoment, storeFactory) {

        amMoment.changeLocale(appConfig.momentJsLanguage);

        $rootScope.$on('SESSION_EXPIRED', function (event, data) {
          event.stopPropagation();
          sessionService.logoutUser(3000);
        });

        $rootScope.$on('SESSION_EXPIRED_AFFILIATES', function (event, data) {
          event.stopPropagation();
          sessionService.logoutUser(3000);
        });

        toolsService.newRandomQuery();

        // load Store configuration
        if (appConfig.webSettings.shop) {
          storeFactory.getConfiguration();
        }

        $anchorScroll.yOffset = 70;

        $rootScope.$on('$routeChangeSuccess', function (newRoute, oldRoute) {
          $timeout(function () {
            if(!toolsService.isEmpty($location.hash())) {
              $anchorScroll($location.hash());
            }
          }, 5000);
        });
      }
    ])

    .directive('isActiveNav', ['$rootScope', '$route', function ($rootScope, $route) {
      return {
        restrict: 'A',
        link: function (scope, elem, attrs) {
          $rootScope.$on('$routeChangeSuccess', function (event, $currentRoute, $previousRoute) {
            if (attrs.values) {
              if ($rootScope.isActive(attrs.values)) {
                elem.addClass('active');
              } else {
                elem.removeClass('active');
              }
            }
          });
        }
      };
    }])

    .controller('mainController', [function ($scope, $log, $location, $window, $rootScope, $filter, $cookies, $templateCache, appConfig, configService, toolsService, sessionService, usersManager, recipesFactory, deviceDetector, ngDialog, appTranslations, serviceBadges, webStorage, saRouter, affiliatesUsersManager, legalTerms, seoService) {
      var showLogs = toolsService.showLogs();
      configService.debugEnabled(showLogs);

      $rootScope.translations = appTranslations;

      $rootScope.gtm = appConfig.gtm || '';

      $rootScope.mycookTouchDomain = appConfig.mycookTouchDomain;

      seoService.setRobots();

      $rootScope.pinterestID = appConfig.pinterestID;

      serviceBadges.getBadges().then(function (response) {
        $rootScope.translations.badges = response;
      });

      legalTerms.getLegalTerms().then(function (response) {
        $rootScope.translations.legalTerms = response.legalterms;

        $scope.counterLegal = 0;
        if($rootScope.translations.legalTerms.memberConditions && $rootScope.translations.legalTerms.memberConditions.enabled) $scope.counterLegal++;
        if($rootScope.translations.legalTerms.internationalConditions && $rootScope.translations.legalTerms.internationalConditions.enabled) $scope.counterLegal++;
        if($rootScope.translations.legalTerms.newsletterConditions && $rootScope.translations.legalTerms.newsletterConditions.enabled) $scope.counterLegal++;
        if($rootScope.translations.legalTerms.contactConditions && $rootScope.translations.legalTerms.contactConditions.enabled) $scope.counterLegal++;
        if($rootScope.translations.legalTerms.ebookConditions && $rootScope.translations.legalTerms.ebookConditions.enabled) $scope.counterLegal++;
        if(appConfig.webSettings.shop && $rootScope.translations.legalTerms.ecommerceGuestConditions && $rootScope.translations.legalTerms.ecommerceGuestConditions.enabled) $scope.counterLegal++;
      });

      // Url Home
      $scope.homeUrl = $location.protocol() + '://' + $location.host();

      if($location.host().indexOf('localhost') > -1) { // Development URL (add port number)
        $scope.homeUrl += ':' +  $location.port();
      }

      $scope.showLogs = showLogs;

      $scope.deviceDetector = deviceDetector;

      //set media domain, for the moment is the same that the api
      $scope.mediaDomain = appConfig.mycookTouchDomain + '/image';

      //Path from project root
      $scope.absolut = '/';

      //set the adminRoles from the config
      $scope.adminRoles = appConfig.adminRoles;

      //set Webpage configuration (menu - mycook - mycooktouch - blog - etc)
      $scope.webSettings = appConfig.webSettings;

      // Check if nutritional is enabled
      $scope.nutritionalEnabled = false;

      // recipesCounter
      $rootScope.translations.recipesCounter = appConfig.recipesCounter || '1200';

      // WeChat
      if($rootScope.translations.config.share.wechat) {
        var url = 'https://api.weixin.qq.com/cgi-bin/token?grant_type=client_credential&appid=wx973c0217822fb65a&secret=7f33b18286ba6a5a4fc83c3346d1c544';

        console.log('wechat', url);
        var headers = {
          'Access-Control-Allow-Origin' : '*',
          'Access-Control-Allow-Methods' : 'GET',
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        };

        /*$http({
          method: "GET",
          headers: headers,
          url: url
        }).success(function(result) {
            console.log("Auth.signin.success!")
            console.log(result);
        }).error(function(data, status, headers, config) {
            console.log("Auth.signin.error!")
            console.log(data);
            console.log(status);
            console.log(headers);
            console.log(config);
        });

        $http.jsonp(url, {jsonpCallbackParam: 'callback'}).success(function(data){
            console.log(data);
        });*/

        /*$http({
          method: 'GET',
          //method: 'JSONP',
          url: url,
          headers: {
            'Content-Type': 'application/json'
          },
        }).then(function (res) {
          $scope.wechatAccessToken = res.access_token;
        }, function errorCallBack(err) {
          console.log('wechat', err);
        });*/
      }

      $scope.shareWechat = function (url) {
        //$window.open(url, '_blank');
        var urlDestino = 'https://api.weixin.qq.com/cgi-bin/wxaapp/createwxaqrcode?access_token=' + $scope.wechatAccessToken;
        console.log('shareWechat', urlDestino, url);
        $http({
          method: 'POST',
          url: urlDestino,
          data: {
            "path": url
          }
        }).then(function (res) {
          console.log(res);
        }, function errorCallBack(err) {
          console.log('wechat', err);
        });
      };

      recipesFactory.getIngredientsSettings().then(function (settings) {
        if (settings) {
          $scope.nutritionalEnabled = settings;
        }
      }, function (err) {
        $log.error("RecipeController > getIngredientsSettings: ", err);
      });

      // Check if Website is viewed from mobile or desktop (show share Whatsapp)
      $scope.isMobile = false;
      (function (a, b) {
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) $scope.isMobile = true;
      })(navigator.userAgent || navigator.vendor || window.opera);

      var counter_footer_modules = 1;

      if ($rootScope.translations.config.social_media.footer_module) counter_footer_modules++;
      if ($rootScope.translations.footer.communities.communities_list && $rootScope.translations.footer.communities.communities_list.length > 0) counter_footer_modules++;
      if (($rootScope.translations.config.download_app) && ($rootScope.translations.config.download_app.google_play !== '' || $rootScope.translations.config.download_app.app_store !== '')) counter_footer_modules++;
      if (appConfig.webSettings.newsletter) counter_footer_modules++;

      $scope.footer_modules = 'column-' + counter_footer_modules;

      $scope.logout = function (affiliates) {
        if (affiliates && affiliates == true) {
          affiliatesUsersManager.logoutUser();
          $scope.currentUserAffiliates = '';
        } else {
          usersManager.logoutUser();
          $scope.currentUser = '';
          $scope.currentUserInfo = '';
        }

        sessionService.logoutUser(1000); // redirect in case private page
      };

      $rootScope.$on('userAffiliatesLoggedIn', function (event, data) {
        //$scope.currentUserAffiliates = data;

        /*if (!data.legalTerms) {
          // Check contrato
          var url = appConfig.localApiBasePath + '/affiliates/legalterms';
          $http({
            method: 'GET',
            url: url
          }).then(function (res) {
            $scope.signature_contract = res.data;
            $scope.signature_contract.affiliates = true;
          }, function errorCallBack(err) {
            console.log(err);
          });
        }*/
      });

      $rootScope.$on('userLoggedIn', function (event, data) {
        getCurrentUserInfo(data.niceName);

        // Check contrato
        // change URL
        /*var url = appConfig.localApiBasePath + '/affiliates/legalterms';
        $http({
          method: 'GET',
          url: url
        }).then(function (res) {
          console.log(res);
          $scope.signature_contract = res.data;
          $scope.signature_contract.affiliates = false;
        }, function errorCallBack(err) {
          console.log(err);
        });*/
      });

      function getCurrentUserInfo(niceName) {
        usersManager.getCurrentUserInfo(niceName).then(function (user) {
          $scope.currentUser = user;

          var currentUserMachine = webStorage.get('userMachine');

          if (!toolsService.isEmpty(currentUserMachine)) {
            $log.debug("Main get currentUserInfo > reaprovecho currentUserMachine de sessionService: ", currentUserMachine);
            $scope.userMachine = currentUserMachine;
          } else {
            usersManager.getMachinesType('mycooktouch').then(function (bundleData) {
              $log.debug("AppController > userMachine > data: ", user, bundleData);
              var userMachine = (appConfig.webSettings.mycook) ? 'mycook' : 'mycooktouch';
              // así podemos personalizar la información que se muestra
              // Devuelve máquinas QUE PUEDE USAR
              if (bundleData.length > 0) {
                userMachine = 'mycooktouch';
              }
              $scope.userMachine = userMachine;
              webStorage.set('userMachine', userMachine);
            });
          }
        });
      };

      //update seo parameters on route change, checks if user can see a private page based on his user token and page privacy
      $rootScope.$on('$routeChangeSuccess', function (event, $currentRoute, $previousRoute) {

        // Collapse menus
        $scope.closeUserNavigation();

        getTags();

        seoService.setPrevPage();
        seoService.setNextPage();

        $rootScope.currentLocation = $location.path();
        $rootScope.canonical = '';
        $rootScope.ogUrl = $location.$$absUrl;

        if (!toolsService.isEmpty($currentRoute) && !toolsService.isEmpty($currentRoute.$$route)) {
          $rootScope.currentRouteName = $currentRoute.$$route.routeName;

          var pageInfo = $currentRoute.$$route;
          $rootScope.pageType = pageInfo.pageType;
          $rootScope.pageName = pageInfo.name;

          if ($rootScope.pageType == 'affiliates') {
            seoService.setRobots(false, false);
          } else {
            seoService.setRobots(pageInfo.index, pageInfo.follow);
          }

          if (!toolsService.isEmpty(pageInfo)) {
            $rootScope.pageCssClass = (!toolsService.isEmpty(pageInfo.pageCssClass)) ? pageInfo.pageCssClass : '';

            var seoObj = seoService.searchSeoAtTranslations();

            seoService.setImage(); // set default image

            if (!toolsService.isEmpty(seoObj)) {
              var title = (!toolsService.isEmpty(seoObj.title)) ? seoObj.title : '';
              var description = (!toolsService.isEmpty(seoObj.description)) ? seoObj.description : '';
              seoService.setTitle(title);
              seoService.setDescription(description);
            }

            var mitoken;

            if ($rootScope.pageType == 'affiliates') {
              mitoken = sessionService.getUserToken(true);
            } else {
              mitoken = sessionService.getUserToken();
            }

            // si hay token o estoy en una página privada, compruebo token, SI NO ESTOY PIDIENDO UN TEMPLATE
            if (!toolsService.isEmpty(mitoken) || (!toolsService.isEmpty(pageInfo.private) && pageInfo.private)) {
              checkToken(mitoken);
            }
          }
        }

        var dataLayer = window.dataLayer = window.dataLayer || [];

        dataLayer.push({
          event: 'ngRouteChange',
          attributes: {
            route: $location.url()
          }
        });
      });

      // LISTEN WHEN RECIPES ARE LOADED - getRecipesByCriteria
      $scope.$on('LOAD', function () {
        $scope.loading = true;
        $log.debug("AppController load: " + $scope.loading);
      });
      $scope.$on('UNLOAD', function () {
        $scope.loading = false;
        $log.debug("AppController unload: " + $scope.loading);
      });
      // END LISTEN WHEN RECIPES ARE LOADED

      function checkToken(token) {
        if ($scope.pageType == 'affiliates') {
          affiliatesUsersManager.checkToken(sessionService.getUserToken(true)).success(function (tokenInfo) {
            var userNiceName = tokenInfo.niceName;
            if (userNiceName !== '') {
              webStorage.set('userNiceNameAffiliates', userNiceName);
              $rootScope.$emit('userAffiliatesLoggedIn', tokenInfo);
              $rootScope.$emit('legalTerms', {
                'data': tokenInfo
              });
            }
          }).error(function (error) {
            $rootScope.$emit('SESSION_EXPIRED_AFFILIATES');
          });

        } else {
          usersManager.checkToken(token).success(function (tokenInfo) {
            var userNiceName = tokenInfo.niceName;

            if (userNiceName !== '') {
              $rootScope.$emit('userLoggedIn', {
                'niceName': userNiceName
              });

              $rootScope.$emit('legalTerms', {
                'data': tokenInfo
              });
            }
          }).error(function (error) {
            $rootScope.$emit('SESSION_EXPIRED');
          });
        }
      }

      $scope.getTagUrl = function (tag) {
        // Laura: ñapa para aside diets - xk el array de tags está guardado con :
        tag = tag.replace(appConfig.itemSeparator, appConfig.decorator);

        var tag_array = tag.split(appConfig.decorator);
        var route;
        var url;

        angular.forEach(tag_array, function (value, key) {
          value = $filter('slugify')(value);
          tag_array[key] = value;
        });

        switch (tag_array.length) {
          case 2:
            route = saRouter.getRoute('tagsRecipeList2Params');
            url = saRouter.replaceUrlParams(route.url, {
              tipoDieta: tag_array[0],
              tipoDietaParam2: tag_array[1]
            });
            break;

          case 3:
            route = saRouter.getRoute('tagsRecipeList3Params');
            url = saRouter.replaceUrlParams(route.url, {
              tipoDieta: tag_array[0],
              tipoDietaParam2: tag_array[1],
              tipoDietaParam3: tag_array[2]
            });
            break;

          default:
            route = saRouter.getRoute('tagsRecipeList');
            url = saRouter.replaceUrlParams(route.url, {
              tipoDieta: tag_array[0]
            });
            break;
        }
        return url;
      };

      function getTags() {
        recipesFactory.getTags().then(function (tagsBundle) {
          $scope.tags = tagsBundle.tags;
          $scope.tagRank = tagsBundle.tagsRank;
          $scope.tagsDietas = tagsBundle.tagsDietas;
        });
      }

      // open popup policyPrivacy
      $scope.openPrivacyPolicy = function (legalType, position) {
        var legalType = legalType || 'memberConditions';

        if(legalType === 'cookies') {
          ngDialog.open({
            template: $templateCache.get('template/privacy-cookies.html'),
            plain: true,
            className: 'ngdialog-theme-default'
          });
        } else {
          ngDialog.open({
            template: $templateCache.get('template/privacy-policy.html'),
            plain: true,
            className: 'ngdialog-theme-default',
            onOpenCallback: function() {
              $rootScope.$emit('changeLegalBox', {
                legal: legalType,
                position: position
              });
            }
          });
        }
      };

      $scope.toggleFooterPolices = function () {
        $scope.footerPolices = !$scope.footerPolices;
      };

      // MENU NAV
      $scope.toggleUserNavigation = function () {
        if ($scope.userNavigation) { // Activo, desactivamos hamburguesa
          $scope.buttonHamburguer = false;
        } else $scope.buttonHamburguer = true;
        $scope.userNavigation = !$scope.userNavigation;
        $scope.mobileNavigation = false;
      };

      $scope.closeUserNavigation = function (event) {
        $scope.userNavigation = false;
        $scope.buttonHamburguer = false;
        $scope.submenuIsCollapsed = false;
        $scope.mobileNavigation = false;

        if(event && angular.element(event.target).attr('id') !== 'footerPolices') {
          $scope.footerPolices = false;
        }
      };

      $scope.toggleHamburger = function () {
        if ($scope.buttonHamburguer) { // Activo, desactivamos y quitamos menus
          $scope.closeUserNavigation();
          $scope.buttonHamburguer = false;
          $scope.mobileNavigation = false;
        } else { // Desactivo, activamos el menú
          $scope.buttonHamburguer = !$scope.buttonHamburguer;
          $scope.mobileNavigation = true;
        }
      };

      $scope.toggleSubmenu = function () {
        $scope.submenuIsCollapsed = !$scope.submenuIsCollapsed;
      };

      ///// get tag class (Used in advanced search & edit recipe)
      $scope.getClassTag = function (value) {
        value = slugify(value);

        var tags = appConfig['tags-separator'];
        var returnValue = '';

        Object.keys(tags).map(function (objectKey) {
          if (tags[objectKey] === value) {
            returnValue = objectKey;
          }
        });

        return returnValue;
      };

      angular.element($window).bind("scroll", function (e) {
        if (angular.element($window).scrollTop() > 1) { //  >= (angular.element('#header').height() - angular.element('.module-pending-drafts').height())
          angular.element('.module-pending-drafts').addClass('fixed');
        } else {
          angular.element('.module-pending-drafts').removeClass('fixed');
        }
      });

      // footer
      $scope.currentYear = new Date().getFullYear();
      $scope.counterLegal = 0;

      //COOKIES
      $scope.acceptCookies = function () {
        $scope.showCookiesPolicy = false;

        var now = new Date();
        var expireDate = new Date(now.getFullYear(), now.getMonth() + 6, now.getDate());
        $cookies.put('POLICY_COOKIES', true, {
          'expires': expireDate
        });
      }

      var showCookies = true;
      if ($cookies.get('POLICY_COOKIES')) showCookies = false;
      $scope.showCookiesPolicy = showCookies;
    }]);
})();