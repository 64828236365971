(function () {
  angular.module('mainApp')
    .controller('HelpVideoTutorialsController', [function ($scope, $rootScope, $log, appConfig, ngDialog, youtubeFactory) {

      $scope.playLists_mycook = [];
      $scope.playLists_mycooktouch = [];

      var playListsIDS_mycooktouch = $rootScope.translations.help.video_tutorials.mycooktouch_playlist;
      var playListsIDS_mycook = $rootScope.translations.help.video_tutorials.mycook_playlist;

      playListsIDS_mycooktouch.forEach(function (item) {
        getPlaylistVideos(item, $scope.playLists_mycooktouch);
      });

      playListsIDS_mycook.forEach(function (item) {
        getPlaylistVideos(item, $scope.playLists_mycook);
      });

      function getPlaylistVideos(item, arr) {
        youtubeFactory.getVideosFromPlaylistById({
          playlistId: item.playlist_id,
          part: "snippet", // (optional) default: 'id,snippet'
          key: "AIzaSyCb8ZyXJ4AsTrQb2SE7geu9QXnalrpOE2I",
        }).then(function (_data) {
          //on success
          arr.push({
            "title": item.title,
            "videos": _data.data
          });
        }).catch(function (_data) {
          //on error
          $log.error("getVideosFromPlaylistById ERROR: ", _data);
        });
      }

      $scope.mycook = false;
      $scope.mycooktouch = false;
      $scope.before_selection = true;

      if (!appConfig.webSettings.mycook) {
        $scope.mycooktouch = true;
      } else if (!appConfig.webSettings.mycooktouch) {
        $scope.mycook = true;
      }

      $scope.openDownloads = function (machine) {
        $scope.before_selection = false;
        if (machine == 'mycooktouch') {
          $scope.mycooktouch = true;
          $scope.mycook = false;
        } else {
          $scope.mycook = true;
          $scope.mycooktouch = false;
        }
      };

      $scope.openDialog = function (title, youtube_id, templateID, className) {
        $scope.title = title;
        console.log('openDialog', title, youtube_id);
        $scope.video = '//www.youtube.com/embed/' + youtube_id + '?rel=0&showinfo=0&autohide=1&autoplay=1&enablejsapi=1&cc_load_policy=1&modestbranding=1&hl=' + appConfig.youtubeTranslations;

        ngDialog.open({
          template: templateID,
          className: className,
          scope: $scope
        });
      }
    }]);
})();