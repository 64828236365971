(function () {
  angular.module('mainApp')

    .service('recipeListService', function ($rootScope, $route, $routeParams, $log,
      recipesFactory, ngDialog, toolsService) {

      var showDeleteDialog = function (niceName, title) {
        if (!toolsService.isEmpty(niceName)) {

          var recipeToDelete = {
            niceName: niceName,
            title: title
          };
          ngDialog.openConfirm({
            template: 'remove-recipe.html',
            className: 'new-recipe',
            data: recipeToDelete
          }).then(function (r) {
            recipesFactory.deleteRecipe(niceName).then(function (res) {
              $route.reload();
            }, function (err) {
              $log.error("RecipeListService > showDeleteDialog > deleteRecipe ERROR: ", err);
            });
          }, function (e) {
            $log.error("RecipeListService > showDeleteDialog > openConfirm ERROR: ", err);
          });
        }
      }

      var showPublishDialog = function (recipe, title, userAgent) {

        if (!toolsService.isEmpty(recipe.niceName)) {
          var recipeToPublish = {
            niceName: recipe.niceName,
            title: title
          };

          ngDialog.openConfirm({
            template: 'send-recipe.html',
            className: 'publish-recipe',
            data: recipeToPublish
          }).then(function (r) {
            //quitar los tags de draft
            recipe.grants.view = ['public'];
            recipe.grants.search = ['public'];
            recipe.info = {
              creationDate: new Date(),
              creationSource: userAgent
            };

            recipesFactory.edit(recipe).then(function (res) {
              $rootScope.$broadcast('updateDraftRecipes', {});
              $route.reload();
            }, function (err) {
              $log.error("RecipeListService > showPublicDialog > editRecipe ERROR: ", err);
            });

          }, function (e) {
            $log.error("RecipeListService > showPublicDialog > openConfirm ERROR: ", err);
          });
        }
      }

      var setOrderFilters = function (criteria, pageQuery) {
        var sortBy = $routeParams.sortBy;
        if(!sortBy) {
          sortBy = 'recent'
        }

        pageQuery += setPageQuery(sortBy, 'sortBy');
        criteria = setCriteria(criteria, sortBy, 'sortBy');
        
        var hasVideo = $routeParams.hasVideo;
        pageQuery += setPageQuery(hasVideo, 'hasVideo');
        criteria = setCriteria(criteria, hasVideo, 'hasVideo');

        var user = $routeParams.user;
        //pageQuery += setPageQuery(user, 'user');
        criteria = setCriteria(criteria, user, 'user');

        var verified = $routeParams.verified;
        pageQuery += setPageQuery(verified, 'verified');
        criteria = setCriteria(criteria, verified, 'verified');

        var orderInfo = {
          pageQuery: pageQuery,
          criteria: criteria
        }

        return orderInfo;
      }

      function setPageQuery(param, paramName) {
        var query = '';
        if(!toolsService.isEmpty(param)) {
          query = '&' + paramName + '=' + param;
        }
        return query;
      }
      
      function setCriteria(criteria, param, paramName) {
        if(!toolsService.isEmpty(param)) {
          criteria[paramName] = param;
        }
        return criteria;
      }

      return {
        showDeleteDialog: showDeleteDialog,
        showPublishDialog: showPublishDialog,
        setOrderFilters: setOrderFilters
      }
    });
})();

