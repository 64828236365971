(function () {

  'use strict';

  angular.module('mainApp')

    .controller("RecipesByCategoryController", ['$rootScope', '$scope', '$filter', '$q', '$routeParams',
     '_', 'toolsService', 'appConfig', 'recipesFactory', 'recipeCategoriesFactory', 'seoService', 'recipeListService', 'serviceLandings', 'sessionService',
      function ($rootScope, $scope, $filter, $q, $routeParams,
        _, toolsService, appConfig, recipesFactory, recipeCategoriesFactory, seoService, recipeListService, serviceLandings, sessionService) {
        
        var category = $routeParams.categoryNiceName;
        var promises = [];
        var criteria = {};
        var pageQuery = '?';
        var categoryTitle = '';
        var currentPage = ($routeParams.currentPage) ? $routeParams.currentPage : 1;

        $scope.currentPage = parseInt(currentPage);

        $scope.showDeleteDialog = function (niceName, title) {
          recipeListService.showDeleteDialog(niceName, title);
        };

        $scope.showPublishDialog = function (recipe, title) {
          recipeListService.showPublishDialog(recipe, title, $scope.deviceDetector.raw.userAgent);
        };

        promises.push(recipeCategoriesFactory.getCategoryByNiceName(category)); // get category information
        promises.push(recipesFactory.getTags()); // get Tags

        var categoryLandingsCounter = sessionService.getValue('category-' + category, 'session');
        var offSetItems = (categoryLandingsCounter) ? categoryLandingsCounter : 0;
        console.log('offSetItems at ' + category, offSetItems);

        if(($scope.currentPage == 1 || !categoryLandingsCounter) && appConfig.webSettings.landingsAtRecipesList) {
          // get Landings & ebooks
          var landingProjection = {
            title: 1,
            img: 1,
            niceName: 1,
            type: 1,
            shortDescription: 1,
            _id: 0
          };

          promises.push(serviceLandings.getLandingsByTerm(category, 'categories', landingProjection));
        }

        $q.all(promises).then(function(results) {
          var categoryInfo = results[0];
          var tagsBundle = results[1];
          if(results[2]) var landingsBundle = results[2];

          categoryTitle = categoryInfo.name;

          $scope.tags = tagsBundle.tags;
          $scope.tagsDietas = tagsBundle.tagsDietas;

          if (!toolsService.isEmpty(category)) {
            category = category.replace(appConfig.decorator, '');
            if (category != $rootScope.translations.common.category_all.link) {
              criteria.category = category;
            }
          }

          var orderInfo = recipeListService.setOrderFilters(criteria, pageQuery);
          pageQuery = orderInfo.pageQuery;
          criteria = orderInfo.criteria;
          criteria['status.idParent'] = '';
          $scope.pageQuery = pageQuery;
          $scope.criteria = criteria;

          if(landingsBundle && landingsBundle.status.count > 0) {
            landingsBundle.results = toolsService.getRandomValuesFromArray(landingsBundle.results, 3);
            offSetItems = landingsBundle.results.length;

            _.map(landingsBundle.results, function(obj) {
              if(obj.type == 'ebook') obj.niceName_noebook = obj.niceName.replace('ebook-', '');
              return obj;
            });
          }

          // Save counter in session
          sessionService.setValue('category-' + category, offSetItems, 'session');

          $scope.$emit('LOAD');

          recipesFactory.getRecipesByCriteria(criteria, $scope.currentPage, offSetItems).then(function (recipes) {
            var pageTitle = categoryTitle || $rootScope.translations.common.category_all.title;

            seoService.setTitle(pageTitle, $scope.currentPage);
            seoService.setDescription($filter('translateVariables')($rootScope.translations.seo.recipeList.description, pageTitle), $scope.currentPage);
            $scope.recipesTitle = pageTitle;

            var bundleRecipesAndLandings = recipes.result;

            // SET LANDING DATA TO VIEW
            if($scope.currentPage == 1 && landingsBundle && landingsBundle.status.count > 0) {
              bundleRecipesAndLandings = toolsService.pushElementsAtPositions(recipes.result, landingsBundle.results);
            }

            console.log('//////////// RecipesByCategory > bundleRecipesAndLandings', bundleRecipesAndLandings);

            $scope.contentData = bundleRecipesAndLandings;

            // Paginacion
            var _maxCount = recipes.status.count + offSetItems;
            $scope.maxCount = _maxCount;
            $scope.itemsByPage = appConfig.itemsByPage;
            $scope.lastPage = (_maxCount > appConfig.itemsByPage) ? Math.ceil(_maxCount / appConfig.itemsByPage) : 1;

            seoService.setPrevPage($scope.currentPage, $scope.pageQuery);
            seoService.setNextPage($scope.currentPage, $scope.pageQuery, $scope.lastPage);

            $scope.$emit('UNLOAD');

          }).catch(function (error) {
            toolsService.manageError("CategoryController > getRecipesByCriteria > ERROR1: ", error);
          });
        });
      }
    ]);
})();