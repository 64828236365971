(function (){
	angular.module('mainApp')

		.filter('byCategory', function() {
		  return function(items, search) {
		    if (!search) {
		      return items;
		    }

		    if (search == 'all') {
		      return items;
		    }

		    var itemsByProduct = new Array;

		    items.forEach(function(el){
		    	el.category.forEach(function(category) {
		    		if(category.niceName == search) {
	    				itemsByProduct.push(el);
		    		}
		    	});
		    });

		    return itemsByProduct;
		  };
		})

		.controller('StoreHomeController', ['$rootScope', '$scope', '$filter', '$timeout', 'saRouter', 'storeFactory', 'ngCart', 'serviceStoreAnalytics', 'sessionService',
			function($rootScope, $scope, $filter, $timeout, saRouter, storeFactory, ngCart, serviceStoreAnalytics, sessionService) {

        sessionService.getUserIP().then(function(result) {
          $scope.countryCode = result.countryCode;
        });

				var timerImpressions;
				var arrImpressions = new Array();
				var listProductViews = new Array();

				function getProductUrl(niceName) {
				  var route = saRouter.getRoute('store-product');
				  return saRouter.replaceUrlParams(route.url, { niceName: niceName });
				}

				storeFactory.getConfiguration().then(function (config) {
	        $scope.currency = config.displayCurrency;
	      });

				storeFactory.getFeaturedProducts().then(function (data) {
					if(data.result.length > 0) {
						var products = data.result[0];
						
						storeFactory.getProduct(products.product.niceName).then(function (product) {
							var featuredProduct = product;
							products.product = featuredProduct;
							$scope.featuredProduct = products;
						});
					}
				});

				storeFactory.getProducts().then(function (data) {
					var products = data.result;
					var listCategories = new Array();

					/*for(var i = 0; i < products.length; i++) {
						if(products[i].category.length > 0) {
							//listCategories.push(products[i].category);
							Array.prototype.push.apply(listCategories, products[i].category);
						}
					}*/

					products.forEach(function(el) {
			    	el.category.forEach(function(category) {
			    		var found = $filter('filter')(listCategories, {'niceName': category.niceName}, true);
							if (found.length == 0) {
		    				listCategories.push(category);
							}
			    	});
			    });

					listCategories.unshift({
						'niceName': 'all',
						'text': $rootScope.translations.shop.home.all
					});

					for (var i = 0; i < products.length; i++) {
	          products[i].url = getProductUrl(products[i].cms.url.slug);
	        }

	        //serviceStoreAnalytics.addProductImpression(products, 'home');

					$scope.listCategories = listCategories;
					$scope.products = products;
					$scope.currentCategory = 'all';

				});

				$scope.updateCategoryFilter = function (category) {
					//console.log('enviar impresiones change cagetory: ', arrImpressions);
					sendImpressions();

					$scope.currentCategory = category;

					listProductViews = [];
					arrImpressions = [];

					var productsByCategory = $filter('byCategory')($scope.products, category);
				}

				$scope.ngCartAddItem = function (niceName, name, price, quantity, data) {
					ngCart.addItem(niceName, name, price, quantity, data);
				}

				$scope.productView = function(element, inview, position) {
					var el = element;
					el.positionImpression = position;

					if(inview && listProductViews.indexOf(el.niceName) === -1) {
						$timeout.cancel(timerImpressions);
						listProductViews.push(el.niceName);

						arrImpressions.push(el);

						timerImpressions = $timeout(function() {
							sendImpressions();

							arrImpressions = [];
						}, 5000);
					}
					
					return false;
				}

				function sendImpressions() {
					$timeout.cancel(timerImpressions);
					var category = ($scope.currentCategory == 'all') ? 'home' : 'home-list-by-category';

					serviceStoreAnalytics.addProductImpression(arrImpressions, category);
				}
		}]);
})();