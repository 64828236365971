(function () {
  'use strict';

  angular.module('mainApp')

    .controller('ShoppingGuideController', [function ($scope, legalTerms) {
      legalTerms.getLegalTerms(['termsOfSale']).then(function(legal) {
        $scope.legal = legal['termsOfSale'];
      });
    }]);

})();