(function () {

	'use strict';

	angular.module('mainApp')

		.controller('EditRecipeController', ['$sce', '$scope', '$rootScope', '$http', '$q', '$timeout', '$interval', '$upload', '$location', '$window', '$routeParams', '$filter', '_', '$log', 'appConfig', 'toolsService', 'recipesFactory', 'usersManager', 'sessionService', 'filtersManager', 'ngDialog', 'ingredientsManager', 'recipeCategoriesFactory',
			function ($sce, $scope, $rootScope, $http, $q, $timeout, $interval, $upload, $location, $window, $routeParams, $filter, _, $log, appConfig, toolsService, recipesFactory, usersManager, sessionService, filtersManager, ngDialog, ingredientsManager, recipeCategoriesFactory) {

				// Son la key, value es el que cambia por idioma
				var progressive = 'Progressiva',
						turbo = 'Turbo',
						saveAsDraft = false,
						filtersBundle = filtersManager.getFilters();
				//$scope.hours = filtersBundle.hours;
				$scope.minutesMycook = filtersBundle.minutesMycook;
				$scope.minutes = filtersBundle.minutes;
				$scope.seconds = filtersBundle.seconds;
				$scope.sendingRecipe = false;
				$scope.forms = {}; // forms
				$scope.basicInformationForm_submitted = false; // form validation
				$scope.steps_submitted = false; // form validation
				$scope.selecteds = [];
				$scope.courses = ["breakfast","appetizer","snack","first","second","dessert"];

        $scope.ingredientMeasures = ingredientsManager.getMeasurements();

				// INITIAL RECIPE
				var emptyStep = {
					"description": "",
					"accessories": [],
					"temperature": "",
					"speed": "",
					"ingredients": [
						/*{
							"qty": "",
							"unit": "",
							"name": "",
							"extra": "",
							"reserved": false
						}*/
					],
					"auxFields": {
						"time_hours": 0,
						"time_min": 0,
						"time_sec": 0
					}
				};

				var emptyGroup = {
					"name": $rootScope.translations.new_recipe.step_by_step.elaboration_title_value,
					"steps": [
						emptyStep
					]
				};

				$scope.emptyRecipe = {
					//"totalTimeH": 0,
					//"totalTimeM": 0,
					"price": 0,
					"difficulty": 0,
					"groups": [
						emptyGroup
					],
					"course": [],
					"tags": [],
          "videos": [],
          "imageRights": false
				};

				if (!toolsService.isEmpty($routeParams.recipeNiceName) && $routeParams.recipeNiceName.length > 2) {
					$scope.recipeCase = 'edit';
				} else $scope.recipeCase = 'new';

				// haurien de ser true xk estiguin deshabilitats
				if ($scope.recipeCase == 'new') {
					$scope.pane_2 = $scope.pane_3 = $scope.pane_4 = $scope.pane_5 = $scope.pane_6 = true;
				} else $scope.pane_2 = $scope.pane_3 = $scope.pane_4 = $scope.pane_5 = $scope.pane_6 = false;

				$scope.pane_1_title = $scope.pane_2_title = $scope.pane_4_title = false;

				recipeCategoriesFactory.getCategories().then(function (cats) {
					$scope.categories = cats;
				});

				$rootScope.$broadcast('updateDraftRecipes', {});

				$rootScope.$on('updatedDraftRecipes', function(e, draftRecipes) {
					$scope.currentDraftRecipes = draftRecipes;
				});

				$scope.$watch('userMachine', function(userMachine) {
					$log.debug('Edit recipe, userMachine:', userMachine);

					if (!toolsService.isEmpty(userMachine)) {
						if (userMachine == 'mycooktouch') {
							$scope.speeds = filtersBundle.speedsTouch;
							$scope.temperatures = filtersBundle.temperaturesTouch;
						} else {
							$scope.speeds = filtersBundle.speeds;
							$scope.temperatures = filtersBundle.temperatures;
						}
					}
				});

				// If Edit fill all the recipe fields
				if ($scope.recipeCase == 'edit') {
					recipesFactory.getRecipe($filter('urlEncode')($routeParams.recipeNiceName)).then(function (recipe) {
						var seo_title = $filter('translateVariables')($rootScope.translations.seo.editRecipe.title, recipe.title);
						seo_title += ' ' + $rootScope.translations.seo.separator + ' ' + $rootScope.translations.seo.website_name;
						$rootScope.pageTitle = seo_title;

						$scope.recipe = recipe;
						if ($scope.recipe.groups.length > 1) {
							$scope.pType = 'group';
						} else {
							$scope.pType = 'single';
						}

						// rellenamos los auxFields de cada step, para poder hacer bien el binding
						for (var i = 0; i < $scope.recipe.groups.length; i++) {
							var g = $scope.recipe.groups[i];
							for (var j = 0; j < g.steps.length; j++) {
								var aStep = g.steps[j];
								if(!aStep.type) aStep.type = 'mycook'; // old recipes without type
								if(aStep.type && aStep.type !== 'mycook') aStep.auxType = aStep.type;
								aStep.auxFields = initStepAuxTags(aStep);
							}
						} // end formatting process
						preSelectTags();

            updateTotalSteps();
            
            registerCourses();

					}, function (error) {
						$log.error("RecipeController > EDIT recipe doesn't exists");
					});
				} else { // If New recipe reset form values
          resetForm();
				}
				
				function setEmptyRecipe() {
					$scope.selecteds = [];
					return _.cloneDeep($scope.emptyRecipe);
				}

				// add course to array
				$scope.updateCourse = function(course) {
					if($scope.recipe.course.indexOf(course) > -1) { // remove
						_.pull($scope.recipe.course, course);
					} else {
						$scope.recipe.course.push(course);
					}
				};

				// Step 2: Procesos de elaboracion
				$scope.setProcessType = function(pType) {
					$scope.pType = pType;
					$scope['pane_4'] = false;
					$scope.accordion.toggle(3);

					if (pType === 'single' && $scope.recipe.groups.length > 1) {
						$scope.recipe.groups.splice(1, $scope.recipe.groups.length - 1);
					}
				};
				// END Step 2: Procesos de elaboracion
				
				// Adding elements
				$scope.addNewGroup = function($event) {
					if ($event) {
						$event.preventDefault();
					}

					$scope.recipe.groups.push({name: '', steps: []});

					updateTotalSteps();
					// si creamos un nuevo proceso (no el primero) añadimos un nuevo paso directamente
					var totalGroups = $('.recipe-group').length;
					if (totalGroups > 0) {
						$scope.addNewStep(totalGroups);
						$timeout(function () {
							$('html, body').animate({
								scrollTop: $("#group" + totalGroups).offset().top - 100
							}, 500);
						}, 0);
					}
				};

				$scope.addNewStep = function (groupID, $event) {
					if ($event) {
						$event.preventDefault();
					}
					var newStep = _.cloneDeep(emptyStep);
					$scope.recipe.groups[groupID].steps.push(newStep);
					var totalSteps = $('#group' + groupID + ' .group-step').length;

					updateTotalSteps();

					setTimeout(function () {
						$('html, body').animate({
							scrollTop: $('#group' + groupID).find('.group-step').eq(totalSteps).offset().top - 100
						}, 500);
					}, 0);
				};

				$scope.addNewIngredient = function (groupID, stepID, reserved, $event) {
					$event.preventDefault();
					$scope.recipe.groups[groupID].steps[stepID].ingredients.push({
						qty: '',
						unit: '',
						name: '',
						extra: '',
						reserved: reserved
					});
				};

				$scope.removeIngredient = function (array_ing, ingredient, $event) {
					$event.preventDefault();
					_.pull(array_ing, ingredient);
				};
				// END Adding elements

				$scope.removeItem = function (array, index, $event) {
					$event.preventDefault();
					array.splice(index, 1);

					updateTotalSteps();
				};

				$scope.selectTypeStep = function (step, type, $event) {
					//'mycook', 'bake', 'fry', 'coat', 'manipulation', 'repose', 'stew'
					if(type == 'mycook') {
						$event.preventDefault();
						step.auxType = undefined;
					} else {
						step.auxFields.step_speed = step.speed = step.temperature = "";

						// remove accessories not available
						var availableAccessories = filtersBundle.accessoriesNoMycook;
						var filteredAccessories = _.filter(step.accessories, function(n) {
							return (availableAccessories.indexOf(n) > -1) ? true : false;
						});
						step.accessories = filteredAccessories;
					}

					step.type = type;
				};

				$scope.getStepNumber = function(indexGroup, indexStep) {
					var num = 0;

					for(var i = 0; i <= indexGroup; i++) {
						if(indexGroup == i) {
							num += (indexStep + 1);
						} else {
							num += $scope.recipe.groups[i]['steps'].length;
						}
					}

					return num;
				};

				// Validations during form
				$scope.mixingPaddleAlert = function (form, step) {
					var allowedRange = ["", "1", "2", "3", "4", "5", "2-3", "2-4", "2-5", "3-4", "3-5", "4-5"];
					var isValidPaddle;
					var showError;

					if (step.accessories.indexOf("mixingBlade") === -1 || (step.accessories.indexOf("mixingBlade") > -1 && allowedRange.indexOf(step.speed) > -1 && (step.auxFields && step.auxFields.step_speed != 'Turbo'))) {
						isValidPaddle = true;
						showError = false;
					} else {
						isValidPaddle = false;
						showError = true;
					}
					form.step_speed.$setValidity('paddleerror', isValidPaddle);

					return showError;
				};

				$scope.setSpeed = function (form, parent, index, $event) {
					var step = $scope.recipe.groups[parent].steps[index];

					checkMycookTime(form, step);

					if (!toolsService.isEmpty(step.auxFields) && !toolsService.isEmpty(step.auxFields.step_speed)) {
						if (!toolsService.isEmpty(step.temperature)) {
              var allowedRange = ['S', '1', '2', '3', '4', '5', progressive];
              
              if(appConfig.region == 'CN-MYCOOKTOUCH') {
                allowedRange = ['S', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', progressive, turbo];
              }

							if (!toolsService.isEmpty(step.auxFields.step_speed)) {
								// rangos no validos
								if ((step.auxFields.step_speed == progressive && !toolsService.isEmpty(step.auxFields.velprog_end) &&
									allowedRange.indexOf(step.auxFields.velprog_end) == -1) ||
									allowedRange.indexOf(step.auxFields.step_speed) == -1) {

									// en este caso, vaciamos la velocidad porque no es válida
									step.auxFields.step_speed = undefined;
									step.auxFields.velprog_end = undefined;
									step.auxFields.velprog_init = undefined;
									step.speed = undefined;
								} else {
									if (step.auxFields.step_speed == progressive) {
										step.speed = step.auxFields.velprog_init + '-' + step.auxFields.velprog_end;
									} else {
										step.speed = step.auxFields.step_speed;
									}
								}
							} else {
								step.speed = step.auxFields.step_speed;
							}

						} else {// si no estamos condicionados por la temperatura
							if (step.auxFields.step_speed == progressive) {
								// cambiar el undefined del inicio o marcarlo como error
								if (!toolsService.isEmpty(step.auxFields.velprog_init) && !toolsService.isEmpty(step.auxFields.velprog_end)) {
									step.speed = step.auxFields.velprog_init + '-' + step.auxFields.velprog_end;
								} else {
									step.speed = undefined;
								}
							} else if (step.auxFields.step_speed == turbo) {
								step.speed = step.auxFields.step_turbo;
								// vaciamos tiempo cuando turbo
								step.auxFields.time_min = 0;
								step.auxFields.time_sec = 0;
							} else {
								step.speed = step.auxFields.step_speed;
							}
						}
					} else {
						step.speed = undefined;
					}
				};

				function checkTime(form, step) {
					var isValidTime = true;

					if (step.auxFields.time_hours > 0 || step.auxFields.time_min > 0 || step.auxFields.time_sec > 0) {
						isValidTime = true;
					} else {
						isValidTime = false;
					}

					form.step_out_time_hours.$setValidity('timeerror', isValidTime);
					form.step_out_time_minutes.$setValidity('timeerror', isValidTime);
					form.step_out_time_sec.$setValidity('timeerror', isValidTime);
				}

				function checkMycookTime(form, step) {
					var isValidTime = true;

					// Validar que si mycook time está lleno solo cuando hay velocidad
					if (step.auxFields.step_speed !== 'Turbo' && step.auxFields.step_speed !== '' && !toolsService.isEmpty(step.auxFields.step_speed)) { // velocidad con tiempo obligatorio
						if (step.auxFields.time_min > 0 || step.auxFields.time_sec > 0) {
							isValidTime = true;
						} else {
							isValidTime = false;
						}
					} else {
						isValidTime = true;
					}

					form.step_time.$setValidity('timeerror', isValidTime);
					form.step_time_sec.$setValidity('timeerror', isValidTime);
				}

				$scope.rangeSpeeds = function (step) {
					return function (speed) {
						var isValidSpeed = true;
						if (!toolsService.isEmpty(step.temperature) && appConfig.region != 'CN-MYCOOKTOUCH') {
							var reducedRange = ['S', '1', '2', '3', '4', '5', progressive];
							isValidSpeed = (reducedRange.indexOf(speed.k) > -1);
						}
						return isValidSpeed;
					};
				};

				$scope.validateTemp = function (groupIdx, stepIdx) {
					var step = $scope.recipe.groups[groupIdx].steps[stepIdx];

					if (step.speed == "A" || step.auxFields.step_speed == turbo) {
						step.temperature = "";
					}
				};

				$scope.setRangeMin = function (step) {
					return function (min) {
						var maxLim = 1000;
						if (step.speed == "A") {
							maxLim = 4; // fins a 3 minuts
						}
						return (maxLim > min);
					};
				};

				// El temps de amassat no pot ser superior a 180 segons (3 minuts)
				$scope.validateDoughTime = function (groupIdx, stepIdx) {
					var step = $scope.recipe.groups[groupIdx].steps[stepIdx];

					if (step.speed == "A") {
						if (step.cookTime !== undefined && step.cookTime > 180) {
							step.auxFields.time_min = 3;
							step.auxFields.time_sec = 0;
						}
					}
				};

				$scope.greaterThan = function (val) {
					return function (item) {
						return item > val;
					};
				};

				$scope.lessThan = function (val) {
					return function (item) {
						if (val === undefined) {
							val = 10;
						}
						return (item < val);
					};
				};

				$scope.setMycookTime = function (form, parent, index, $event) {
					var step = $scope.recipe.groups[parent].steps[index];

					checkMycookTime(form, step);

					if (toolsService.isEmpty(step.auxFields)) {
						step.auxFields = {};
					}
					if (!isNaN(parseInt(step.auxFields.time_min, 10)) && !isNaN(parseInt(step.auxFields.time_sec, 10))) {
						step.cookTime = (parseInt(step.auxFields.time_min, 10) * 60) + parseInt(step.auxFields.time_sec, 10);
					} else {
						step.cookTime = 0;
					}
				};

				$scope.setTime = function (form, parent, index, $event) {
					var step = $scope.recipe.groups[parent].steps[index];

					checkTime(form, step);

					if (toolsService.isEmpty(step.auxFields)) {
						step.auxFields = {};
					}
					if (!isNaN(parseInt(step.auxFields.time_hours, 10)) && !isNaN(parseInt(step.auxFields.time_min, 10)) && !isNaN(parseInt(step.auxFields.time_sec, 10))) {
						step.stepTime = parseInt(step.auxFields.time_hours * 3600, 10) + (parseInt(step.auxFields.time_min, 10) * 60) + parseInt(step.auxFields.time_sec, 10);
					} else {
						step.stepTime = 0;
					}
				};

				function initStepAuxTags(aStep) {
					var auxFields = {};
					if (!toolsService.isEmpty(aStep)) {

						// speed
						if (/^([1-9]|[1-9][0-1])T$/i.test(aStep.speed)) { // Turbo case
							auxFields.step_turbo = aStep.speed;
							auxFields.step_speed = turbo;
						} else if (/(.*)\-(.*)/.test(aStep.speed)) { // Progressive case
							var aRange = aStep.speed.split("-");
							auxFields.velprog_init = aRange[0];
							auxFields.velprog_end = aRange[1];
							auxFields.step_speed = progressive;
						} else {  // normal case
							auxFields.step_speed = aStep.speed;
						}

						if (!toolsService.isEmpty(aStep.cookTime) && !isNaN(parseInt(aStep.cookTime, 10))) {
							// Mycook time
							auxFields.time_min = Math.floor(aStep.cookTime / 60);
							auxFields.time_sec = aStep.cookTime - auxFields.time_min * 60;
						} else if(!toolsService.isEmpty(aStep.stepTime)) {
							// Step time
							var time = aStep.stepTime;
							auxFields.time_hours = Math.floor(time / 3600);
							time = time - auxFields.time_hours * 3600;
							auxFields.time_min = Math.floor(time / 60);
							time = time - (auxFields.time_min * 60);
							auxFields.time_sec = time;
						} else {
							auxFields.time_hours = 0;
							auxFields.time_min = 0;
							auxFields.time_sec = 0;
						}
					}
					return auxFields;
				}

				function updateTotalSteps() {
					var arr = new Array({
						key: '',
						name: $rootScope.translations.new_recipe.select
					});

					var counter = 0;

					if($scope.recipe) {
						$scope.recipe.groups.map(function(group, key_group) {
							group['steps'].map(function(step, key_step) {
								counter++;

								arr.push({
									key: $filter('translateVariables')($rootScope.translations.single_recipe.reserved_ingredient, counter),
									name: $rootScope.translations.new_recipe.step_by_step.step_title + ' ' + counter
								});
							});
						});
					}

					$scope.totalSteps = arr;
				}

				// Check if recipe title exist
				$scope.recipeAlreadyExists = function (title) { // tiene que recibir el title, no el nicename
					$scope.alreadyExists = false;
					if ($scope.recipeCase == 'new' && !toolsService.isEmpty(title) && title.length > 3) {
						var titleNiceName = slugify(title);

						recipesFactory.getRecipe($filter('urlEncode')(titleNiceName)).then(function (recipe) {
							$scope.alreadyExists = true;
						}, function (error) {
							$log.error("RecipeController > recipe doesn't exists");
						});
					}
				};

				///////////// PUBLISH RECIPE
				// click publish or save as draft button
				$scope.submitGeneralForm = function (forms, draft) {
					saveAsDraft = draft;

					forms.forEach(function (form) {
						$scope.submitFormPart(form[0], form[1], form[2], true);
					});

					prepareRecipe($scope.recipeCase).then(function (recipeName) {
						$location.path($rootScope.url('recipe', {recipeNiceName: recipeName}));
					});
				};

				function prepareRecipe(rcase) {
          var deferred = $q.defer();

          $scope.recipe.title = _.upperFirst($scope.recipe.title);

					var catNames = $scope.categories.filter(function (cat) {
						return (cat.niceName == $scope.recipe.categoryNiceName);
					});

					if (catNames.length > 0) {
						$scope.recipe.category = catNames[0].name;
					}

					if ($scope.recipe.videos && $scope.recipe.videos.length > 0) {
						var filteredVideos = [];

						$scope.recipe.videos.forEach(function (vidString) {
							if (!toolsService.isEmpty(vidString)) {
								var videoCode = toolsService.extractYoutubeCode(vidString);
								if (!toolsService.isEmpty(videoCode)) {
									filteredVideos.push(videoCode);
								}
							}
						});

						$scope.recipe.videos = filteredVideos;
					}

					checkStepsAndIngredients(); // Revisar pasos & ingredientes (vacios, clean name, check qty)

					if (!$scope.sendingRecipe && $scope.recipe_general_form.$valid && catNames.length > 0) {
						$scope.recipe.draft = saveAsDraft;
						var showDialog = false;

						// check if show dialog "realmente quieres publicar la receta"
						if (!saveAsDraft && (rcase == 'new' || $scope.recipe.status.draft)) { // new o era un draft
							showDialog = true;
						}

						publishWithDialog(showDialog, rcase, deferred);
					} else {
						deferred.reject('form not valid');
					}

					return deferred.promise;
				}

				// submit part of form
				// @number: number of form part (1: basic info, 2: procesos, 3: elaboracion)
				// @form: form part
				// @is_submit: is form part submitted
				// @is_general_form_submitted: is form submitted
				$scope.submitFormPart = function (number, form, is_submit, is_general_form_submitted) {
					if (form) { // validar form
						$scope[is_submit] = true;

						// not valid && not general go to form
						if(!form.$valid && !is_general_form_submitted) {
							$('html, body').animate({scrollTop: $('#my-accordion v-pane:nth-child(' + number + ')').offset().top}, 300);

						// valid but not general
						} else if (form.$valid && !is_general_form_submitted) {
							$scope['pane_' + (number + 1)] = false;
							$scope['pane_' + (number) + '_title'] = false;
							$scope.accordion.toggle(number);

						} else {
							// general form invalid & submitted
							if ($scope.recipe_general_form.$invalid && is_general_form_submitted) {
								if (form.$invalid) $scope['pane_' + number + '_title'] = true;
								$scope.accordion.collapseAll();
								$('html, body').animate({scrollTop: 0}, 500);
							}
						}

					} else { // sin validar abro siguiente
						$scope['pane_' + (number + 1)] = false;
						$scope.accordion.toggle(number);
					}
				};

				function publishRecipe(rcase) {
					var deferred = $q.defer();
					$scope.sendingRecipe = true;
					addingTags($scope.recipe);

					if (rcase == 'edit') {
						$scope.recipe.info = {
							modificationSource: $scope.deviceDetector.raw.userAgent
						};
					} else { // creating recipe
						$scope.recipe.info = {
							creationDate: new Date(),
							creationSource: $scope.deviceDetector.raw.userAgent
						};
					} // end edit/creating recipe

					//Putting recipe as private, draft or public
					$scope.recipe.grants = {"search": "public", "view": "public"};

					if ($scope.recipe.status && $scope.recipe.status.private) {
						var privateUser = "user/" + $scope.currentUser.niceName;
						$scope.recipe.grants = {"search": privateUser, "view": privateUser};

					} else if($scope.recipe.draft) {
						var draftUser = "draft/" + $scope.currentUser.niceName;  
						$scope.recipe.grants = {"search": draftUser, "view": draftUser};
          }

          manageCourses();

					recipesFactory.publishRecipe($scope.recipe, $scope.recipeCase).then(function (res) {
						var filesArray = (!toolsService.isEmpty($scope.imagesRecipeUpload)) ? [$scope.imagesRecipeUpload] : $scope.myFiles;

						if ($scope.recipeCase == 'edit') {
							if (filesArray) {
								postFiles(filesArray[0], $scope.recipe.niceName);
								deferred.resolve($scope.recipe.niceName);
							} else { // end new img
								deferred.resolve($scope.recipe.niceName);
							}
						} else { // post recipe
							if (filesArray) {
								postFiles(filesArray[0], res.data.niceName);
							}

							deferred.resolve(res.data.niceName);
						}
					}, function (e) {
						if ($scope.recipe_general_form.$invalid) {
							$('html, body').animate({scrollTop: 0}, 500);
						}

						$log.error("sendRecipe KO. Error: ", e);
						deferred.reject('formulario no válido');

					}).finally(function () {
						$rootScope.$broadcast('updateDraftRecipes', {});

						$timeout(function () {
							$scope.sendingRecipe = false;
						}, appConfig.timeoutDialogues);
					});

					return deferred.promise;
				};
				//////// END PUBLISH

				function publishWithDialog(dialog, rcase, deferred) {
					if(dialog) {
						ngDialog.openConfirm({
							template: 'send-recipe.html',
							className: 'new-recipe',
							scope: $scope
						}).then(function (r) {
							publishRecipe(rcase).then(function (r) {
								deferred.resolve(r);
							}, function (e) {
								deferred.reject(e);
							});
						}, function (e) {
							deferred.reject(e);
						});
					} else {
						publishRecipe(rcase).then(function (r) {
							deferred.resolve(r);
						}, function (e) {
							deferred.reject(e);
						});
					}
        }

        function registerCourses() {
          var newCourses = new Array();
          var coursesObject = filtersBundle.coursesObject;

          _.forEach($scope.recipe.course, function(course) {

            var filteredCourses = _.pickBy(coursesObject, function(value) {
              return (value.indexOf(course) > -1);
            });

            _.forEach(filteredCourses, function(value, key) {
              newCourses.push(key);
            });
          });

          newCourses = _.uniq(newCourses);

          $scope.recipe.course = newCourses;
        }
        
        function manageCourses() {
          var newCourses = new Array();
          var coursesObject = filtersBundle.coursesObject;

          $scope.recipe.course.forEach(function(course) {
            if(!coursesObject[course]) {
              console.error('manageCourses: ' + course);
            }

            coursesObject[course].forEach(function(item) {
              newCourses.push(item);
            });
          });

          newCourses = _.uniq(newCourses);

          $scope.recipe.course = newCourses;
        }

				function checkStepsAndIngredients() {
					$scope.recipe.groups.map(function(group, key_group) {
						group['steps'].map(function(step, key_step) {
							if(step.type == 'mycook') {
								step.outsideTemperature = null;
								step.auxFields.time_hours = 0;
								step.stepTime = 0;
							} else {
								step.cookTime = 0;
							}

							// check turbo
							if(step.auxFields.step_speed == 'Turbo') {
								step.stepTime = 0;
								step.cookTime = 0;
							}

							step['ingredients'].map(function(ingredient, key_ing) {
								// transform fraction to float
								if (!toolsService.isEmpty(ingredient.qty)) {
									ingredient.qty = ((ingredient.qty).toString()).replace(/,/g, '.'); // change comma to dot

									if ((ingredient.qty).toString().indexOf("/") !== -1) { // is a fraction
										ingredient.qty = $filter('fractionToFloat')(ingredient.qty);
									}
								}
								// trim ingredient
								ingredient.name = (ingredient.name).trim();
								ingredient.name = $filter('capitalize')(ingredient.name); //ingredient.name.charAt(0).toUpperCase() + ingredient.name.slice(1);

								if (toolsService.isEmpty(ingredient.qty) && toolsService.isEmpty(ingredient.unit) && toolsService.isEmpty(ingredient.name) && toolsService.isEmpty(ingredient.extra)) {
									_.pull($scope.recipe.groups[key_group].steps[key_step].ingredients, $scope.recipe.groups[key_group].steps[key_step].ingredients[key_ing]);
								}
							});
						});
					});
				}

				function resetForm() {
					$scope.myFiles = [];
					$scope.recipe = setEmptyRecipe();
					$scope.pType = undefined;
				}

				// Get TAG num columns
				function tagColumns() {
					if($scope.currentUser && $scope.currentUser.role) {
						var result = $filter('showTags')($scope.treeTags, $scope.currentUser.role);
						var num = result.length / 4;

						if (Number.isInteger(num)) {
							$scope.tagCols = 4;
						} else {
							$scope.tagCols = 3;
						}
					}
				}

				// Create TAGS TREE
				$scope.$watch('currentUser', function (currentUser) {
					tagColumns();
				});

				// tree control
				$scope.multiOpts = {
					multiSelection: true,
					//nodeId: "path",
					label: "name",
					injectClasses: {
						ul: "a1",
						li: "a2",
						iExpanded: "a3",
						iCollapsed: "a4",
						iLeaf: "a5",
						label: "a6"
					},
					equality: function (node1, node2) {
						return node1 !== undefined && node2 !== undefined && node1.path === node2.path;
					}
				};

				recipesFactory.getTags().then(function (bundle) {
					$scope.tagsBuenos = toolsService.getObjValues(bundle.tags);
					$scope.treeTags = arrayToAngular2($scope.tagsBuenos);

					// when tags does not have a parent
					var treeTagsCopy = _.cloneDeep($scope.treeTags);
					var emptyTag = {"name": "--empty--", "checked": false, "children": []};

					$scope.treeTags.forEach(function (tag, index) {
						if (tag.children.length == 0) {
							treeTagsCopy = treeTagsCopy.splice(index, 1);
							emptyTag['children'].push($scope.treeTags[index]);
						}
					});

					if (emptyTag['children'].length > 0) treeTagsCopy.push(emptyTag);
					$scope.treeTags = treeTagsCopy;
					// when tags does not have a parent

					$scope.propagateChanges = function (sel, isSelected) {
						var pathSelected = sel.path.split(appConfig.itemSeparator);
						var hasParent = (pathSelected.length > 2);
						if (hasParent) {
							modifyParent(pathSelected);
						}

						// al deseleccionarlo, deseleccionamos hijos
						if (!isSelected && sel.children.length > 0) {
							_.remove($scope.selecteds, function (item) {
								return (item.path.indexOf(sel.path + appConfig.itemSeparator) > -1);
							});
						}
					};
				});

				// fill tags checked when edit recipe
				function preSelectTags() {
					// format tag, generate node and add to selecteds
					$scope.recipe.tags.forEach(function (aTag) {
						var tagRegExp = new RegExp("\\" + appConfig.decorator, "g");
						var tagCorrected = aTag.replace(tagRegExp, appConfig.itemSeparator);

						var selectedNode = {
							"name": $filter('getLastItem')(tagCorrected),
							"checked": false,
							"path": tagCorrected,
							"children": []
						};

						$scope.selecteds.push(selectedNode);
					});
				}

				// add tags from tree directive to model
				function addingTags(recipeModel) {
					recipeModel.tags = [];
					$scope.selecteds.forEach(function (selTag) {
						var tagRegExp = new RegExp("\\" + appConfig.itemSeparator, "g");
						var tagFormatted = selTag.path.replace(tagRegExp, appConfig.decorator);

						if (recipeModel.tags.indexOf(tagFormatted) == -1) {
							recipeModel.tags.push(tagFormatted);
						}
					});
				}
				// END TAGS TREE

				// mass autocomplete ingredients
				$scope.ac_option_highlight = {
					suggest: suggest_ingredients_remote,
					on_error: console.log
				};

				function suggest_ingredients(term, ingredients) {
					var fuzzySearch = new Fuse(ingredients, {
						shouldSort: true,
						caseSensitive: false,
						threshold: 0.4,
					});

					var q = term.toLowerCase().trim();

					return fuzzySearch
						.search(q)
						.slice(0, 10)
						.map(function (i) {
							var val = ingredients[i];
							return {
								value: val,
								label: $sce.trustAsHtml(highlight(val, term))
							};
						});
				}

				function highlight(str, term) {
					var highlight_regex = new RegExp('(' + term + ')', 'gi');
					return str.replace(highlight_regex, '<strong>$1</strong>');
				};

				function suggest_ingredients_remote(term) {
					if (term.length < 2)
						return;

					var deferred = $q.defer();
					ingredientsManager.getIngredients().then(function (ingredients) {
						deferred.resolve(suggest_ingredients(term, ingredients));
					});
					return deferred.promise;
				}
				// END mass autocomplete ingredients

				// Upload Image recipe
				var editImageSrc = $scope.mediaDomain + "/recipe/185x134/" + $routeParams.recipeNiceName;
				$scope.editImageSrc = editImageSrc;
				$scope.imagesRecipeUpload = {};

        $scope.photoChanged = function (event) { //check if new image is setted and shows it on the screen
          $scope.recipe.imageRights = false;
					$scope.fileReaderSupported = window.FileReader != null;
					$scope.imagesRecipeUpload = event.target.files;

					if ($scope.imagesRecipeUpload) {
						var files = $scope.imagesRecipeUpload;
						var file = files[0];
						if ($scope.fileReaderSupported && file.type.indexOf('image') > -1) {
							$timeout(function () {
								var fileReader = new FileReader();
								fileReader.readAsDataURL(file);
								fileReader.onload = function (e) {
									$timeout(function () {
										$scope.editImageSrc = e.target.result;
									});
								}
							});
						}
					}
				}

				function uploadFile(niceName, newfile2Send) {
					var destinationUrl = '';
					var method = '';

					if ($scope.recipeCase == 'new') {
						destinationUrl = appConfig.mycookTouchDomain + '/recipe/' + niceName + '/image';
						method = 'POST';

						uploadProcess(destinationUrl, method, newfile2Send);

					} else { //es un edit, faig un replace de la imatge a la posicio 0 (elimino la imatge a la posicio 0 i pujo la nova)
						destinationUrl = appConfig.mycookTouchDomain + '/recipe/' + niceName + '/image';
						method = 'POST';

						if ($scope.recipe.images.length == 0) { //la recepta te la imatge per defecte, no cal fer el delete
							uploadProcess(destinationUrl, method, newfile2Send);

						} else { //sha de borrar la imatge actual de la recepta i pujar la nova
							var urlDestinoDelete = appConfig.mycookTouchDomain + '/recipe/' + niceName + '/image/0';

							$http({
								method: 'delete',
								url: urlDestinoDelete
							}).then(function (response) {
								uploadProcess(destinationUrl, method, newfile2Send);
							});
						}
					}
				}

				function uploadProcess(destinationUrl, method, newfile2Send) {
					$upload.upload({
						url: destinationUrl,
						method: method,
						file: newfile2Send,
						fileFormDataName: 'image',
					}).progress(function (evt) {
						$log.debug("progress1 > evt: ", evt);
						$log.debug('progress: ' + parseInt(100.0 * evt.loaded / evt.total, 10) + '% file :' + evt.config.file.name);
						$scope.imageProgress = parseInt(100.0 * evt.loaded / evt.total, 10) + '% file :' + evt.config.file.name;
					}).success(function (data, status, headers, config) {
						$log.debug('que es el que tinc al data: ', data, status, headers, config);
						$log.debug('file ' + config.file.name + ' is uploaded successfully. Response: ', data);
					}).error(function (e) {
						$log.error("File upload Error", e);
						$scope.errorPhotoUpload = true;
					});
				}

				function postFiles(myFiles, niceName) {
					// FEATURE: [not necessary, just in case to allow multiple files upload] if need to upload multiple images, need to do this process asynchronously
					for (var i = 0; i < myFiles.length; i++) {

						var aFile = myFiles[i];
						var newfile2Send;

						if (Object.prototype.toString.call(aFile) === '[object File]') {
							newfile2Send = aFile;
						} else if (aFile.indexOf(',') > -1) {
							var blobBin = atob(aFile.split(',')[1]);
							var array = [];
							for (var j = 0; j < blobBin.length; j++) {
									array.push(blobBin.charCodeAt(j));
							}

							newfile2Send = new Blob([new Uint8Array(array)], {
								type: 'image/jpeg',
								name: 'new-image.jpg'
							}); // image/png
						}

						if (newfile2Send) {
							uploadFile(niceName, newfile2Send);
						}
					}
				}
				// END Upload Image recipe

				// Manage Tags
				function modifyParent(pathSelected) {
					var parentPath = pathSelected.slice(0, pathSelected.length - 1);
					var parentPathStr = parentPath.join(appConfig.itemSeparator);

					var nodeChecked = false;
					for (var i = 0; i < $scope.treeTags.length && !nodeChecked; i++) {
						var mainTag = $scope.treeTags[i];
						for (var j = 0; j < mainTag.children.length && !nodeChecked; j++) {
							var parentTag = mainTag.children[j];

							if (parentTag.path == parentPathStr) {
								if (!_.some($scope.selecteds, {path: parentPathStr})) {
									$scope.selecteds.push(parentTag);
								}
							}
						}
					}
				}

				function arrayToAngular2(array, check) {
					var op = 'uncheck';
					if (check !== undefined) {
						op = check;
					}

					var tree = [];
					for (var i in array) {
						var path = array[i];
						_angularToggle2(tree, path.split(appConfig.itemSeparator), 0, op);
					}
					return tree;
				}

				function findNode2(array, name) {
					for (var i = 0; i < array.length; i++) {
						var node = array[i];
						if (node.name == name) {
							return i;
						}
					}
					return -1;
				}

				function _angularToggle2(tree, segments, level, op, param) {
					var pos;
					var node;
					if (segments.length - 1 == level) {
						pos = findNode2(tree, segments[level]);
						if (pos == -1) {
							node = {};
							node.name = segments[level];
							node.checked = false;
							node.path = segments.join(appConfig.itemSeparator);
							node.children = [];
							tree.push(node);
							pos = tree.length - 1;
						}
						if (op === 'toggle') {
							tree[pos].checked = !tree[pos].checked;
						} else if (op === 'check') {
							tree[pos].checked = true;
						} else if (op === 'uncheck') {
							tree[pos].checked = false;
						} else if (op === 'get') {
							return tree[pos].children;
						} else if (op === 'put') {
							tree[pos].children = param;
						}
					} else {
						pos = findNode2(tree, segments[level]);
						if (pos == -1) {
							node = {};
							node.name = segments[level];
							node.checked = false;
							node.children = [];
							node.path = segments.slice(0, level + 1).join(appConfig.itemSeparator);
							tree.push(node);
							pos = tree.length - 1;
						}
						_angularToggle2(tree[pos].children, segments, level + 1, op, param);
					}
					return tree;
				}
				// END Manage Tags
		}]);

})();