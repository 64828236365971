(function(){
  'use strict';

  angular.module('mainApp')

    .controller('StoreMyOrdersController', ['$scope','$rootScope','$filter','appConfig','storeFactory','webStorage',
      function ($scope, $rootScope, $filter, appConfig, storeFactory, webStorage) {

      	var userNiceName = webStorage.get('userNiceName');

      	storeFactory.getOrders(userNiceName).then(function(data) {
    			$scope.orders = $filter('filter')(data.result, {state: '!WaitingPayment'});
      	});
    }])

    .controller('StoreOrderController', ['$scope','$rootScope','$routeParams','$location','$filter','appConfig','storeFactory','webStorage',
      function ($scope, $rootScope, $routeParams, $location, $filter, appConfig, storeFactory, webStorage) {

      	$scope.orderId = $routeParams.id;

      	var userNiceName = webStorage.get('userNiceName');

	      if($scope.orderId) {
	      	storeFactory.getOrder($routeParams.id, userNiceName).then(function(data) {
      			if(data.result && data.result.length > 0) {
      				$scope.order = data.result[0];

      				// SEO
		          var seo_title = $rootScope.translations.shop.seo['store-order'].title;
		          seo_title += ' ' + $rootScope.translations.seo.separator + ' ' + $rootScope.translations.seo.website_name;
		          $rootScope.pageTitle = $filter('translateVariables')(seo_title, data.result[0].id);
      			} else {
      				$location.path($rootScope.url('notFound'));
      			}
	      	});
	      }
    }]);
})();