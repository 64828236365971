(function () {
  'use strict';

  angular.module('mainApp')

    .controller('StorePaymentController', ['$scope', '$rootScope', '$location', '$cookies', 'ngCart', 'paymentSvc', 'toolsService', 'webStorage', '_', 'serviceStoreAnalytics', 'legalTerms',
      function ($scope, $rootScope, $location, $cookies, ngCart, paymentSvc, toolsService, webStorage, _, serviceStoreAnalytics, legalTerms) {

        serviceStoreAnalytics.checkoutProcess(4);

        $scope.freeShippingCost = true; //appConfig.shop.freeShippingCost;
        //$scope.$back = toolsService.$back;
        //$scope.$reload = validateOrder;
        //$scope.changePath = toolsService.changePath;
        $scope.showTaxes = true;
        $scope.paymentType;

        $scope.address = ngCart.getShippingAddress();
        $scope.orderComments = ngCart.getComments();
        $scope.errMsg = null;
        var processing = false;

        $scope.voucher = null;
        $scope.voucherMessage = '';
        $scope.voucherIsApplied = false;
        $scope.sendAsGift = false;

        validateOrder();

        $rootScope.$on('ngCart:change', function () {
          if ($scope.errMsg == null && processing == false) {
            validateOrder();
          }
        });

        $scope.addVoucher = function (code) {
          ngCart.setCoupon(code);
        };

        $scope.removeVoucher = function () {
          $scope.voucher = null;
          $scope.voucherMessage = '';
          $scope.voucherIsApplied = false;
          ngCart.setCoupon(null);
        };

        $scope.getTotalItems = function () {
          console.log('checkout - StorePaymentController > getTotalItems', ngCart.getTotalItems());
          return ngCart.getTotalItems();
        };

        $scope.doPayment = function () {
          var conditionsArray = ['termsOfSale'];
          var mail = false;

          if (toolsService.isEmpty($scope.currentUser)) {
            mail = $scope.address.mail;
          }

          legalTerms.acceptLegalTerms('multi', conditionsArray, mail).then(function(result){
            continuePayment();
          }).catch(function(error) {
            console.error('CheckoutController -> Error accepting legalTerms', error);
          });
        };

        function continuePayment() {
          processing = true;
          ngCart.setComments($scope.orderComments);
          ngCart.setSendAsGift($scope.sendAsGift);
          var cart = ngCart.getCart();
          var userNiceName = webStorage.get('userNiceName') || 'guest';

          //store-payment-tpv

          if ($scope.voucher) {
            cart.voucher = {
              code: $scope.voucher
            };
          }

          if (ngCart.getOrderId()) {
            cart.orderId = ngCart.getOrderId();
          }

          if ($cookies.get('AFFILIATE_MYCOOK') != null) {
            cart.affiliate = $cookies.get('AFFILIATE_MYCOOK');
          }

          paymentSvc.insertOrder(cart, userNiceName).then(function (results) {
            console.log('checkout - StorePaymentController > inserting order ok', results);

            // dummy
            var paymentType = 'tpv';
            // dummy

            if (results.status = 200) {
              $scope.order = results.data;
              //toolsService.changePath($scope.url(paymentType,{id:$scope.order.id}));

              serviceStoreAnalytics.checkoutOptions(4, paymentType);

              console.log('checkout - StorePaymentController > after insertOrder redirect to store-payment-' + paymentType, 'order id: ' + $scope.order.id);
              $location.path($rootScope.url('store-payment-' + paymentType, {
                id: $scope.order.id
              }));
              $scope.errMsg = null;
            }
            processing = false;
          },
          function (results) {
            console.log('checkout - StorePaymentController > inserting order ko', results);
            errorHandling(results.data);
            processing = false;
          });
        }

        /*function translateError(val) {
            return 'translateError -> checkout.payment.errors.' + val;
            ///$translate('checkout.payment.errors.' + val);
        }*/

        function errorHandling(data) {
          // 1 - order items undefined or <
          // 2 - item no valid
          // 3 - item does not exist
          // 4 - invalid stock
          // 5 - invalid shipping address
          // 6 - unknown order type
          // 7 - unathorized

          // coupon errors
          // 20 - Discount code applied correctly
          // 21 - date range
          // 22 - Discount exceded valid uses
          // 23 - Discount not match user
          // 24 - Discount not match any product of your cart
          // 25 - Invalid discount code
          // 26 - You can't mix coupon codes
          // 27 - no estan todos los productos de un pack
          // 28 - producto con precio de landing no se puede aplicar doble descuento

          $scope.voucherMessage = '';

          console.log('checkout - StorePaymentController > errorHandling', data);

          var removeVoucher = false;

          if (data.length > 0) {
            data.forEach(function (item) {
              // coupon errors
              if(item.errorCode >= 21 && item.errorCode <= 28) {
                removeVoucher = true;
                $scope.voucherMessage += '<p>' + $rootScope.translations.shop.cart.promotional_code.messages[item.errorCode] + '</p>';
              }
            });
          }

          if (removeVoucher) ngCart.setCoupon(null);

          if (data) {
            // item does not exist / no stock
            if (data.errCode == 3 || data.errCode == 4) {
              //Get product names to show error
              var products = data.data.map(function (d) {
                return d['name'] + ' (Ref: ' + d['reference'] + ')';
              });

              products = products.toString().replace(/,/g, ", ");

              //Remove Invalid items from the cart
              data.data.forEach(function (p) {
                //If product is not avaliable/valid remove it from the cart
                if (data.errCode == 3) {
                  ngCart.removeItemByNiceName(p._niceName);
                } else {
                  //If stock is not valid update it
                  if (p.stock > 0) {
                    var item = ngCart.getItemByNiceName(p._niceName);
                    data.setQuantity(p.stock, false);
                  } else {
                    ngCart.removeItemByNiceName(p._niceName);
                  }
                }
              });

              var sufix = (data.data.length > 1) ? "_plural" : "_singular";
              var v = data.errCode.toString();
              console.log('checkout - StorePaymentController > errorHandling 3-4 > Error', v + 'a' + sufix);

              $scope.errMsg = $rootScope.translations.shop.order_checkout.errors[v + 'a' + sufix] + ' ' + products + ' ' + $rootScope.translations.shop.order_checkout.errors[v + 'b' + sufix];
            } else {
              var v = (data.errCode > 5) ? 'other' : data.errCode;
              console.log('checkout - StorePaymentController > errorHandling 5-other > Error', v);

              $scope.errMsg = $rootScope.translations.shop.order_checkout.errors[v];
            }
          }
        }

        function validateOrder() {
          var cart = _.clone(ngCart.getCart());
          var userNiceName = webStorage.get('userNiceName') || 'guest';
          processing = true;

          if ($scope.voucher || ngCart.getCoupon()) {
            var coupon = ($scope.voucher || ngCart.getCoupon());
            $scope.voucher = coupon;
            cart.voucher = {
              code: coupon
            };
          }

          console.log('checkout - StorePaymentController > validateOrder', cart);

          paymentSvc.validateOrder(cart, userNiceName).then(function (results) {
            console.log('StorePaymentController > validateOrder RESULTS', results);

            if (results.status = 200) {
              ngCart.setDiscount(results.data.totalDiscount);
              ngCart.setTotalDiscount(results.data.total + results.data.totalDiscount);

              $scope.order = results.data;

              if (!toolsService.isEmpty($scope.order.voucher)) {
                if (!toolsService.isEmpty($scope.order.voucher.status) && $scope.order.voucher.status == 'applied') {
                  $scope.voucherIsApplied = true;
                } else {
                  $scope.voucherIsApplied = false;
                }

                $scope.voucherMessage = '<p>' + $rootScope.translations.shop.cart.promotional_code.messages.valid_discount + '</p>';
              }
            }

            $scope.errMsg = null;
            processing = false;
          },
          function (results) {
            console.log('checkout - StorePaymentController > paymentSvc.error', results);
            errorHandling(results.data);
            processing = false;
          });
        }
      }
    ])

    .factory('paymentSvc', ['$rootScope', '$http', '_', 'appConfig', 'sessionService',
      function ($rootScope, $http, _, appConfig, sessionService) {

        function insertOrder(order, userNiceName) {
          return handleOrder(order, userNiceName, 'new');
        }

        function validateOrder(order, userNiceName) {
          return handleOrder(order, userNiceName, 'calc');
        }

        function handleOrder(order, userNiceName, type) {
          var leanOrder = cleanCart(order);

          console.log('checkout - StorePaymentController > handleOrder', leanOrder);
          console.log('checkout - StorePaymentController > auth', 'Bearer ' + sessionService.getUserToken());
          console.log('checkout - StorePaymentController > JSON', JSON.stringify(leanOrder));

          return $http({
            method: 'post',
            headers: {
              'Authorization': 'Bearer ' + sessionService.getUserToken()
            },
            url: appConfig.localApiBasePath + '/order/' + type + '/' + userNiceName,
            data: JSON.stringify(leanOrder)
          });
        }

        function cleanCart(fullCart) {
          var leanCart = _.pick(fullCart, ['shipping', 'shippingAddress', 'taxRate', 'voucher', 'comments', 'sendAsGift', 'customerValue', 'orderId', 'affiliate']);
          var leanItems = [];

          fullCart.items.forEach(function (item) {
            //leanItems.push(_.omit(item, ['_data.relatedProducts']));
            var leanItem = _.pick(item, ['_niceName', '_name', '_price', '_quantity', '_data', '_landing']);
            // omit doesn't work with nested fields :(
            //leanItems.push(_.omit(leanItem, ['$$hashKey', '_data.relatedProducts', '_data.$$hashKey']));
            delete leanItem.$$hashKey;
            delete leanItem._data.relatedProducts;
            delete leanItem._data.$$hashKey;
            leanItems.push(leanItem);
          });

          leanCart.items = leanItems;
          return leanCart;
        }

        return {
          insertOrder: insertOrder,
          validateOrder: validateOrder
        };
      }
    ])

    .factory('orderSvc', ['$rootScope', '$http', '$q', '_', 'appConfig',
      function ($rootScope, $http, $q, _, appConfig) {

        // Analytics.trackCheckout(1);
        // Analytics.trackEvent('Cart', 'view', 'carrito');

        function get(orderId, signature, merchant) {
          return $http({
            method: 'get',
            url: appConfig.localApiBasePath + '/order/' + orderId + '?signature=' + signature + '&merchant=' + merchant
          });
        }

        /*function getOrdersFromUser(userId) {
            console.log("checkout - StorePaymentController > GET ORDERS FROM USER");
            var deferredUsers = $q.defer();
            $http.post(appConfig.localApiBasePath + '/orders', JSON.stringify({query: {customer: userId}}))
                .success(function(res) {
                    var data = (res.result) ? res.result : null;
                    deferredUsers.resolve(data);
                })
                .error(function (res) {
                    deferredUsers.reject(res);
                });

            return deferredUsers.promise;
        }*/

        return {
          get: get
          /*,getOrdersFromUser: getOrdersFromUser*/
        };
      }
    ])

    .directive('orderSummary', ['timestamp', function (timestamp) {
      return {
        restrict: 'E',
        templateUrl: function (element, attrs) {
          if (typeof attrs.templateUrl == 'undefined') {
            return '/template/store/checkout/order-summary.html?rev=' + timestamp;
          } else {
            return attrs.templateUrl;
          }
        },
        link: function (scope, element, attrs) {}
      };
    }]);
})();